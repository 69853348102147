import {ProjectsFiltersEnum} from '@/views/srm/projects/api/models/enums/projectsFilters.enum';

export const PROJECTS_NEEDED_FILTERS: Array<ProjectsFiltersEnum> = [
  ProjectsFiltersEnum.NOMENCLATURE_GROUPS,
  ProjectsFiltersEnum.PROJECT_METHODS,
  ProjectsFiltersEnum.STAGE_TYPES,
  ProjectsFiltersEnum.STAGE_STATUSES,
  ProjectsFiltersEnum.BUSINESS_ENTITIES,
  ProjectsFiltersEnum.STAGE_SUPPLIER_PARTICIPATION_STATUSES,
];
