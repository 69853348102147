<script setup lang="ts">
import StaticPage from '@/common/components/static/StaticPage.vue';
import { useTenantStore } from '@/stores/tenant.store';

const tenantStore = useTenantStore();
const props = defineProps<{
  imageUrl: string,
  containerWidth: string
}>();

</script>

<template>
  <div>
    <StaticPage :image-url="props.imageUrl">
      <div>
        <div
          class="text-wrapper"
          :style="{'width': containerWidth}"
        >
          <p class="main-text">Срок действия вашего <br> приглашения истек</p>
          <p class="text">
            Запросите повторную отправку ссылки для<br>регистрации, обратившись в техподдержку по почте<br>
            <a
              :href="'mailto:' + tenantStore.tenant?.contactEmailExternal"
              class="mm-link"
            >
              {{ tenantStore.tenant?.contactEmailExternal || '' }}
            </a>
          </p>
        </div>
      </div>
    </StaticPage>
  </div>
</template>

<style scoped lang="scss">

@import '../../../assets/styles/base/common/variables';

:global(.auth-wrapper) {
  background: white !important;
}

.text-wrapper {
  margin-left: 135px;

  .main-text {
    font-size: 32px;
    font-weight: 500;
    line-height: 36px;
  }

  .text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;

    a {
      font-weight: 500;
    }
  }
}
</style>
