<template>
  <div
    class="datepicker__wrapper"
    :class="{
      'icon-right': iconPosition === DatePickerIconPositionEnum.Right,
      'datepicker__wrapper--invalid': invalid
    }"
    @click="(event) => event.stopPropagation()"
  >
    <VueDatePicker
      ref="datepickerRef"
      :model-value="modelValue"
      locale="ru"
      select-text="Выбрать"
      cancel-text="Отменить"
      auto-apply
      :close-on-auto-apply="true"
      month-name-format="long"
      v-bind="$attrs"
      :disabled="disabled"
      :format="format"
      :min-date="minDate"
      :placeholder="placeholder"
      :max-date="maxDate"
      @focus="onFocus"
      @blur="onBlur"
      @internal-model-change="onInput"
    >
      <template #input-icon>
        <SvgIcon :src="IconPathEnum.ActionDateSvg" />
      </template>
      <template
        v-for="(_, name) in $slots"
        #[name]="slotData"
        :key="name"
      >
        <slot
          :name="name"
          v-bind="slotData"
        />
      </template>
      <template #year="{ year }">
        {{ year }}
      </template>
    </VueDatePicker>

    <div
      v-if="validationField?.errorMessage?.value"
      class="datepicker__error"
    >
      {{ validationField?.errorMessage?.value }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import VueDatePicker from '@vuepic/vue-datepicker';
import type { IDatepickerProps } from '../models/datepicker.model';
import { DatePickerIconPositionEnum } from '../enums/datePickerIconPosition.enum';
import SvgIcon from '@/ui-kit/components/SvgIcon.vue';
import { computed, ref } from 'vue';
import { IconPathEnum } from '@/ui-kit/enums/iconPath.enum';

/**
 * Обертка над библиотекой @vuepic/vue-datepicker
 * Пропсы: https://vue3datepicker.com/api/props/
 * Слоты: https://vue3datepicker.com/api/slots/
 * События: https://vue3datepicker.com/api/events/
 */

const datepickerRef = ref();
const isFocusInput = ref(false);
const inputValue = ref<Date | undefined>();

const props = withDefaults(defineProps<IDatepickerProps>(), {
  iconPosition: 0 as DatePickerIconPositionEnum,
});

const invalid = computed(() => !!props.validationField?.errorMessage.value || !!props.errorMessage);

function onFocus(): void {
  isFocusInput.value = true;
}
function onBlur(): void {
  isFocusInput.value = false;
}

function onInput(event: Date | undefined): void {
  if (isFocusInput.value) {
    inputValue.value = event;
  }
}

defineExpose({ datepickerRef, inputValue });
</script>

<style lang="scss" scoped>
@import '@styles/base/common/variables';

.datepicker {
  &__wrapper {
    display: flex;
    flex-direction: row;
    position: relative;

    &.icon-right {
      :deep(.dp__input_icon) {
        position: relative;
        bottom: 42px;
        left: calc(100% - 40px);
      }
    }

    :deep(.dp__main) {
      .dp__input {
        border: 1px solid #e6e6e6;
        border-radius: 6px;
        font-size: 14px;

        &:hover {
          border-color: $text-disabled;
        }
      }

      .dp__icon {
        top: 52%;
      }
    }
  }

  &__error {
    font-size: 12px;
    line-height: 16px;
    color: $input-error-c;
    position: absolute;
    bottom: 0;
    left: 16px;
  }

  :global(.dp__input_icon) {
    height: 20px;
    display: flex;
  }

  :global(.dp__input) {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
    font-family: Golos, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $text-dark-green;
  }
}
</style>
