import { type Ref, ref } from 'vue';
import { useRoute } from 'vue-router';
import { RouteTypeEnum } from '@/common/enums/routeType.enum';

export function useRoledRoutes(): IUseRoledRoutes {
  const route = useRoute();
  const isSupplierRoute = ref<boolean>(route.path.includes('supplier'));

  const routeType = ref<RouteTypeEnum>(
    isSupplierRoute.value && RouteTypeEnum.Supplier,
  );

  if (!routeType.value) {
    routeType.value = RouteTypeEnum.Supplier;
  }

  return {
    isSupplierRoute,
    routeType,
  };
}

export interface IUseRoledRoutes {
  isSupplierRoute: Ref<boolean>;
  routeType: Ref<RouteTypeEnum>;
}
