export function formatPhoneNumber(number: string, useBrackets: boolean = false): string {
  if (!number) {
    return '';
  }

  const processedNumber = number.replaceAll(' ', '').replaceAll('-', '');

  let formattedNumber = '';

  if (processedNumber.length <= 16) {
    if (processedNumber.length >= 15) {
      formattedNumber += formattedNumber =`${processedNumber?.slice(0, 5)} ${useBrackets ? '(' : ''}${processedNumber?.slice(5, 8)}${useBrackets ? ')' : ''} ${processedNumber?.slice(8, 11)}-${processedNumber?.slice(11, 13)}-${processedNumber?.slice(13, 15)}`;
    } else if (processedNumber.length >= 14) {
      formattedNumber += formattedNumber =`${processedNumber?.slice(0, 4)} ${useBrackets ? '(' : ''}${processedNumber?.slice(4, 7)}${useBrackets ? ')' : ''} ${processedNumber?.slice(7, 10)}-${processedNumber?.slice(10, 12)}-${processedNumber?.slice(12, 14)}`;
    } else if (processedNumber.length >= 13) {
      formattedNumber += formattedNumber =`${processedNumber?.slice(0, 3)} ${useBrackets ? '(' : ''}${processedNumber?.slice(3, 6)}${useBrackets ? ')' : ''} ${processedNumber?.slice(6, 9)}-${processedNumber?.slice(9, 11)}-${processedNumber?.slice(11, 13)}`;
    } else if (processedNumber.length >= 12) {
      formattedNumber += formattedNumber =`${processedNumber?.slice(0, 2)} ${useBrackets ? '(' : ''}${processedNumber?.slice(2, 5)}${useBrackets ? ')' : ''} ${processedNumber?.slice(5, 8)}-${processedNumber?.slice(8, 10)}-${processedNumber?.slice(10, 12)}`;
    } else if (processedNumber.length === 11) {
      formattedNumber += `+${processedNumber[0]} ${useBrackets ? '(' : ''}${processedNumber?.slice(1, 4)}${useBrackets ? ')' : ''} ${processedNumber?.slice(4, 7)}-${processedNumber?.slice(7, 9)}-${processedNumber?.slice(9, 11)}`
    } else {
      formattedNumber += processedNumber;
    }
  }

  return formattedNumber;
}
