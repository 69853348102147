import axios from 'axios';
import { ref } from 'vue';

export async function useFetch<T>(url: string, params?: unknown) {
  const res = await axios<T>({
    method: 'get',
    url: url,
    params,
  });
  return { data: ref<T>(res.data) };
}
