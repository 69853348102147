export abstract class ProviderService {
  protected static readonly serviceName: string;

  static getServiceName(): string {
    if (!this.serviceName) {
      throw new Error('Укажите имя для сервиса');
    }

    return this.serviceName;
  }
}
