<script setup lang="ts">
import BaseVueModal from '@/ui-kit/components/modal/BaseVueModal.vue';
import PasswordField from '@/ui-kit/components/PasswordField.vue';
import { Validators } from '@/ui-kit/utils/validators.util';
import { ResetPasswordValidationEnum } from '@/views/srm/account-settings/models/resetPasswordValidation.enum';
import { ValidatorsMessagesEnum } from '@/ui-kit/enums/validators.enum';
import { useField, useForm } from 'vee-validate';
import { useUserApi } from '@/views/srm/account-settings/api/useUserApi';
import { computed, ref } from 'vue';
import { IconPathEnum } from '@/ui-kit/enums/iconPath.enum';
import SvgIcon from '@/ui-kit/components/SvgIcon.vue';
import Notificator from '@/ui-kit/services/notificator.service';
import SuccessIcon from '@/ui-kit/components/icons/SuccessIcon.vue';


const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'confirm'): void;
}>();

const isLoading = ref(false)

const userProfileApi = useUserApi()

const validationSchema = {
  [ResetPasswordValidationEnum.MainPassword]: [
    Validators.required(),
    Validators.password(ValidatorsMessagesEnum.PasswordSafetyRequirements),
    Validators.maxLength(50),
  ],
  [ResetPasswordValidationEnum.NewPassword]: [
    Validators.required(),
    Validators.password(ValidatorsMessagesEnum.PasswordSafetyRequirements),
    Validators.maxLength(50),
  ],
  [ResetPasswordValidationEnum.ConfirmPassword]: [
    Validators.required(),
    Validators.password(ValidatorsMessagesEnum.PasswordSafetyRequirements),
    Validators.maxLength(50),
    Validators.compare(ResetPasswordValidationEnum.NewPassword, 'Подтверждение пароля не совпадает с новым паролем'),
  ],
};

const form = useForm({
  validationSchema,
});

const fields = {
  currentPassword: useField(ResetPasswordValidationEnum.MainPassword),
  newPassword: useField(ResetPasswordValidationEnum.NewPassword),
  confirmPassword: useField(ResetPasswordValidationEnum.ConfirmPassword),
}

const isButtonDisabled = computed(() => !!fields.currentPassword.value.value && !!fields.confirmPassword.value.value && !!fields.newPassword.value.value)
const isFieldsHasErrors = computed(() => {
  return fields.newPassword.errorMessage.value ||  fields.currentPassword.errorMessage.value || fields.confirmPassword.errorMessage.value
})

async function confirm(): Promise<void> {
  isLoading.value = true
  form.setTouched(true)

  if (!(await form.validate()).valid) {
    return;
  }

  const reqBody = {
    currentPassword: fields.currentPassword.value.value,
    newPassword: fields.newPassword.value.value,
    repeatPassword: fields.confirmPassword.value.value,
  }

  try {
    await userProfileApi.changeUserPassword(reqBody)
    emit('confirm')
    Notificator.showDetachedNotification('Пароль успешно изменен', {
      icon: SuccessIcon,
    });
  } catch(e) {
    console.error(e.message)
    if (e.response.status === 400) {
      const errorFields = e.response.data.errors.map((e) => e.field);
      errorFields.forEach((error) => {
        if (error === 'currentPassword') {
          fields[error].setErrors('Введен неверный пароль');
        } else {
          fields[error].setErrors('Проверьте введенные данные');
        }
      });
      return;
    }
    if (e.response.status === 403) {
      Notificator.showDetachedNotification('Недостаточно прав для изменения пароля');
      return
    }
    Notificator.showDetachedNotification('Произошла непредвиденная ошибка при обновлении пароля');
  } finally {
    isLoading.value = false
  }

}

</script>

<template>
  <BaseVueModal
    title="Смена пароля"
    @cancel="emit('cancel')"
  >
    <div class="d-flex flex-column">
      <div class="d-flex flex-column">
        <form @submit.prevent>
          <PasswordField
            v-model="fields.currentPassword.value.value"
            :validation-field="fields.currentPassword"
            placeholder="Введите текущий пароль"
            :disabled="isLoading"
          />
          <p class="modal-text">
            Пароль должен содержать от 8 символов, прописные (A-Z) и строчные (a-z) буквы латинского
            алфавита, минимум одну цифру, минимум один символ !”№;%:?*()_+-=#$%^&'
          </p>
          <PasswordField
            v-model="fields.newPassword.value.value"
            :validation-field="fields.newPassword"
            placeholder="Новый пароль"
            :disabled="isLoading"
          />
          <PasswordField
            v-model="fields.confirmPassword.value.value"
            :validation-field="fields.confirmPassword"
            placeholder="Подтвердить пароль"
            :disabled="isLoading"
          />
        </form>
        <div class="d-flex justify-content-between">
          <button
            class="btn btn-secondary d-flex justify-content-center"
            @click="emit('cancel')"
          >
            Отмена
          </button>
          <div class="position-relative">
            <button
              class="btn btn-primary d-flex justify-content-center"
              :disabled="!isButtonDisabled || !!isFieldsHasErrors || isLoading"
              @click="confirm"
            >
              <span v-if="!isLoading">Сменить пароль</span>
            </button>
            <SvgIcon
              v-if="isLoading"
              class="uploading-icon"
              :src="IconPathEnum.IndicatorsProgressSvg"
            />
          </div>
        </div>
      </div>
    </div>
  </BaseVueModal>
</template>

<style scoped lang="scss">
@import '@styles/base/common/variables';

.users-container {
  height: 396px;
  width: 100%;
  position: relative;

  .mm-clip-loader {
    left: 32px;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 48px;
    height: 48px;
  }
}

.modal-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $text-black;
}

.btn {
  height: 56px;
  width: 247px;
}
</style>
