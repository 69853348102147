import { FileStatusEnum } from '@/ui-kit/enums/file-uploader/file-status.enum';
import axios, { type CancelTokenSource } from 'axios';
import type { IFile, TFileId } from '@/ui-kit/models/files.model';
import { reactive } from 'vue';
import { FileHelper } from '@/ui-kit/utils/fileHelper.util';

export class FileTemp {
  public progress = 0;
  public error = '';
  public status = FileStatusEnum.Default;
  public token: CancelTokenSource;
  public tempId: TFileId = FileHelper.getTempId();
  public sourceId: string | null = null;
  public type: string = '';

  constructor(
    public file?: File | IFile,
    public isUploaded = false,
  ) {
    this.sourceId = (file as IFile)?.sourceId;
    this.refreshCancelToken();
  }

  public static getReactiveInstance(file?: File | IFile, isUploaded = false): FileTemp {
    return reactive(new FileTemp(file, isUploaded));
  }

  public setError(error: string): void {
    this.error = error;
  }

  public onUploaded(file: IFile): void {
    this.file = file;
    this.isUploaded = true;
  }

  public setStatus(status: FileStatusEnum): void {
    this.status = status;
    this.refreshCancelToken();
  }

  public refreshCancelToken(): void {
    this.token = axios?.CancelToken?.source();
  }

  public cancelToken(): void {
    this.token?.cancel();
    this.refreshCancelToken();
  }

  public static isFileTempInstance(entity: unknown): boolean {
    return entity instanceof FileTemp;
  }
}
