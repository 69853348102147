import type { IDictionaryValue } from '@/common/models/filters/dictionary-value';
import type { ITreeViewItem } from '@/ui-kit/models/treeView.model';

export function useTree(): ITreeParser {
  return {
    parseTree: (data: unknown[], filedName: string, delimiter: string): ITreeViewItem[] => {
      const tree: ITreeViewItem[] = [];
      const sorted = data.sort((a, b) => {
        if (a[filedName] > b[filedName]) {
          return 1;
        }
        if (a[filedName] < b[filedName]) {
          return -1;
        }
        return 0;
      });

      sorted.forEach((sortedItem) => {
        const parts = sortedItem[filedName].split(delimiter);
        let buff = tree;
        let currentPart = '';
        for (const part of parts) {
          if (currentPart) {
            currentPart += `.${part}`;
          } else {
            currentPart = part;
          }
          const found = buff.find((buffItem) => buffItem[filedName] === currentPart);
          if (found) {
            buff = found.children;
          } else {
            buff.push({
              ...sortedItem,
              hidden: false,
              children: [],
            });
            currentPart = '';
            break;
          }
        }
      });

      return tree;
    },
    treeToArray: (tree: ITreeViewItem[]): ITreeViewItem[] => {
      return flattenTree(tree);
    },
  };
}

function flattenTree(tree: ITreeViewItem[], result: ITreeViewItem[] = []): ITreeViewItem[] {
  for (const node of tree) {
    result.push(node);
    if (node.children && node.children.length > 0) {
      flattenTree(node.children, result);
      delete node.children; // Remove children to avoid circular references in the resulting array
    }
  }
  return result;
}

export interface ITreeParser {
  parseTree: (values: IDictionaryValue[], filedName: string, delimiter: string) => ITreeViewItem[];
  treeToArray: (tree: ITreeViewItem[]) => ITreeViewItem[];
}
