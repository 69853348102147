export abstract class BaseReactiveHelper {
  public value: boolean;

  protected constructor(value = false) {
    this.value = value;
  }

  public activate(): void {
    this.value = true;
  }

  public deactivate(): void {
    this.value = false;
  }
}
