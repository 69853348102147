import type { RouteRecordRaw } from 'vue-router';
import GeneralInformation from '@/views/srm/account-settings/GeneralInformation.vue';
import Notifications from '@/views/srm/account-settings/notifications/Notifications.vue';


export const accountSettingsRoutes: RouteRecordRaw[] = [
    {
        path: '',
        redirect: '/account-settings/general-information',
        name: 'profile/general-information',
    },
    {
        path: 'general-information',
        component: GeneralInformation,
        name: 'general-information',
    },
    {
        path: 'notifications',
        component: Notifications,
    },
];
