<script setup lang="ts">

import BaseImg from '@/ui-kit/components/BaseImg.vue';

const props = defineProps<{
  imageUrl?: string,
  mainText?: string,
  text?: string,
  containerWidth?: string,
}>()

</script>

<template>
  <div class="d-flex align-content-center not-found-content">
    <BaseImg
      :src="props.imageUrl"
      format="webp"
    />
    <div
      v-if="props.mainText"
      class="text-wrapper"
      :style="{'width': containerWidth}"
    >
      <p class="main-text">{{ props.mainText }}</p>
      <p class="text">{{ props.text }}</p>
    </div>
    <slot v-else></slot>
  </div>
</template>

<style scoped lang="scss">

@import '../../../assets/styles/base/common/variables';

:global(.auth-wrapper) {
  background: white !important;
}

.not-found-content {
  height: 502px;
  justify-content: center;
  align-items: center;
  margin-top: 120px;

  .text-wrapper {
    margin-left: 135px;

    .main-text {
      font-size: 32px;
      font-weight: 500;
      line-height: 36px;
      margin-bottom: 20px;
    }

    .text {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      white-space: pre-wrap;
    }
  }

}

</style>