<script setup lang="ts"></script>

<template>
  <div class="auth-alert">
    <slot />
  </div>
</template>

<style scoped lang="scss">
@import '@styles/base/common/variables';

.auth-alert {
  background: white;
  padding: 32px 40px 40px 40px;
  border-radius: 12px;
  width: 582px;
  border: 1px solid $gray-200;
}
</style>
