import type { IPagination } from '@/common/models/pagination';
import type { IId } from '@/common/models/id';
import type { ITableAllRowsSelectEvent, ITableRowSelectEvent } from '@/ui-kit/models/table.model';
import Notificator from '@/ui-kit/services/notificator.service';
import type Loader from '@/ui-kit/utils/loaderHelper.util';
import { type Ref, ref } from 'vue';

export class AppTableSelectService<T extends IId> {
  private _tableData: IPagination<T>;
  private _selectedIds: Ref<Array<number>> = ref([]);
  private _unselectedIds: Ref<Array<number>> = ref([]);
  private _selectedCount: Ref<number> = ref(0);
  private _isAllSelected: Ref<boolean> = ref(false);

  constructor(tableData: IPagination<T>) {
    this._tableData = tableData;
  }

  public get selectedIds(): Array<number> {
    return this._selectedIds.value;
  }

  public get isSelectAllSemiSelected(): boolean {
    return !!this._unselectedIds.value?.length &&
      this._tableData?.total - this.selectedCount === this._unselectedIds.value?.length
  }

  public get selectedCount(): number {
    return this._selectedCount.value;
  }

  public get isAllSelected(): boolean {
    return this._isAllSelected.value;
  }

  public updateTableData(newData: IPagination<T>, resetSelected = false): void {
    this._tableData = newData;

    if (newData.page > 1 || newData.size > 20) {
      return;
    }

    if (resetSelected) {
      this.resetData();
    }
  }

  public selectItem(event: ITableRowSelectEvent<T>): void {
    if (event.value) {
      if (this.isSelectAllSemiSelected) {
        this.filterUnselectedItems(event.row.id);
        this._selectedCount.value++;
        this._isAllSelected.value = !this._unselectedIds.value?.length;
        return;
      }

      this._selectedIds.value.push(event.row.id);
      this._selectedCount.value = this.selectedIds?.length;
      this.filterUnselectedItems(event.row.id);
      return;
    }

    this._unselectedIds.value?.push(event.row.id);
    if (this.isSelectAllSemiSelected || this.isAllSelected) {
      this.filterSelectedItems(event.row.id);
      this._selectedCount.value--;
      this._isAllSelected.value = false;
    }
    else {
      this.filterSelectedItems(event.row.id);
      this._selectedCount.value = this.selectedIds?.length;
    }
    if (!this._selectedCount.value) {
      this.resetData();
    }
  }

  public deselectAll(): void {
    this._selectedIds.value = [];
    this._selectedCount.value = 0;
    this._isAllSelected.value = false;
  }

  public isRowSelected(row: T): boolean {
    if (this._isAllSelected.value) {
      return true;
    }

    if (this.isSelectAllSemiSelected) {
      return !this._unselectedIds.value?.find((unselectedId: number) => unselectedId === row.id);
    }

    return !!this._selectedIds.value?.find((selectedId: number) => selectedId === row.id);
  }

  public selectAll(event: ITableAllRowsSelectEvent): void {
    this._unselectedIds.value = [];

    if (event.value) {
      this._isAllSelected.value = true;
      this._selectedCount.value = this._tableData?.total;
      return;
    }

    this.deselectAll();
  }

  public async getOrFetchSelectedIds(
    fetchFn: () => Promise<Array<number>>,
    loader?: Loader,
  ): Promise<Array<number>> {
    if (!this.isSelectAllSemiSelected && !this._isAllSelected.value) {
      return this._selectedIds.value;
    }

    try {
      loader && loader.activate();
      let selectedIdsInstance: Array<number> = this._selectedIds.value;

      if (this._tableData?.total > this._tableData?.items?.length) {
        if (!fetchFn) {
          return;
        }

        selectedIdsInstance = await fetchFn();
      }

      if (this.isSelectAllSemiSelected) {
        return selectedIdsInstance?.filter((id: number) => !this._unselectedIds.value?.includes(id));
      }

      return selectedIdsInstance;
    }
    catch (error) {
      console.error(error);
      Notificator.showDetachedNotification('Произошла ошибка при получении информации о выбранных элементах');
    }
    finally {
      loader && loader.deactivate();
    }
  }

  private filterUnselectedItems(filterById: number): void {
    this._unselectedIds.value = this._unselectedIds.value?.filter((id) => id !== filterById);
  }

  private filterSelectedItems(filterById: number): void {
    this._selectedIds.value = this._selectedIds.value?.filter((id) => id !== filterById);
  }

  private resetData(): void {
    this._selectedIds.value = [];
    this._isAllSelected.value = false;
    this._unselectedIds.value = [];
    this._selectedCount.value = 0;
  }
}
