<script setup lang="ts">
import { IconPathEnum } from '@/ui-kit/enums/iconPath.enum';
import SvgIcon from '@/ui-kit/components/SvgIcon.vue';
import { computed, ref, watch } from 'vue';
import { useClickOutside } from '@/ui-kit/composables/useClickOutside';
import type {
  ISingleCheckFilterParams,
  ISingleCheckFilterValue,
} from '@/common/models/filters/filter-model';

const props = withDefaults(defineProps<ISingleCheckFilterParams>(), {
  rotateIconWhenOpened: true,
  iconRotated: false,
  disabled: false,
  active: false,
  opened: false,
  viewValue: '',
  width: 232,
});

const emit = defineEmits<{
  (e: 'update:opened', value: boolean): void;
  (e: 'update:viewValue', value: unknown): void;
  (e: 'update:modelValue', value: unknown): void;
}>();

const isOpened = ref<boolean>(props.opened);
const viewValueRef = ref<string>(props.viewValue);
const alignLeft = ref<boolean>(false);
const menu = ref(null);
const wrapperRef = ref<HTMLDivElement>();
const selectedItem = ref<ISingleCheckFilterValue>();

const iconRotate = computed(() =>
  selectedItem.value
    ? `transform: rotate(${selectedItem.value?.iconRotateDeg ? selectedItem.value?.iconRotateDeg : 0}deg);`
    : null,
);

function isItemSelected(item: ISingleCheckFilterValue): boolean {
  return JSON.stringify(item) === JSON.stringify(props.modelValue);
}

function open(): void {
  if (!props.opened && !props.disabled) {
    checkOverflow();
    isOpened.value = true;
    emit('update:opened', true);
  }
}

function onSelectItem(item: ISingleCheckFilterValue) {
  selectedItem.value = item;
  emit('update:modelValue', item.value);
  closeFilter();
}

function checkOverflow() {
  const parentNativeElement = props.parentRef.$el || props.parentRef;
  const menuNativeElement = menu.value;
  const containerRect = parentNativeElement.getBoundingClientRect();
  const dropdownRect = menuNativeElement.getBoundingClientRect();
  alignLeft.value = dropdownRect.left < containerRect.left;
}

function stopPropagation(event: Event) {
  event.stopPropagation();
}

function getLabel(): string {
  if (viewValueRef.value) {
    return `${props.label}:`;
  } else {
    return props.label;
  }
}

function clear(event: Event): void {
  event.stopPropagation();
  viewValueRef.value = '';
  emit('update:viewValue', '');
}

function closeFilter() {
  emit('update:opened', false);
  isOpened.value = false;
}

useClickOutside(wrapperRef, closeFilter);

watch(
  () => props.opened,
  (value) => {
    isOpened.value = value;
  },
  { immediate: true },
);

watch(
  () => props.viewValue,
  (value) => {
    viewValueRef.value = value;
  },
  { immediate: true },
);
</script>

<template>
  <div
    ref="wrapperRef"
    class="filter d-flex align-items-center"
    :class="{
      'filter-active': viewValueRef,
      'filter-opened': isOpened && !viewValueRef,
      'filter-disabled': disabled
    }"
    @click="open"
  >
    <div class="d-flex align-items-center">
      <div
        class="filter-label mm-body-regular-s"
        :class="{ mr4: icon || viewValueRef }"
      >
        {{ getLabel() }}
      </div>
      <div
        v-if="viewValueRef"
        class="filter-value mm-body-regular-s mr4"
      >
        {{ viewValueRef }}
      </div>
    </div>
    <SvgIcon
      v-if="icon && (!viewValueRef || disabled)"
      class="filter-icon"
      :wrapper-classes="disabled ? 'icon--inactive' : ''"
      :class="{ 'filter-icon-rotate': (isOpened && rotateIconWhenOpened) || iconRotated }"
      :src="icon"
      :style="iconRotate"
    />
    <SvgIcon
      v-if="viewValueRef && !disabled"
      class="filter-icon"
      wrapper-classes="icon--white"
      :src="IconPathEnum.NavigationClose20PxSvg"
      @click="clear"
    />
    <div
      ref="menu"
      class="menu"
      :class="{ 'menu-opened': isOpened, 'menu-left': alignLeft && isOpened }"
      @click="stopPropagation"
    >
      <p
        v-if="title"
        class="menu--title"
      >
        {{ title }}
      </p>
      <div
        v-for="item in items"
        :key="item.id"
        :style="`width: ${width}px !important;`"
        class="menu--item"
        :class="{ selected: isItemSelected(item.value) }"
        @click="onSelectItem(item)"
      >
        <div class="menu--item__value">
          {{ item.name }}
          <SvgIcon
            v-if="isItemSelected(item.value)"
            :src="IconPathEnum.IndicatorsCheckmarkSvg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@styles/base/common/variables';

.filter {
  padding: 7px 7px 7px 15px;
  border: 1px solid $gray-200;
  border-radius: 6px;
  cursor: pointer;
  position: relative;

  &-icon {
    width: 20px;
    height: 20px;
    transition: all 300ms ease-out;

    &-rotate {
      transform: rotate(180deg);
    }
  }

  &-active {
    background: $text-dark-green;

    .filter-label {
      color: white !important;
      opacity: 0.8;
    }
  }

  &-opened {
    background: $light-gray;
  }

  &-label {
    white-space: nowrap;
    color: $text-dark-green;
  }

  &-value {
    color: white;
  }

  &-disabled {
    border: 1px solid $gray-200;
    background: $light-gray;

    .filter-label {
      color: $text-disabled !important;
    }

    .filter-value {
      color: $text-disabled;
    }

    .filter-icon {
      rect {
        fill: $text-disabled;
      }
    }
  }
}

.menu {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  box-shadow: -2px 2px 16px 0px #0319121f;
  padding: 0;
  visibility: hidden;
  z-index: 999;
  background: $white;
  cursor: default;
  border-radius: 8px;

  &-opened {
    visibility: visible;
    padding: 16px;
    top: 40px;
  }

  &-left {
    left: 0;
    transform: translateX(0);
  }

  &--title {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: $light-green;
    margin-bottom: 12px;
  }

  &--item {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }

    &__value {
      cursor: pointer;
      width: 100%;
      font-size: 14px;
      line-height: 20px;
      color: $text-black;
      font-weight: 400;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>
