import Projects from '@/views/srm/projects/Projects.vue';
import type { RouteRecordRaw } from 'vue-router';

export const projectRoutes: RouteRecordRaw[] = [
    {
        path: '',
        component: Projects,
        name: 'projects-root',
    },
    {
        path: ':tabName',
        component: Projects,
        name: 'projects-tab-name',
    },
];