export class DocumentBreakpointsUtils {
  static readonly mobileBreakpoint = 360;
  static readonly tabletBreakpoint = 768;
  static readonly desktopBreakpoint = 1279;
  static readonly largeDesktopBreakpoint = 1599;

  static getIsRenderedMobile(): boolean {
    return this.getIsMobile();
  }

  static getIsRenderedInValue(value: number) {
    return this.getIsInValue(value);
  }

  static getIsRenderedTablet(): boolean {
    return this.getIsTablet();
  }

  static getIsRenderedDesktop(): boolean {
    return this.getIsDesktop();
  }

  static getIsRenderLargeDesktop(): boolean {
    return this.getIsLargeDesktop();
  }

  static getIsRenderedSmallMobile(): boolean {
    return this.getIsSmallMobile();
  }

  // document is not defined на сервер-сайде, поэтому статические методы-геттеры декорируются этой функцией

  private static getIsInValue(value: number): boolean {
    return window.innerWidth < value;
  }

  private static getIsMobile(): boolean {
    return window.innerWidth >= this.mobileBreakpoint && window.innerWidth < this.tabletBreakpoint;
  }

  private static getIsTablet(): boolean {
    return window.innerWidth >= this.tabletBreakpoint && window.innerWidth < this.desktopBreakpoint;
  }

  private static getIsDesktop(): boolean {
    return (
      window.innerWidth >= this.desktopBreakpoint && window.innerWidth < this.largeDesktopBreakpoint
    );
  }

  private static getIsLargeDesktop(): boolean {
    return window.innerWidth >= this.largeDesktopBreakpoint;
  }

  private static getIsSmallMobile(): boolean {
    return window.innerWidth <= this.mobileBreakpoint;
  }
}
