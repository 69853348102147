export class TreeHelperUtils {
  static searchNodeByKey(
    key: string,
    keyValue: string | number,
    treeChildrenKey: string,
    root: Record<string, unknown>,
    strictEqual = true,
  ): Record<string, unknown> {
    if (root?.[treeChildrenKey] == null) {
      return null;
    }

    const stack = [root];
    while (stack.length) {
      const node = stack.pop();
      if (strictEqual ? node[key] === keyValue : node[key] == keyValue) {
        return node;
      }
      if (node?.[treeChildrenKey]) {
        stack.push(...(node?.[treeChildrenKey] as Array<Record<string, unknown>>));
      }
    }
    return null;
  }

  static searchFromArrayNodeByKey(
    key: string,
    keyValue: string | number,
    treeChildrenKey: string,
    root: Array<Record<string, unknown>>,
    strictEqual = true,
  ): Record<string, unknown> {
    let currentNode = null;
    for (let i = 0; i < root?.length; i++) {
      currentNode = TreeHelperUtils.searchNodeByKey(
        key,
        keyValue,
        treeChildrenKey,
        root[i] as unknown as Record<string, unknown>,
        strictEqual,
      );
      if (currentNode) {
        return currentNode;
      }
    }
    return null;
  }

  static enumeration(
    treeChildrenKey: string,
    root: Record<string, unknown>,
    mapFn?: (node: Record<string, unknown>) => Record<string, unknown>,
  ): Array<Record<string, unknown>> {
    if (root?.[treeChildrenKey] == null) {
      return [];
    }

    const tempArray = [];
    const stack = [root];
    while (stack.length) {
      const node = stack.pop();
      tempArray.push(mapFn ? mapFn(node) : node);
      if (node?.[treeChildrenKey]) {
        stack.push(...(node?.[treeChildrenKey] as Array<Record<string, unknown>>));
      }
    }
    return tempArray;
  }

  static enumerationArray(
    array: Array<Record<string, unknown>>,
    treeChildrenKey: string,
    mapFn?: (node: Record<string, unknown>) => Record<string, unknown>,
  ): Array<Array<Record<string, unknown>>> {
    return (array || []).map((item) => TreeHelperUtils.enumeration(treeChildrenKey, item, mapFn));
  }

  static searchPathToNodeByKey(
    key: string,
    keyValue: string | number,
    treeChildrenKey: string,
    root: Record<string, unknown>,
    strictEqual = true,
  ): Array<Record<string, unknown>> {
    if (root?.[treeChildrenKey] == null) {
      return [];
    }

    const stack = [[root, [root]]];
    while (stack.length) {
      const [node, path] = stack.pop();
      if (strictEqual ? node[key] === keyValue : node[key] == keyValue) {
        return path as Array<Record<string, unknown>>;
      }
      if (node?.[treeChildrenKey]) {
        stack.push(
          ...node[treeChildrenKey].map((item) => [
            item,
            [...(path as Array<Record<string, unknown>>), item],
          ]),
        );
      }
    }
    return [];
  }

  public static searchNodeByFilter<T extends { [P in keyof T]: unknown }>(
    nodes: Array<T>,
    childNodesKey: keyof T,
    filter: (node: T) => boolean,
    getNodeChildren?: (child: T) => Array<T>,
  ): T | undefined {
    if (!nodes.length) {
      return;
    }

    const queue: Array<T> = [...nodes];
    while (queue.length) {
      const node = queue.shift();

      if (filter(node)) {
        return node;
      }

      queue.push(
        ...((getNodeChildren ? getNodeChildren(node) : (node[childNodesKey] as Array<T>)) || []),
      );
    }
  }

  public static changeNodeValue<T extends { [P in keyof T]: unknown }>(
    nodes: Array<T>,
    childNodesKey: keyof T,
    cb: (child: T) => void,
    getNodeChildren?: (child: T) => Array<T>,
  ): void {
    if (!nodes?.length) {
      return;
    }

    nodes.forEach((child) => {
      cb(child);
      this.changeNodeValue<T>(
        getNodeChildren ? getNodeChildren(child) : (child[childNodesKey] as Array<T>),
        childNodesKey,
        cb,
        getNodeChildren,
      );
    });
  }

  public static searchNodesByFilter<T extends { [P in keyof T]: unknown }>(
    nodes: Array<T>,
    childNodesKey: keyof T,
    filter: (node: T) => boolean,
    onlyTopNodes?: boolean,
  ): Array<T> {
    const foundNodes: Array<T> = [];

    if (!nodes.length) {
      return foundNodes;
    }

    const queue: Array<T> = [...nodes];
    while (queue.length) {
      const node = queue.shift();

      if (onlyTopNodes) {
        if (filter(node)) {
          foundNodes.push(node);
        } else {
          queue.push(...((node[childNodesKey] as Array<T>) || []));
        }
      } else {
        if (filter(node)) {
          foundNodes.push(node);
        }

        queue.push(...((node[childNodesKey] as Array<T>) || []));
      }
    }

    return foundNodes;
  }

  public static deleteNodeByFilter<T extends { [P in keyof T]: unknown }>(
    nodes: Array<T>,
    childNodesKey: keyof T,
    filter: (node: T) => boolean,
  ): Array<T> {
    if (!nodes?.length) {
      return nodes;
    }

    return nodes.filter((child) => {
      if (!filter?.(child)) {
        return false;
      }

      if (child?.[childNodesKey]) {
        child[childNodesKey] = this.deleteNodeByFilter(
          child[childNodesKey] as Array<T>,
          childNodesKey,
          filter,
        );
      }

      return true;
    });
  }

  public static findAllNodeParents<T extends { [P in keyof T]: unknown }>(
    nodes: Array<T>,
    childNodesKey: keyof T,
    filter: (node: T) => boolean,
    getNodeChildren?: (child: T) => Array<T>,
    path = [] as Array<T>,
  ): Array<T> {
    if (!nodes.length) {
      return [];
    }

    for (const node of nodes) {
      if (filter(node)) {
        return path;
      }

      const result = this.findAllNodeParents(
        getNodeChildren ? getNodeChildren(node) : (node[childNodesKey] as Array<T>),
        childNodesKey,
        filter,
        getNodeChildren,
        [...path, node],
      );

      if (result.length) {
        return result;
      }
    }

    return [];
  }
}
