<script setup lang="ts">
import StaticPage from '@/common/components/static/StaticPage.vue';
import { useTenantStore } from '@/stores/tenant.store';

const tenantStore = useTenantStore();
const props = defineProps<{
  imageUrl: string,
  containerWidth: string
}>();
</script>

<template>
  <div>
    <StaticPage :image-url="props.imageUrl">
      <div>
        <div
          class="text-wrapper"
          :style="{'width': containerWidth}"
        >
          <h1 class="main-text">Всегда на связи и готовы прийти на помощь</h1>
          <p class="text">
            Если у вас возникли вопросы по работе сервиса или вы столкнулись <br> с ошибкой, пожалуйста, свяжитесь с нами по почте
            <a
              class="mm-text-link"
              :href="'mailto:' + tenantStore.tenant?.contactEmailExternal"
            >
              {{ tenantStore.tenant?.contactEmailExternal || '' }}
            </a>
          </p>
          <p class="text">Мы обязательно поможем!</p>
        </div>
      </div>
    </StaticPage>
  </div>
</template>

<style scoped lang="scss">

@import '../../../assets/styles/base/common/variables';

:global(.auth-wrapper) {
  background: white !important;
}

.not-found-content {
  height: 502px;
  justify-content: center;
  align-items: center;
  margin-top: 120px;

  .text-wrapper {
    margin-left: 135px;

    .main-text {
      font-size: 32px;
      font-weight: 500;
      line-height: 36px;
    }

    .text {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
    }
  }

  .mm-text-link {
    text-decoration: none;
    font-weight: 500;
  }

}

</style>
