<template>
  <div class="mm-password-field">
    <div class="mm-password-field__container">
      <TextField
        v-model="model"
        class="mm-password-field__field"
        :validation-field="validationField"
        icon-disabled
        :placeholder="placeholder"
        :disabled="disabled"
        :type="fieldType"
        :label="label"
        :clearable="false"
        :autocomplete="autocomplete"
        :error-message="customError"
        :start-autofocus="startAutofocus"
        :is-only-submit-validate="isOnlySubmitValidate"
        :disable-autocomplete="disableAutocomplete"
        :readonly="readonly"
        :max-length="maxLength"
        name="password"
        @enter="onEnter()"
        @click-button="emits('clickButton')"
      />

      <SvgIcon
        v-if="isShowIcon"
        src="action/eye"
        class="mm-password-field__icon"
        :class="{ 'mm-password-field__icon--active': fieldType === EFiledType.Text }"
        @click="onToggleInputType"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { FieldContext } from 'vee-validate';
import TextField from './TextField.vue';
import SvgIcon from './SvgIcon.vue';
import {ref, watch} from 'vue';

enum EFiledType {
  Password = 'password',
  Text = 'text'
}

const props = withDefaults(
  defineProps<{
    modelValue?: string;
    type?: string;
    placeholder?: string;
    label?: string;
    disabled?: boolean;
    autocomplete?: string;
    validationField?: FieldContext<string>;
    customError?: string;
    startAutofocus?: boolean;
    isOnlySubmitValidate?: boolean;
    isShowIcon?: boolean;
    readonly?: boolean;
    maxLength?: number;
    disableAutocomplete?: boolean;
  }>(),
  {
    disabled: false,
    autocomplete: 'current-password',
    isShowIcon: true,
    maxLength: 50,
  },
);

const emits = defineEmits<{
  (e: 'update:modelValue', event: string);
  (e: 'clickButton'): void;
  (e: 'enter'): void;
}>();

const model = ref<string>(props.modelValue);
const fieldType = ref<EFiledType>(EFiledType.Password);

function onToggleInputType(): void {
  fieldType.value = fieldType.value === EFiledType.Password ? EFiledType.Text : EFiledType.Password;
}

function onEnter() {
  emits('enter');
}

watch(
    () => model.value,
    (value: string) => emits('update:modelValue', value),
)
</script>

<style scoped lang="scss">
@import '../../assets/styles/base/common/variables';

.mm-password-field {
  &__container {
    position: relative;

    :placeholder {
      color: $light-green;
      opacity: 1;
    }

    ::placeholder {
      color: $light-green;
      opacity: 1;
    }
  }

  &__icon {
    position: absolute;
    top: 16px;
    right: 17px;
    z-index: 10;
    cursor: pointer;

    &--active {
      :deep(path) {
        fill: $pasword-filed-icon-active-c !important;
      }
    }
  }

  &__field {
    :deep(.mm-input__input) {
      &::-ms-reveal,
      &::-ms-clear {
        display: none;
      }
    }
  }
}
</style>
