<script lang="ts" setup>
import { FilterTypeEnum } from '@/common/enums/filterType.enum';
import type { Component } from 'vue';
import DictionaryFilter from './DictionaryFilter.vue';
import DictionaryTreeFilter from './DictionaryTreeFilter.vue';
import SortFilter from './SortFilter.vue';
import INumberPeriodFilter from './NumberPeriodFilter.vue';
import DatesFilter from './DatesFilter.vue';
import type { FilterComponentParams } from '@/common/models/filters/filter-model';
import SingleCheckFilter from './SingleCheckFilter.vue';

defineProps<{
  filter: FilterComponentParams;
  disabled?: boolean;
  modelValue?: unknown;
  parentRef: unknown;
}>();

defineEmits<{
  (e: 'update:modelValue', value?: unknown): void;
}>();

const filtersMap = new Map<FilterTypeEnum, Component>([
  [FilterTypeEnum.Dictionary, DictionaryFilter],
  [FilterTypeEnum.DictionaryTree, DictionaryTreeFilter],
  [FilterTypeEnum.Sort, SortFilter],
  [FilterTypeEnum.NumberPeriod, INumberPeriodFilter],
  [FilterTypeEnum.Date, DatesFilter],
  [FilterTypeEnum.SingleCheck, SingleCheckFilter],
]);
</script>

<template>
  <div class="filter-wrapper">
    <component
      :is="filtersMap.get(filter.type)"
      v-bind="filter"
      :parent-ref="parentRef"
      :model-value="modelValue"
      :disabled="disabled"
      @update:model-value="(event) => $emit('update:modelValue', event)"
    />
  </div>
</template>
