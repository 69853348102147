import PerfectScrollbar from 'perfect-scrollbar';
import type { IUsePerfectScrollbar } from '../models/composables/usePerfectScrollbar.model';
import { nextTick, ref } from 'vue';

export function usePerfectScrollbar(element: string | Element): IUsePerfectScrollbar {
  const instance = ref<PerfectScrollbar | null>(null);

  function init(): void {
    if (instance.value) {
      destroy();
    }

    nextTick(() => {
      if (!element || (typeof element === 'string' && !document.querySelector(element))) {
        return;
      }

      instance.value = new PerfectScrollbar(element);
    });
  }

  function destroy(): void {
    instance.value?.destroy();
    instance.value = null;
  }

  function update(): void {
    instance.value?.update();
  }

  return {
    instance,
    init,
    destroy,
    update,
  };
}
