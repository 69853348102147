import { defineStore } from 'pinia';
import type { ITenant } from '@/common/models/tenant.model';
import { useTenantsApi } from '@/common/api/useTenantApi';

export const useTenantStore = defineStore('tenant', {
  state: () => ({
    tenant: null as ITenant,
  }),
  actions: {
    async updateTenant(): Promise<void> {
      const api = useTenantsApi();
      this.tenant = await api.getCurrentTenant();
    },
  },
});
