<script setup lang="ts">
import Tab from '@/ui-kit/components/tabs/Tab.vue';
import Tabs from '@/ui-kit/components/tabs/Tabs.vue';
import type { ITab, TTabValue } from '@/ui-kit/models/tabs.model';
import { useRoute, useRouter } from 'vue-router';
import { ref, watch } from 'vue';
import { StringsHelper } from '@/ui-kit/utils/strings.util';

const props = defineProps<{
  tabList: Array<ITab>;
  hideBorder?: boolean;
}>();

const route = useRoute();
let foundActiveTab: ITab;
let defaultActiveTabValue: TTabValue;
const activeTabValue = ref<TTabValue>(null);
setDefaults();

const router = useRouter();

function formatCount(count: number): string {
  return StringsHelper.toPriceString(count);
}

function getFoundActiveTab(): ITab {
  return props.tabList
    .filter((item) => item.path)
    .find((item) => route.path.includes(item.path as string));
}

function setDefaults(): void {
  foundActiveTab = getFoundActiveTab();
  defaultActiveTabValue = foundActiveTab ? foundActiveTab.value : null;
  activeTabValue.value = defaultActiveTabValue;
}

watch(route, () => {
  setDefaults();
});

watch(() => activeTabValue.value, (newValue) => {
  const foundItem = props.tabList.find((item) => item.value === newValue);
  if (foundItem && foundItem.path) {
    router.push(`${foundItem.path}`);
  }
});
</script>

<template>
  <Tabs
    v-model="activeTabValue"
    class="page-tabs"
    :centered-horizontal="false"
    :hide-border="hideBorder"
  >
    <Tab
      v-for="(tab, index) in tabList"
      :key="index"
      :value="tab.value"
      custom-class="page-tab"
    >
      <div
        class="tab d-flex mm-body-medium-s"
        :class="{
          'tab-disabled': tab.unselectable,
          'tab-active': tab.value === activeTabValue
        }"
      >
        <div :class="{ mr4: tab.count !== undefined }">{{ tab.label }}</div>
        <div v-if="tab.count !== undefined && tab.label !== 'Все'">{{ formatCount(tab.count as number) }}</div>
      </div>
    </Tab>
  </Tabs>
</template>

<style scoped lang="scss">
@import '@styles/base/common/variables';

.page-tabs {
  width: auto;
}

.page-tab {
  margin: 0;
  border-width: 3px !important;
}

.tab {
  color: $text-light-green;

  &:not(:first-child) {
    margin-left: 40px;
  }

  padding: 0 0 17px 0;

  &-active {
    color: $text-black;
  }

  &-disabled {
    color: $text-disabled;
  }
}
</style>
