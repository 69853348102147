import { createRouter, createWebHistory } from 'vue-router';
import { appRoutes } from '@/views/srm/routes';
import { authRoutes } from '@/views/auth/routes';
import { useProfileStore } from '@/stores/profile.store';
import { waitForCurrentRole, waitForCurrentUser } from '@/common/utils/waitForCurrentUser';
import { SupplierStatusEnum } from '@/common/enums/supplierStatus.enum';
import { useAuthToken } from '@/common/composables/useAuthToken';
import type { PermissionsEnum } from '@/common/enums/permissions.enum';
import StaticPage from '@/common/components/static/StaticPage.vue';

const { isAuthorized } = useAuthToken();

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'app',
      component: () => import('./views/srm/Layout.vue'),
      children: appRoutes,
    },
    {
      path: '/auth',
      component: () => import('./views/auth/AuthWrapper.vue'),
      children: authRoutes,
    },
    {
      path: '/:pathMatch(.*)*',
      component: () =>  isAuthorized() ? import('./views/srm/Layout.vue') : import('./views/auth/AuthWrapper.vue'),
      children: [
        {
          path: '/:pathMatch(.*)*',
          component: StaticPage,
          props: {
            imageUrl: '/images/notFoundImg.jpg',
            mainText: 'К сожалению, страница не найдена',
            text: 'Возможно, вы ввели неправильный адрес \nили страница была удалена. Попробуйте повторить попытку или воспользуйтесь навигацией.',
            containerWidth: '374px',
          },
          name: 'not-found',
        },
      ],
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const profileStore = useProfileStore();
  const queryRoleName = sessionStorage.getItem('roleName');
  if (queryRoleName) {
    profileStore.setCurrentRoleName(queryRoleName);
  }
  if (to.fullPath.startsWith('/auth')) {
    next();
    return;
  }
  const status = await getUserStatus();
  if (status) {
    if (!queryRoleName) {
      await waitForCurrentRole();
    }
    if (to.path === '/') {
      const preferredLocations = status === SupplierStatusEnum.Registered ? ['/projects', '/supplier-profile'] : ['/supplier-profile', '/projects'];
      for (let i = 0; i < preferredLocations.length; i++) {
        const route = router.resolve(preferredLocations[i]);
        const hasAccess = await checkAccess(route?.meta?.neededPermissions as PermissionsEnum[]);
        if (hasAccess) {
          next({
            path: preferredLocations[i],
          });
          return;
        }
      }
      next({
        path: '/auth/access-denied',
      });
    } else {
      const hasAccess = await checkAccess(to.meta?.neededPermissions as PermissionsEnum[]);
      if (hasAccess) {
        next();
      } else {
        next({
          path: '/auth/access-denied',
        });
      }
    }
  } else {
    next({
      path: '/auth/login',
    });
  }
});

function getUserStatus() {
  const profileStore = useProfileStore();
  if (isAuthorized()) {
    return waitForCurrentUser(() => profileStore.getProfile?.status);
  }
  return false;
}

async function checkAccess(neededPermissions: PermissionsEnum[]): Promise<boolean> {
  const userStore = useProfileStore();

  return waitForCurrentUser(() => {
    return userStore.hasPermission(neededPermissions)
  });
}

export default router;
