<script setup lang="ts">
import AppTable from '@/ui-kit/components/AppTable.vue';
import PageBottomActions from '@/common/components/page/PageBottomActions.vue';
import LoaderButton from '@/ui-kit/components/LoaderButton.vue';
import { onBeforeMount, ref } from 'vue';
import ClipLoader from '@/ui-kit/components/ClipLoader.vue';
import Checkbox from '@/ui-kit/components/Checkbox.vue';
import Loader from '@/ui-kit/utils/loaderHelper.util';
import {
  useNotificationsSettingsApi,
} from '@/views/srm/account-settings/notifications/api/useNotificationsSettingsApi';
import { useProfileStore } from '@/stores/profile.store';
import type {
  INotificationsSettingItem,
} from '@/views/srm/account-settings/notifications/api/models/notificationsSettings.model';
import Notificator from '@/ui-kit/services/notificator.service';
import {
  NOTIFICATIONS_TABLE_COLUMNS,
} from '@/views/srm/account-settings/notifications/api/constants/notificationsTableColumns.const';
import { useAuthApi } from '@/common/api/useAuthApi';
import type { IBaseUser } from '@/common/models/base-user';

const isLoading = Loader.getReactiveInstance();
const isSaving = Loader.getReactiveInstance();
const api = useNotificationsSettingsApi();
const profileStore = useProfileStore();
const settings = ref<Array<INotificationsSettingItem>>([]);
const isDirty = ref<boolean>(false);
const { getCurrentUser } = useAuthApi();

const userInfo = ref<IBaseUser>()

async function loadData(): Promise<void> {
  try {
    isLoading.activate();
    userInfo.value = await getCurrentUser()
    const settingsResponse = await api.getNotificationSettings();
    const foundSetting = settingsResponse?.find((setting: INotificationsSettingItem) => setting.roleId === profileStore?.currentRole?.id);
    settings.value = foundSetting?.notifications;
  }
  catch (error) {
    console.error(error);
    Notificator.showDetachedNotification('Произошла ошибка при загрузке настроек уведомлений');
  }
  finally {
    isLoading.deactivate();
  }
}

async function onSave(): Promise<void> {
  try {
    isSaving.activate();
    await api.updateNotificationSettings(settings.value?.map((setting) => ({
      isSystem: setting.isSystem,
      isEmail: setting.isEmail,
      notificationType: setting.notificationType,
    })));
    isDirty.value = false;
    Notificator.showDetachedSuccessNotification('Настройки успешно сохранены', {
      toastClassName: 'mm-notification__toast big-mb',
    });
  }
  catch (error) {
    console.error(error);
    Notificator.showDetachedNotification('Произошла ошибка при сохранении настроек');
  }
  finally {
    isSaving.deactivate();
  }
}

onBeforeMount(async () => {
  await loadData();
});
</script>

<template>
  <div class="notifications-settings">
    <ClipLoader v-if="isLoading.value" />
    <div v-else>
      <h4>Какие уведомления вы хотите получать?</h4>
      <p>
        Уведомления по указанным событиям будут появляться в вашем профиле и направляться на почту <br>
        <span>{{ userInfo.email }}</span>
      </p>

      <Suspense>
        <AppTable
          :columns="NOTIFICATIONS_TABLE_COLUMNS"
          :data="settings"
          :show-more-count="settings?.length"
        >
          <template #notification="{ row }: { row: INotificationsSettingItem }">
            <div class="setting-title">
              {{ row.title }}
            </div>
          </template>
          <template #system="{ row }: { row: INotificationsSettingItem }">
            <div>
              <Checkbox
                :model-value="row.isSystem"
                :disabled="isSaving.value"
                @update:model-value="(event: boolean) => {
                  row.isSystem = event;
                  isDirty = true;
                }"
              />
            </div>
          </template>
          <template #email="{ row }: { row: INotificationsSettingItem }">
            <div>
              <Checkbox
                :model-value="row.isEmail"
                :disabled="isSaving.value"
                @update:model-value="(event: boolean) => {
                  row.isEmail = event;
                  isDirty = true;
                }"
              />
            </div>
          </template>
        </AppTable>
      </Suspense>
    </div>
  </div>

  <PageBottomActions :content-width="1520">
    <template #right>
      <LoaderButton
        :custom-disabler="isLoading.value || !isDirty"
        :loader="isSaving"
        class="save-btn"
        @click="onSave"
      >
        Сохранить
      </LoaderButton>
    </template>
  </PageBottomActions>
</template>

<style scoped lang="scss">
@import '@styles/base/common/variables';

.notifications-settings {
  max-width: 756px;
  margin: 0 auto;
  padding-top: 40px;
  min-height: 70vh;
  padding-bottom: 100px;

  .setting-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $text-black;
  }

  h4 {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    margin: 0;
    color: $text-black;
  }

  p {
    color: $text-dark-green;
    margin-top: 16px;
    margin-bottom: 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    span {
      color: $link;
    }
  }

  :deep(.mm-table__header-row) {
    th {
      padding-top: 11px;
      padding-bottom: 11px;

      &:first-child {
        padding-left: 20px;
      }

      &:last-child {
        padding-right: 20px;
      }

      &:not(:first-child) {
        > div {
          display: flex;
          justify-content: center;
        }
      }

      .mm-table__header-row-content {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  :deep(.mm-table__body) {
    .mm-table__row {
      td {
        padding: 18px 0;

        .checkbox__input {
          margin: 0;
        }

        &:first-child {
          padding-left: 20px;
          padding-top: 20px;
          padding-bottom: 20px;
        }

        &:last-child {
          padding-right: 20px;
        }

        &:not(:first-child) {
          > div {
            display: flex;
            justify-content: center;
            align-items: flex-start;
          }
        }
      }
    }
  }
}

.save-btn {
  width: 247px;
  height: 40px;
  justify-content: center;
}
</style>
