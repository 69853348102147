<script setup lang="ts">
import StaticPage from '@/common/components/static/StaticPage.vue';
import { useTenantStore } from '@/stores/tenant.store';

const tenantStore = useTenantStore();
const props = defineProps<{
  imageUrl: string,
  containerWidth: string
}>();

</script>

<template>
  <div>
    <StaticPage :image-url="props.imageUrl">
      <div>
        <div
          class="text-wrapper"
          :style="{'width': containerWidth}"
        >
          <p class="main-text">Для просмотра недостаточно полномочий</p>
          <p class="text">
            Для просмотра информации недостаточно прав <br>
            доступа. Обратитесь к администратору вашего <br>
            аккаунта или в поддержку по почте <br>
            <a
              :href="'mailto:' + tenantStore.tenant?.contactEmailExternal"
              class="mm-link"
            >
              {{ tenantStore.tenant?.contactEmailExternal || '' }}
            </a>
          </p>
        </div>
      </div>
    </StaticPage>
  </div>
</template>

<style scoped lang="scss">

@import '../../../assets/styles/base/common/variables';

.not-found-content {
  height: 502px;
  justify-content: center;
  align-items: center;
  margin-top: 120px;

  .text-wrapper {
    margin-left: 135px;

    .main-text {
      font-size: 32px;
      font-weight: 500;
      line-height: 36px;
    }

    .text {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;

    }
  }

}

</style>
