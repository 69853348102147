import axios from 'axios';
import type {
  ICheckSMSRequest,
  ILoginSmsCodeRequest,
  IRepeatSMSCodeTimeoutResponse,
  IRequestForSmsCode,
  IResetPasswordRequest, ITempTokenResponse,
} from '@/views/auth/models/auth.model';
import type { ISupplierUniqueCheck } from '@/views/auth/pages/registration/models/checkUnique.model';
import type { ICreateUser, ICreateUserConfirmRequest } from '@/views/auth/pages/registration/models/createUser.model';
import type { ICreateUserResult } from '@/views/auth/pages/registration/models/createUserResult.model';
import type { IUserId } from '@/views/auth/pages/registration/models/userId.model';
import type { SMSOtpTypeEnum } from '@/views/auth/api/enums/smsOtpType.enum';
import type { ISupplierModel, ISupplierUser } from '@/views/auth/pages/invitation/models/supplierUser.model';
import type { IUserRegisterConfirm } from '@/views/auth/pages/registration/models/userRegister.model';
import type { IAuthToken } from '@/common/models/api/auth/authToken.model';

export function useAuthorizationApi(): IAuthorizationApi {
  const baseApiUrl = `${import.meta.env.VITE_API_URL}/auth/login`;
  const baseAuthUrl = `${import.meta.env.VITE_API_URL}/auth`;
  const baseUsersApiUrl = `${import.meta.env.VITE_API_URL}/users`
  const baseSuppliersUrl = `${import.meta.env.VITE_API_URL}/suppliers`;

  return {
    getSmsForLogin: async (data: ILoginSmsCodeRequest): Promise<ITempTokenResponse> => {
      const res = await axios.post(`${baseApiUrl}/sms/`, data);
      return res.data;
    },
    resetSupplierPassword: async (data: IResetPasswordRequest): Promise<void> => {
      const res = await axios.post(`${baseAuthUrl}/reset-password/`, data);
      return res.data;
    },
    getSmsForRecovery: async (data: IRequestForSmsCode): Promise<ITempTokenResponse> => {
      const res = await axios.post(`${baseAuthUrl}/recovery-password/`, data);
      return res.data;
    },
    repeatSmsForRecovery: async (phone: string, token: string): Promise<void> => {
      const res = await axios.post(`${baseAuthUrl}/recovery-password/repeat/`, { phone, token });
      return res.data;
    },
    repeatSmsForRegistration: async (phone: string, token: string): Promise<void> => {
      const res = await axios.post(`${baseAuthUrl}/registration/repeat/`, { phone, token });
      return res.data;
    },
    repeatSmsForUserRegistration: async (phone: string, token: string): Promise<void> => {
      const res = await axios.post(`${baseAuthUrl}/user-registration/repeat/`, { phone, token });
      return res.data;
    },
    repeatSmsForEmployeeRegistration: async (phone: string, token: string): Promise<void> => {
      const res = await axios.post(`${baseAuthUrl}/employee-confirm/repeat/`, { phone, token });
      return res.data;
    },
    checkSmsOtp: async (type: SMSOtpTypeEnum, data: ICheckSMSRequest): Promise<void> => {
      const res = await axios.post(`${baseAuthUrl}/check-sms-otp/${type}/`, data);
      return res.data;
    },
    repeatSmsForLogin: async (phone: string, tempToken: string): Promise<void> => {
      const res = await axios.post(`${baseApiUrl}/sms/repeat/`, { phone, token: tempToken });
      return res.data;
    },
    checkLastOtp: async (type: SMSOtpTypeEnum, phone: string): Promise<IRepeatSMSCodeTimeoutResponse> => {
      const res = await axios.post(`${baseAuthUrl}/check-last-otp/${type}/`, { phone });
      return res.data;
    },
    checkUnique: async (reqBody: ISupplierUniqueCheck): Promise<boolean> => {
      const res = await axios.post<{ isSupplierExist: boolean }>(`${baseSuppliersUrl}/existence/`, reqBody, {
        params: {
          skipAuth: true,
        },
      });
      return res.data.isSupplierExist;
    },
    getSmsForRegistration: async (reqBody: ICreateUser): Promise<ICreateUserResult> => {
      const res = await axios.post(`${baseSuppliersUrl}/registration/sms/`, reqBody, {
        params: {
          skipAuth: true,
        },
      });
      return res.data;
    },
    repeatConfirmRegistration: async (emailOtp: string): Promise<void> => {
      await axios.post(`${baseAuthUrl}/registration/repeat/`, { emailOtp });
    },
    confirmRegistration: async (registrationConfirmCode: string): Promise<IUserId> => {
      const res = await axios.post(
        `${baseSuppliersUrl}/registration-confirmation/`,
        { emailOtp: registrationConfirmCode },
        {
          params: {
            skipAuth: true,
          },
        },
      );
      return res.data;
    },
    createUserAndSupplier: async (data: ICreateUserConfirmRequest): Promise<void> => {
      const res = await axios.post(`${baseSuppliersUrl}/`, data, {
        params: {
          skipAuth: true,
        },
      });
      return res.data;
    },
    checkEmailOTP: async (emailOtp: string): Promise<ISupplierModel> => {
      const res = await axios.post(`${baseSuppliersUrl}/invitation/check/`, { emailOtp }, {
        params: {
          skipAuth: true,
        },
      });
      return res.data;
    },
    checkEmailOTPForEmployee: async (emailOtp: string): Promise<ISupplierModel> => {
      const res = await axios.post(`${baseSuppliersUrl}/employee-invitation/check/`, { emailOtp }, {
        params: {
          skipAuth: true,
        },
      });
      return res.data;
    },
    createSupplierByInvitation: async (reqBody: IUserRegisterConfirm): Promise<void> => {
      const res = await axios.post(`${baseSuppliersUrl}/invitation/`, reqBody, {
        params: {
          skipAuth: true,
        },
      });
      return res.data;
    },
    createSupplierByEmployeeInvitation: async (reqBody: IUserRegisterConfirm): Promise<void> => {
      const res = await axios.post(`${baseSuppliersUrl}/employee-confirm/`, reqBody, {
        params: {
          skipAuth: true,
        },
      });
      return res.data;
    },
    getSmsForInvitation: async (reqBody: ISupplierUser): Promise<ITempTokenResponse> => {
      const res = await axios.post(`${baseSuppliersUrl}/invitation/sms/`, reqBody, {
        params: {
          skipAuth: true,
        },
      });
      return res.data;
    },
    getSmsForEmployeeInvitation: async (reqBody: ISupplierUser): Promise<ITempTokenResponse> => {
      const res = await axios.post(`${baseSuppliersUrl}/employee-confirm/sms/`, reqBody, {
        params: {
          skipAuth: true,
        },
      });
      return res.data;
    },
    repeatEmailConfirm: async (email: string): Promise<void> => {
      const res = await axios.post(`${baseUsersApiUrl}/email-confirmation/send/`, { email }, {
        params: {
          skipAuth: true,
        },
      });
      return res.data;
    },
    confirmEmail: async (emailOtp: string): Promise<void> => {
      const res = await axios.post(`${baseUsersApiUrl}/email/confirm/`, { emailOtp }, {
        params: {
          skipAuth: true,
        },
      });
      return res.data;
    },
    logout: async (refreshToken: string): Promise<void> => {
      const res = await axios.post(`${baseAuthUrl}/logout/`, {
        refreshToken,
      });
      return res.data;
    },
  }
}

export interface IAuthorizationApi {
  getSmsForLogin: (data: ILoginSmsCodeRequest) => Promise<ITempTokenResponse>;
  resetSupplierPassword: (data: IResetPasswordRequest) => Promise<IAuthToken>;
  getSmsForRecovery: (data: IRequestForSmsCode) => Promise<ITempTokenResponse>;
  repeatSmsForRecovery: (phone: string, token: string) => Promise<void>;
  repeatSmsForRegistration: (phone: string, token: string) => Promise<void>;
  repeatSmsForUserRegistration: (phone: string, token: string) => Promise<void>;
  repeatSmsForEmployeeRegistration: (phone: string, token: string) => Promise<void>;
  checkSmsOtp: (type: SMSOtpTypeEnum, data: ICheckSMSRequest) => Promise<void>;
  repeatSmsForLogin: (phone: string, tempToken: string) => Promise<void>;
  checkLastOtp: (type: SMSOtpTypeEnum, phone: string) => Promise<IRepeatSMSCodeTimeoutResponse>;
  createUserAndSupplier: (data: ICreateUserConfirmRequest) => Promise<IAuthToken>;
  checkUnique: (reqBody: ISupplierUniqueCheck) => Promise<boolean>;
  getSmsForRegistration: (reqBody: ICreateUser) => Promise<ITempTokenResponse>;
  repeatConfirmRegistration: (emailOtp: string) => Promise<void>;
  confirmRegistration: (registrationConfirmCode: string) => Promise<IUserId>;
  checkEmailOTP: (emailOtp: string) => Promise<ISupplierModel>;
  checkEmailOTPForEmployee: (emailOtp: string) => Promise<ICreateUser>;
  createSupplierByInvitation: (reqBody: ISupplierUser) => Promise<IAuthToken>;
  createSupplierByEmployeeInvitation: (reqBody: ISupplierUser) => Promise<IAuthToken>;
  getSmsForInvitation: (reqBody: ISupplierUser) => Promise<ITempTokenResponse>;
  getSmsForEmployeeInvitation: (reqBody: ISupplierUser) => Promise<ITempTokenResponse>;
  repeatEmailConfirm: (email: string) => Promise<void>;
  confirmEmail: (emailOtp: string) => Promise<void>;
  logout: (refreshToken: string) => Promise<void>;
}
