import type { RouteRecordRaw } from 'vue-router';
import NoAccessRights from '@/views/auth/pages/NoAccessRights.vue';
import LoginPage from '@/views/auth/pages/login/LoginPage.vue';
import RegistrationPage from '@/views/auth/pages/registration/RegistrationPage.vue';
import ResetPasswordPage from '@/views/auth/pages/reset/ResetPasswordPage.vue';
import InvitationPage from '@/views/auth/pages/invitation/InvitationPage.vue';
import SessionExpired from '@/views/auth/pages/SessionExpired.vue';
import ConfirmEmailLinkExpired from '@/views/auth/pages/ConfirmEmailLinkExpired.vue';
import StaticPage from '@/common/components/static/StaticPage.vue';
import InvitationExpired from '@/views/auth/pages/InvitationExpired.vue';
import EmailConfirmed from '@/views/auth/pages/EmailConfirmed.vue';
import AccessDenied from '@/views/auth/pages/AccessDenied.vue';

export const authRoutes: RouteRecordRaw[] = [
  {
    path: 'no-access-rights',
    component: NoAccessRights,
    name: 'no-access-rights',
    props: {
      imageUrl: '/images/noAccessRights.png',
      containerWidth: '501px',
    },
  },
  {
    path: 'email-confirmation/:otp',
    component: EmailConfirmed,
    name: 'email-confirmation',
    props: {
      imageUrl: '/images/emailConfirmed.png',
      containerWidth: '501px',
    },
  },
  {
    path: 'access-denied',
    component: AccessDenied,
    props: {
      imageUrl: '/images/accessDeniedImg.jpg',
      containerWidth: '422px',
    },
    name: 'access-denied',
  },
  {
    path: 'session-expired',
    component: SessionExpired,
    name: 'session-expired',
    props: {
      imageUrl: '/images/sessionExpired.png',
      containerWidth: '501px',
    },
  },
  {
    path: 'invitation-expired',
    component: InvitationExpired,
    name: 'invitation-expired',
    props: {
      imageUrl: '/images/hourglass.png',
      containerWidth: '501px',
    },
  },
  {
    path: 'link-expired',
    component: ConfirmEmailLinkExpired,
    name: 'link-expired',
    props: {
      imageUrl: '/images/linkExpired.png',
      containerWidth: '501px',
    },
  },
  {
    path: 'login',
    component: LoginPage,
    name: 'login',
  },
  {
    path: 'registration',
    component: RegistrationPage,
    name: 'registration',
  },
  {
    path: 'invitation/:key',
    component: InvitationPage,
    name: 'invitation',
  },
  {
    path: 'employee-invitation/:key',
    component: InvitationPage,
    name: 'employee-invitation',
  },
  {
    path: 'reset-password',
    component: ResetPasswordPage,
    name: 'reset-password',
  },
  {
    path: '/:pathMatch(.*)*',
    component: StaticPage,
    props: {
      imageUrl: '/images/notFoundImg.jpg',
      mainText: 'К сожалению, страница не найдена',
      text: 'Возможно, вы ввели неправильный адрес \nили страница была удалена. Попробуйте повторить попытку или воспользуйтесь навигацией.',
      containerWidth: '374px',
    },
    name: 'not-found',
  },
];
