<script setup lang="ts">
import { onBeforeMount, ref, watch } from 'vue';
import Notificator from '@/ui-kit/services/notificator.service';
import UserDataForm from '@/views/auth/pages/registration/components/UserDataForm.vue';
import type { IUserRegister, IUserRegisterConfirm } from '@/views/auth/pages/registration/models/userRegister.model';
import { ActiveRegistrationScreenEnum } from '@/views/auth/pages/registration/enums/activeRegistrationScreen.enum';
import RegistrationResultAlert from '@/views/auth/pages/registration/components/RegistrationResultAlert.vue';
import { useRoute, useRouter } from 'vue-router';
import type { ISupplierModel, ISupplierUser } from '@/views/auth/pages/invitation/models/supplierUser.model';
import SMSInputView from '@/views/auth/components/SMSInputView.vue';
import { useAuthorizationApi } from '@/views/auth/api/useAuthorizationApi';
import { SMSOtpTypeEnum } from '@/views/auth/api/enums/smsOtpType.enum';
import type { IAuthToken } from '@/common/models/api/auth/authToken.model';
import { useAuthToken } from '@/common/composables/useAuthToken';
import { useSupplierApi } from '@/common/api/useSupplierApi';
import { useUserApi } from '@/views/srm/account-settings/api/useUserApi';
import { useProfileStore } from '@/stores/profile.store';
import { ErrorTypeEnum } from '@/common/enums/error-type.enum';
import { useTenantStore } from '@/stores/tenant.store';

const api = useAuthorizationApi();
const route = useRoute();
const router = useRouter();
const { saveToken } = useAuthToken();
const profileApi = useSupplierApi();
const { getCurrent } = useUserApi();
const userStore = useProfileStore();
const tenantStore = useTenantStore();

const supplier = ref<ISupplierModel>();
const user = ref<ISupplierUser>();
const isLoading = ref<boolean>(false);
const activeScreen = ref<ActiveRegistrationScreenEnum>();
const key = route.params['key'] as string;
const captchaResetter = ref<number>(0);
const tempUserToken = ref<string>();
let previousScreen: ActiveRegistrationScreenEnum = null;
const isEmployeeInvitation = route.path.includes('employee');

async function checkSmsInformation() {
  return  await api.checkLastOtp(
    isEmployeeInvitation
      ? SMSOtpTypeEnum.EmployeeInvitation
      : SMSOtpTypeEnum.UserRegistration,
    user.value?.mobilePhoneNumber,
  );
}

async function loadSupplier(): Promise<void> {
  try {
    if (!isEmployeeInvitation) {
      supplier.value = await api.checkEmailOTP(key);
    }
    else {
      const res = await api.checkEmailOTPForEmployee(key);
      supplier.value = res.supplier;
      user.value = res.user;
    }
    activeScreen.value = ActiveRegistrationScreenEnum.User
  } catch (e) {
    errorHandler(e);
  }
}

async function onProceedRegistration(smsOtp: string): Promise<void> {
  await api.checkSmsOtp(isEmployeeInvitation ? SMSOtpTypeEnum.EmployeeInvitation : SMSOtpTypeEnum.UserRegistration, {
    phone: user.value?.mobilePhoneNumber,
    smsOtp,
  })
  const body: IUserRegisterConfirm = {
    ...user.value,
    token: tempUserToken.value,
    smsOtp,
  }
  delete body.smartToken;
  let token: IAuthToken;

  if (isEmployeeInvitation) {
    delete body.mobilePhoneNumber;
    body.mobilePhone = user.value?.mobilePhoneNumber;
    token = await api.createSupplierByEmployeeInvitation(body);
  }
  else {
    token = await api.createSupplierByInvitation(body);
  }

  saveToken(token);
  const profileInfo = await profileApi.getBaseProfile();
  const current = await getCurrent();
  userStore.setCurrent(current);
  userStore.setProfile(profileInfo);
  await router.push('/');
}

async function repeatSms(): Promise<void> {
  if (isEmployeeInvitation) {
    await api.repeatSmsForEmployeeRegistration(user.value?.mobilePhoneNumber, tempUserToken.value);
  }
  else {
    await api.repeatSmsForUserRegistration(user.value?.mobilePhoneNumber, tempUserToken.value);
  }
}

async function onGoToSMSPage(data: IUserRegister): Promise<void> {
  try {
    isLoading.value = true;
    user.value = {emailOtp: key, ...data, id: user.value?.id};
    if (!isEmployeeInvitation) {
      tempUserToken.value = (await api.getSmsForInvitation(user.value))?.token;
    }
    else {
      user.value.mobilePhone = user.value.mobilePhoneNumber;
      tempUserToken.value = (await api.getSmsForEmployeeInvitation(user.value))?.token;
    }
    activeScreen.value = ActiveRegistrationScreenEnum.ConfirmRegistration;
  } catch (e) {
    errorHandler(e);
  } finally {
    isLoading.value = false;
  }
}

function errorHandler(e): void {
  captchaResetter.value++;
  const expiredError = e.response?.data?.errors?.find((item) => item.code === ErrorTypeEnum.Expired);
  const notFoundError = e.response?.data?.errors?.find((item) => item.code === ErrorTypeEnum.NotFound);
  const alreadyExistsError = e.response?.data?.errors?.find((item) => item.code === ErrorTypeEnum.InvalidData);
  if (expiredError) {
    router.push('/auth/invitation-expired');
  } else if (alreadyExistsError) {
    activeScreen.value = ActiveRegistrationScreenEnum.RegistrationIsNotPossible;
  } else if (notFoundError) {
    router.push('/auth/not_found');
  } else if (e.response?.status === 404) {
    router.push('/auth/login');
  } else {
    Notificator.showDetachedNotification('Произошла непредвиденная ошибка');
  }
}

function navigationBack(): void {
  activeScreen.value = previousScreen;
}

watch(() => activeScreen.value, (newValue, previousValue) => {
  previousScreen = previousValue;
});

onBeforeMount(() => {
  loadSupplier();
})
</script>

<template>
  <UserDataForm
    v-if="activeScreen === ActiveRegistrationScreenEnum.User"
    :isLoading="isLoading"
    :supplierInvitation="supplier"
    :captcha-reset="captchaResetter"
    disable-autocomplete
    :user="user"
    hide-no-account-alert
    @proceed="onGoToSMSPage"
  />

  <SMSInputView
    v-if="activeScreen === ActiveRegistrationScreenEnum.ConfirmRegistration"
    :key="activeScreen"
    :phone="user.mobilePhoneNumber"
    :get-sms-fn="repeatSms"
    :callback-fn="onProceedRegistration"
    :get-sms-timeout-fn="checkSmsInformation"
    @back="navigationBack"
  />

  <RegistrationResultAlert
    v-if="activeScreen === ActiveRegistrationScreenEnum.RegistrationIsNotPossible"
    :title="'Невозможно зарегистрироваться \nв системе с указанными данными \nконтрагента'"
    @back="navigationBack"
  >
    <div class="mm-body-regular-m mm-color-black">
      Для получения помощи обратитесь в техническую поддержку <br> по электронной почте <a
        class="mm-text-undecorated-link mm-font-500"
        :href="'mailto:' + tenantStore.tenant?.contactEmailExternal"
        target="_blank"
      >
        {{ tenantStore.tenant?.contactEmailExternal || '' }}
      </a>
    </div>
  </RegistrationResultAlert>
</template>

<style scoped lang="scss">
</style>

