<script setup lang="ts">
import AuthAlert from '@/views/auth/components/AuthAlert.vue';
import BackButton from '@/common/components/BackButton.vue';

defineProps<{
  title: string;
  noAccount?: boolean;
}>();

const emit = defineEmits<{
  (e: 'back', value: void): void;
}>();


function navigateBack(): void {
  emit('back');
}

</script>

<template>
  <div class="d-flex flex-column align-items-center">
    <div class="d-flex flex-column mt40">
      <BackButton @click="navigateBack">Назад</BackButton>
      <div class="d-flex flex-column align-items-center">
        <AuthAlert class="mt24">
          <div class="d-flex flex-column">
            <div class="title-wrap mm-headline-3 mm-color-black mm-font-500 mb20">{{ title }}</div>
            <slot />
          </div>
        </AuthAlert>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.title-wrap {
  white-space: pre-line
}
</style>
