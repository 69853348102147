<script setup lang="ts">
import { ModalsContainer } from 'vue-final-modal';
import { useSupplierApi } from '@/common/api/useSupplierApi';
import { useProfileStore } from '@/stores/profile.store';
import { useTenantStore } from '@/stores/tenant.store';
import Loader from '@/ui-kit/utils/loaderHelper.util';
import Notificator from '@/ui-kit/services/notificator.service';
import { onBeforeMount, onBeforeUnmount, provide, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthToken } from '@/common/composables/useAuthToken';
import { useUserApi } from '@/views/srm/account-settings/api/useUserApi';
import { useNotificationsApi } from '@/common/api/useNotificationsApi';
import { useCookieWarning } from '@/common/composables/useCookieWarning';
import { waitForCurrentRole } from '@/common/utils/waitForCurrentUser';
import type { IStatuses } from '@/common/models/status.model';
import { STATUSES_DATA } from '@/common/constants/status.const';
import { STATUSES_UPDATE } from '@/common/constants/timingDefaults.const';

const api = useSupplierApi();
const profileStore = useProfileStore();
const tenantStore = useTenantStore();
const { getStatuses } = useNotificationsApi();
const isLoading = Loader.getReactiveInstance();
const router = useRouter();
const { isAuthorized } = useAuthToken();
const { getCurrent, getUserProfile } = useUserApi();
const notificationsApi = useNotificationsApi();
const statuses = ref<IStatuses>();
const shownIds = new Set<number>();
const intervalId = ref<number>();
const pageSize = ref<number>(20);
provide(STATUSES_DATA, { statuses });

useCookieWarning();

async function fetchStatuses(page: number = 1) {
  statuses.value = await getStatuses(page, pageSize.value);
}

async function startCheckStatuses() {
  intervalId.value = setInterval(() => fetchStatuses(1), STATUSES_UPDATE);
}

function clearUpdateStatuses() {
  clearInterval(intervalId.value);
}

async function loadProfile(): Promise<void> {
  try {
    isLoading.value = true;
    const user = await getCurrent();
    profileStore.setCurrent(user);
    await waitForCurrentRole();
    const profile = await api.getBaseProfile();
    profileStore.setProfile(profile);
    const profileRes = await getUserProfile();
    profileStore.setTimezone(profileRes.timezone);
    profileStore.setNotificationsCounters(await notificationsApi.getNotificationsCount());
    await fetchStatuses(1);
    await startCheckStatuses();
  } catch (e) {
    if (e.status_code == 401) {
      await router.push('/auth/login')
      return;
    }
    console.error(e)
    Notificator.showDetachedNotification('Произошла ошибка при загрузке профиля.');
  } finally {
    isLoading.value = false;
  }
}

watch(
  () => statuses.value?.notifications?.new?.notifications,
  (newStatuses) => {
    if (isAuthorized() && newStatuses && newStatuses.length > 0) {
      newStatuses.forEach((notification, index) => {
        if (!shownIds.has(notification.id)) {
          Notificator.showFloatingUserNotification(
            notification,
            notificationsApi,
            5000 * (Math.floor(index / 3) + 1),
          );
          shownIds.add(notification.id);
        }
      });
    }
  },
  { immediate: false },
);

onBeforeMount(async () => {
  const href = window.location.pathname;
  if (isAuthorized() && !href.startsWith('/auth/access-denied')) {
    await loadProfile();
  } else if (!href.includes('/auth')) {
    await router.push(`/auth/login?redirect=${href}`);
  }
  await tenantStore.updateTenant();
});

onBeforeUnmount(() => {
  clearUpdateStatuses();
});
</script>

<template>
  <RouterView />
  <ModalsContainer />
</template>

<style scoped></style>
