import { NotificationActionsEnum } from '../enums/notificationActions.enum';
import { POSITION } from 'vue-toastification';

export default class NotificatorSettings {
  static readonly DEFAULT_NOTIFICATION_PLUGIN_SETTINGS = {
    containerClassName: 'mm-notification',
    transition: 'none',
    maxToasts: 5,
    pauseOnHover: true,
    timeout: 5000,
    newestOnTop: false,
  };

  static readonly DEFAULT_NOTIFICATION_SETTINGS = {
    bodyClassName: 'mm-notification__body',
    toastClassName: 'mm-notification__toast',
    position: POSITION.BOTTOM_CENTER,
    hideProgressBar: true,
    closeOnClick: false,
    draggable: false,
    timeout: 5000,
  };

  static readonly CONTENT_FIT_NOTIFICATION_SETTINGS = {
    ...this.DEFAULT_NOTIFICATION_SETTINGS,
    toastClassName: 'mm-notification__toast mm-notification__toast--content-fit',
  };

  private static messageData = {
    [NotificationActionsEnum.UnauthorizedBasket]: 'Для просмотра корзины',
    [NotificationActionsEnum.UnauthorizedProposal]:
      'Для просмотра актуальных предложений, стоимости и сроков доставки',
    [NotificationActionsEnum.SuccessfulVerified]: 'Ваш e-mail успешно подтвержден. Теперь вы можете',
  };

  private static linkMessageData = {
    [NotificationActionsEnum.UnauthorizedBasket]: 'авторизуйтесь в системе',
    [NotificationActionsEnum.UnauthorizedProposal]: 'авторизуйтесь в системе',
    [NotificationActionsEnum.SuccessfulVerified]: 'авторизоваться',
  };

  public static getMessageByAction(action: NotificationActionsEnum | null): string {
    return action ? (this.messageData as unknown)[action] || '' : '';
  }

  public static getLinkMessageByAction(action: NotificationActionsEnum | null): string {
    return action ? (this.linkMessageData as unknown)[action] || '' : '';
  }
}
