export enum FileStatusEnum {
  Default,
  Queued,
  Uploading,
  Downloading,
  Deleting,
  Error,
  Impossible,
  Infected,
  Checking,
  SkipChecking,
  Done,
}

export enum FileCheckStatusEnum {
  New = 'NEW',
  OnCheck = 'ON_CHECK',
  Checked = 'CHECKED',
  Reject = 'REJECTED',
  Timeout = 'TIMEOUT',
  SkipCheck = 'SKIP_CHECK',
}