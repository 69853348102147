import { onBeforeUnmount, onMounted, type Ref } from 'vue';

export function useClickOutsideV2(ref: Ref<HTMLElement | null>, handler: (e: PointerEvent) => void, additionalRef?: Ref<HTMLElement | null>) {
  const listener = (e: PointerEvent) => {
    if (!ref.value) return;

    const clickInsideMain = ref.value.contains(e.target as Node);
    const clickInsideAdditional = additionalRef?.value ? additionalRef.value.contains(e.target as Node) : false;

    if (!clickInsideMain && !clickInsideAdditional) {
      handler(e);
    }
  };

  onMounted(() => {
    document.addEventListener('click', listener, true);
    document.addEventListener('touchstart', listener, true);
  });

  onBeforeUnmount(() => {
    document.removeEventListener('click', listener, true);
    document.removeEventListener('touchstart', listener, true);
  });
}

export function useClickOutside(ref: Ref, handler: (e: PointerEvent) => void, additionalRef?: Ref) {
  if (!document || !ref) {
    return;
  }

  const listener = (e: PointerEvent) => {
    if (
      e.target === ref.value ||
      e.composedPath().includes(ref.value) ||
      e.target === additionalRef?.value ||
      e.composedPath().includes(additionalRef?.value)
    ) {
      return;
    }

    handler(e);
  };

  onMounted(() => {
    document.body.addEventListener('click', listener);
    document.body.addEventListener('touchstart', listener);
  });

  onBeforeUnmount(() => {
    document.body.removeEventListener('click', listener);
    document.body.removeEventListener('touchstart', listener);
  });
}
