<script lang="ts" setup></script>

<template>
  <div class="empty-banner">
    <div class="empty-banner--wrapper">
      <div class="empty-banner--wrapper__title">
        <slot name="title" />
      </div>
      <div class="empty-banner--wrapper__desc">
        <slot name="description" />
      </div>
    </div>
    <slot name="button" />
    <div class="empty-banner--image">
      <slot name="image" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@assets/styles/base/common/_variables.scss';

.empty-banner {
  width: 100%;
  height: 360px;
  background-color: $light-gray;
  border-radius: 8px;
  padding: 40px 32px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  &--image {
    position: absolute;
    top: 40px;
    right: 28px;
  }

  &--wrapper {
    &__title {
      font-size: 28px;
      font-weight: 500;
      line-height: 36px;
      margin-bottom: 16px;
      color: $text-black;
    }

    &__desc {
      font-size: 14px;
      color: $text-black;
      line-height: 20px;
    }
  }
}
</style>
