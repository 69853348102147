<script setup lang="ts">
import Filter from '@/common/components/filters/Filter.vue';
import { IconPathEnum } from '@/ui-kit/enums/iconPath.enum';
import { ref, watch, computed, withDefaults, defineProps, defineEmits } from 'vue';
import type { IDictionaryValue } from '@/common/models/filters/dictionary-value';
import Checkbox from '@/ui-kit/components/Checkbox.vue';
import TextField from '@/ui-kit/components/TextField.vue';
import type { IDictionaryFilterParams } from '@/common/models/filters/filter-model';

const props = withDefaults(defineProps<IDictionaryFilterParams>(), {
  width: 247,
  confirmButtonText: 'Применить',
  maxHeight: 140,
});

const emits = defineEmits<{
  (e: 'update:modelValue', value?: unknown[]): void;
}>();

const isOpened = ref<boolean>(false);
const contentVisible = ref<boolean>(true);
const viewValue = ref<string>('');
const searchValue = ref<string>('');
const value = ref<unknown[]>(props.modelValue ? [...props.modelValue] : []);
const filteredItems = ref<IDictionaryValue[]>(props.items);
const isSelectedAll = computed<boolean>(() =>
  props.items?.every(
    (item) => !!value.value?.find((valueItem) => valueItem === item[props.valueKey]),
  ),
);

function checked(item: IDictionaryValue): boolean {
  const found = value.value.find((valueItem) => valueItem === item[props.valueKey]);
  return found !== null && found !== undefined;
}

function onValueUpdate(item: IDictionaryValue, checked: boolean): void {
  if (checked) {
    value.value.push(item[props.valueKey]);
  } else {
    value.value = value.value?.filter((valueItem) => valueItem !== item[props.valueKey]);
  }
}

async function confirm(): Promise<void> {
  let isUpdateConfirmed = true;

  if (props.updateConfirmCallback) {
    isUpdateConfirmed = await props.updateConfirmCallback();
  }

  if (!isUpdateConfirmed) {
    return;
  }

  emits('update:modelValue', [...value.value]);
  setViewValue(value.value);
  isOpened.value = false;
}

function setViewValue(values: unknown[]): void {
  if (!values?.length) {
    viewValue.value = '';
  }

  viewValue.value =
    values.length > 1
      ? `${values.length}`
      : props.items
        .filter((item) => values.includes(item[props.valueKey]))
        .map((item) => item[props.viewValueKey])
        .join(', ');
}

function viewValueUpdated(newViewValue: string): void {
  if (!newViewValue) {
    value.value = [];
    viewValue.value = '';
    emits('update:modelValue', []);
  }
}

function openChanged(open: boolean): void {
  if (!open) {
    value.value = [...(props.modelValue || [])];
  }
}

function onCheckAll(): void {
  if (isSelectedAll.value) {
    value.value = [];
    return;
  }

  value.value = [...(props.items || []).map((item) => item[props.valueKey])];
}

watch(
  () => searchValue.value,
  (newValue) => {
    if (newValue) {
      filteredItems.value = props.items?.filter((item) =>
        item[props.viewValueKey]?.toLowerCase().includes(newValue.toLowerCase()),
      );
    } else {
      filteredItems.value = props.items;
    }
  },
);

watch(
  () => isOpened.value,
  (newValue) => {
    if (!newValue) {
      contentVisible.value = false;
      searchValue.value = '';
      setTimeout(() => {
        contentVisible.value = true;
      });
    }
  },
);

watch(
  () => props.modelValue,
  (newValue) => {
    if (JSON.stringify(newValue) === JSON.stringify(value.value)) {
      return;
    }

    value.value = newValue || [];
    setViewValue(value.value);
  },
);
</script>

<template>
  <Filter
    v-model:opened="isOpened"
    :label="label"
    :parent-ref="parentRef"
    :icon="IconPathEnum.NavigationArrowDown20PxSvg"
    :view-value="viewValue"
    :update-confirm-callback="updateConfirmCallback"
    :disabled="disabled"
    @update:view-value="viewValueUpdated($event)"
    @update:opened="openChanged($event)"
  >
    <div
      v-if="contentVisible"
      class="d-flex flex-column text-nowrap"
      :style="{ 'width': width + 'px' }"
    >
      <div class="mm-body-medium-m mb16">{{ title }}</div>
      <TextField
        v-if="search"
        v-model="searchValue"
        :placeholder="searchPlaceHolder"
        :icon-path="IconPathEnum.NavigationSearchSvg"
        size="small"
        class="text-field-no-padding-40 mb20"
      />
      <Checkbox
        v-if="hasSelectAllButton"
        :model-value="isSelectedAll"
        class="select-all-check"
        @update:model-value="onCheckAll"
      >
        Выбрать все
      </Checkbox>
      <div
        class="checkboxes-container d-flex flex-column"
        :style="{'height': maxHeight + 'px'}"
      >
        <PerfectScrollbar
          watch-options
          :options="{ wheelPropagation: false, scrollYMarginOffset: 5 }"
        >
          <Checkbox
            v-for="item in filteredItems"
            :key="item[valueKey]"
            :model-value="checked(item)"
            class="checkbox-margin"
            @update:model-value="onValueUpdate(item, $event)"
          >
            <div class="checkbox-margin--content">
              <span>
                {{ item[viewValueKey] }}
              </span>
              <span>
                {{ item[rightLabelKey] || '' }}
              </span>
            </div>
          </Checkbox>
        </PerfectScrollbar>
      </div>

      <button
        class="btn btn-primary d-flex justify-content-center mt24"
        @click="confirm"
      >
        {{ confirmButtonText }}
      </button>
    </div>
  </Filter>
</template>

<style scoped lang="scss">
@import '@styles/base/common/variables';

.checkboxes-container {

  &-grid {
    display: grid;
    grid-template-columns: auto;
    grid-auto-flow: column;
    gap: 12px 40px;
  }
}

.checkbox-margin {
  &:not(:last-of-type) {
    margin-bottom: 12px;
  }

  flex-wrap: nowrap;

  &--content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span:not(:first-child):last-child {
      color: $light-green;
    }
  }
}

.checkbox-width {
  width: fit-content;
  flex-wrap: nowrap;
}

.select-all-check {
  padding-bottom: 12px;
  border-bottom: 1px solid $gray-200;
  margin-bottom: 12px;
}
</style>
