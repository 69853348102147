<template>
  <div
    :id="`${model.id}`"
    class="tree-node"
    :class="{
      'is-expanded': model.opened,
      [model.class]: model.class,
      'tree-node--outlined': outlined,
      'tree-node--last': outlined && !model.children?.length,
      'tree-node-hidden': model.hidden
    }"
  >
    <div
      class="arrow-line d-flex"
      :class="{
        'arrow-line-root': level === 0,
        'arrow-line-no-children': !model.children?.length,
        'arrow-line-last': model.children?.length
      }"
    >
      <template v-if="!outlined">
        <div
          v-for="(_, index) in level"
          :key="index"
          class="tree-node-level"
        />
      </template>
      <div class="vertical-line"></div>
    </div>
    <div
      class="tree-node-label position-relative"
      @click="onClickNode"
    >
      <template v-if="!outlined">
        <div
          v-for="(_, index) in level"
          :key="index"
          class="tree-node-level"
        />
      </template>
      <SvgIcon
        v-if="model.children?.length"
        class="tree-node-label-icon"
        :class="{ ml2: level !== 0 }"
        :src="IconPathEnum.NavigationArrowRight20PxSvg"
      />

      <div
        v-else-if="!outlined"
        class="tree-node-level"
      />

      <CheckBox
        v-if="checkable"
        :model-value="model.selected"
        class="tree-node-label-checkbox"
        :class="{ ml2: !model.children?.length }"
        :disabled="model.disabled"
        :partially="isPartiallyChildrenSelected && !model.selected"
        @update:model-value="onChangeCheckbox"
        @click.stop
      />

      <RadioButton
        v-if="selectable && !isChildren"
        class="tree-node-label-radio"
        :model-value="{ value: true }"
        :value="{ value: model.selected }"
        :disabled="model.disabled"
        @update:model-value="onChangeRadio"
        @click.stop
      />
      <span
        class="tree-node-label-text"
        :class="{ 'tree-node-label-text__disabled': model.disabled }"
        :style="{ 'border-bottom': prevItem === model.name ? '1px solid black' : '' }"
      >
        {{ model.name || '' }}
      </span>
      <span class="right-label mm-body-regular-s">
        {{
          rightLabelKey ? model[rightLabelKey] : ''
        }}
      </span>
    </div>
    <transition name="tree-node-children-transition">
      <div
        v-if="model.opened && model.children?.length"
        class="tree-node-children"
      >
        <template v-if="!outlined">
          <div
            v-for="(_, index) in level"
            :key="index"
            class="tree-node-level"
          />
        </template>
        <TreeViewNode
          v-for="children in model.children"
          :key="children.id"
          :item="children"
          :prev-item="prevItem"
          :level="level + 1"
          :checkable="checkable"
          :selectable="selectable"
          :outlined="outlined"
          :right-label-key="rightLabelKey"
          @linked-item="$emit('linkedItem', $event)"
          @selected-item="$emit('selectedItem', $event)"
        />
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import type { ITreeViewNodeProps, ITreeViewItem } from '../../models/treeView.model';
import SvgIcon from '../SvgIcon.vue';
import CheckBox from '../Checkbox.vue';
import RadioButton from '../RadioButton.vue';
import { useTreeView } from '../../composables/useTreeView';
import { onMounted, ref, watch } from 'vue';
import { IconPathEnum } from '@/ui-kit/enums/iconPath.enum';

const props = withDefaults(defineProps<ITreeViewNodeProps>(), {
  level: 0,
  checkable: false,
  selectable: false,
  defaultExpandAll: false,
  disabled: false,
  outlined: false,
  prevItem: null,
});

const emit = defineEmits<{
  (e: 'selectedItem', item: ITreeViewItem);
  (e: 'linkedItem', item: ITreeViewItem);
  (e: 'update:item', item: ITreeViewItem);
  (e: 'expandChanged', value: void);
}>();

const model = ref(props.item);

const {
  isNotSelectedChild,
  isPartiallyChildrenSelected,
  changeChildrenSelected,
  isSelectedChild,
  isChildren,
} = useTreeView(model, props.checkable);

function onClickNode(): void {
  if (model.value.disabled) {
    return;
  }

  if (!model.value.children?.length) {
    if (model.value.link) {
      emit('linkedItem', model.value);
      return;
    }
    if (props.selectable) {
      return onChangeRadio();
    }

    if (props.checkable) {
      return onChangeCheckbox(!model.value.selected);
    }
  }
  model.value.opened = !model.value.opened;
  emit('expandChanged');
}

function onChangeRadio(): void {
  model.value.selected = true;
  emit('selectedItem', model.value);
}

function onChangeCheckbox(selected: boolean): void {
  if (isPartiallyChildrenSelected.value && !model.value.selected) {
    selected = true;
  }

  model.value.selected = selected;
  changeChildrenSelected(selected);
  emit('update:item', model.value);
}

function init(): void {
  if (model.value.selected || isSelectedChild.value) {
    model.value.opened = true;
  }

  if (props.checkable && isChildren.value && !isNotSelectedChild.value) {
    model.value.selected = true;
  }
}

watch(
  () => props.item,
  (newItem) => {
    model.value = newItem;
  },
  { deep: true },
);

watch(
  () => isNotSelectedChild.value,
  (newIsNotSelectedChild) => (model.value.selected = !newIsNotSelectedChild),
);

onMounted(() => {
  init();
});
</script>

<style lang="scss" scoped>
@import '@styles/base/common/variables';

.tree-node {
  display: flex;
  flex-direction: column;
  position: relative;

  &:last-child {
    .arrow-line-last {
      height: calc(100% - 32px);
    }
  }

  &-label {
    border-radius: 8px;
    padding: 10px 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    transition: background-color 0.2s;
    z-index: 1;

    &-icon {
      width: 20px;
      height: 20px;
      transition: transform 0.2s;
      margin-right: 14px;

      :deep(path) {
        fill: $tree-node-icon-c;
        transition: fill 0.3s;
      }
    }

    &:hover {
      background-color: $tree-node-bg;
      cursor: pointer;
    }

    &:hover > &-icon {
      :deep(path) {
        fill: $tree-node-icon-hover-c;
      }
    }

    &-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $tree-node-label-text-c;

      &__disabled {
        color: $gray-300;
      }
    }

    &-checkbox,
    &-radio {
      z-index: 5;
    }

    :deep(.radio__input) {
      > span {
        border: 1px solid $gray-200;
      }
    }
  }

  &--last {
    .tree-node {
      &-label {
        margin-left: 35px;
      }
    }
  }

  &--outlined {
    .tree-node {
      &-label {
        padding: 8px;
      }

      &-children {
        margin-left: 16px;
        padding-left: 12px;
        border-left: 1px solid $dark-gray;
      }
    }
  }

  .tree-node-label-icon {
    min-width: 20px;
    min-height: 20px;
  }

  &.is-expanded > &-label {
    & > .tree-node-label-icon {
      transform: rotate(90deg);
      min-width: 20px;
      min-height: 20px;
    }
  }

  &-children {
    &-transition {
      &-enter-active,
      &-leave-active {
        transition: opacity 0.2s ease;
      }

      &-enter-from,
      &-leave-to {
        opacity: 0;
      }
    }
  }

  & > * > &-level {
    width: 34px;
  }
}

.tree-node-hidden {
  display: none;
}

.right-label {
  color: $light-green;
  position: absolute;
  right: 12px;
  top: 12px;
  bottom: 0;
  margin: auto;
}

.arrow-line {
  height: calc(100% - 22px);
  position: absolute;
  top: 33px;
  left: 23px;

  &-no-children {
    top: 0;
    height: 100%;
  }

  &-root {
    left: 21px;
  }

  .vertical-line {
    width: 1px;
    background: $dark-gray;
    height: 100%;
  }
}
</style>
