<template>
  <Popper
    arrow
    v-bind="props"
    @close:popper="$emit('close:popper')"
    @open:popper="$emit('open:popper')"
  >
    <slot />
    <template #content="slotProps">
      <slot
        name="content"
        v-bind="slotProps"
      />
    </template>
  </Popper>
</template>
<script lang="ts" setup>
import type { IPopperProps } from '../models/popper.model';
import { PopperPlacementEnum } from '../enums/popperPlacement.enum';
import Popper from 'vue3-popper';

const props = withDefaults(defineProps<IPopperProps>(), {
  class: 'mm-tooltip mm-tooltip--base',
  placement: 'top' as PopperPlacementEnum,
  offsetDistance: '0',
  show: undefined,
});

defineEmits<{
  (e: 'close:popper');
  (e: 'open:popper');
}>();
</script>

<style scoped lang="scss">
.mm-tooltip {
  & > :deep(div) {
    > * {
      cursor: pointer;
    }
  }
}
</style>
