<script setup lang="ts">
import { someFieldIsInvalidUtil } from '@/common/utils/someFieldIsInvalid.util';
import AuthAlert from '@/views/auth/components/AuthAlert.vue';
import PasswordField from '@/ui-kit/components/PasswordField.vue';
import { IconPathEnum } from '@/ui-kit/enums/iconPath.enum';
import { useField, useForm } from 'vee-validate';
import { Validators } from '@/ui-kit/utils/validators.util';
import SvgIcon from '@/ui-kit/components/SvgIcon.vue';
import PasswordRules from '@/views/auth/components/PasswordRules.vue';

defineProps<{
  isLoading: boolean;
}>();

const emit = defineEmits<{
  (e: 'proceed', value: string): void;
}>();

const checkInputDataText = 'Проверьте введенные данные';

const {values, errors} = useForm({
  validationSchema: {
    password: [
      Validators.required(checkInputDataText),
      Validators.password('Пароль не соответствует требованиям безопасности'),
      Validators.maxLength(50, 'Значение должно быть не более 50 символов'),
    ],
    confirmPassword: [
      Validators.compare('password', 'Значение не совпадает с введенным ранее'),
    ],
  },
});

const fields = {
  password: useField<string>('password'),
  confirmPassword: useField<string>('confirmPassword'),
};

function proceed(): void {
  if (someFieldIsInvalidUtil(errors.value)) {
    Object.values(fields).forEach((field) => field.setTouched(true));
  } else {
    emit('proceed', values.password);
  }
}

</script>

<template>
  <div class="d-flex flex-column align-items-center mt100">
    <div class="d-flex flex-column align-items-center position-relative">
      <AuthAlert>
        <div class="d-flex flex-column">
          <div class="mm-headline-3 mm-color-black mm-font-500 mb32">Введите новый пароль</div>
          <PasswordField
            v-model="fields.password.value.value"
            :validation-field="fields.password"
            :disabled="isLoading"
            label="Новый пароль"
          />
          <PasswordField
            v-model="fields.confirmPassword.value.value"
            :validation-field="fields.confirmPassword"
            :disabled="isLoading"
            label="Подтвердить пароль"
          />

          <div class="position-relative mt22">
            <button
              class="btn btn-primary button56 w-100"
              :disabled="someFieldIsInvalidUtil(errors) || isLoading"
              @click="proceed"
            >
              <span v-if="!isLoading">Подтвердить</span>
            </button>
            <SvgIcon
              v-if="isLoading"
              class="uploading-icon"
              :src="IconPathEnum.IndicatorsProgressSvg"
            />
          </div>
        </div>
      </AuthAlert>
      <PasswordRules />
    </div>
  </div>
</template>

<style scoped lang="scss">
:deep(.password-rules) {
  top: 96px;
}
</style>
