<script setup lang="ts">

import AuthAlert from '@/views/auth/components/AuthAlert.vue';
import Select from '@/ui-kit/components/Select.vue';
import TextField from '@/ui-kit/components/TextField.vue';
import type { IDictionary } from '@/common/models/dictionary';
import type { IResidentOptions } from '@/views/auth/pages/registration/models/residentOptions.model';
import { useField, useForm } from 'vee-validate';
import { InputMasksEnum } from '@/ui-kit/enums/inputMasks.enum';
import { computed, watch } from 'vue';
import { Validators } from '@/ui-kit/utils/validators.util';
import type { ISupplierRegister } from '@/views/auth/pages/registration/models/supplierRegister.model';
import { someFieldIsInvalidUtil } from '@/common/utils/someFieldIsInvalid.util';
import NoAccount from '@/views/auth/pages/registration/components/AccountAlert.vue';
import { IconPathEnum } from '@/ui-kit/enums/iconPath.enum';
import SvgIcon from '@/ui-kit/components/SvgIcon.vue';

const props = defineProps<{
  legalForms: Array<IDictionary>;
  supplier?: ISupplierRegister;
  isLoading?: boolean;
}>();

const emit = defineEmits<{
  (e: 'proceed', value: ISupplierRegister): void;
  (e: 'loadMoreLegalForms'): void;
}>();

const selectResidentStatusOptions: Array<IResidentOptions> = [
  {
    label: 'Да',
    value: true,
  },
  {
    label: 'Нет',
    value: false,
  },
];

const juridicalInnMaskOptions: IMask.AnyMaskedOptions = {
  mask: InputMasksEnum.RegistrationJuridicalInn,
};

const individualInnMaskOptions: IMask.AnyMaskedOptions = {
  mask: InputMasksEnum.RegistrationInn,
};

const checkInputDataText = 'Проверьте введенные данные';

const juridicalInnValidators = [
  Validators.required(checkInputDataText),
  Validators.maxLength(10, 'Значение должно содержать 10 символов'),
  Validators.minLength(10, 'Значение должно содержать 10 символов'),
];

const individualInnValidators = [
  Validators.required(checkInputDataText),
  Validators.maxLength(12, 'Значение должно содержать 12 символов'),
  Validators.minLength(12, 'Значение должно содержать 12 символов'),
];

const registrationNumberValidators = [
  Validators.required(checkInputDataText),
  Validators.maxLength(30, 'Значение должно быть не более 30 символов'),
];

const {values, errors, schema} = useForm<ISupplierRegister>({
  validationSchema: {
    legalFormId: [Validators.required(checkInputDataText)],
    fullName: [
      Validators.required(checkInputDataText),
      Validators.maxLength(300, 'Значение должно быть не более 300 символов'),
    ],
    isResident: [Validators.required(checkInputDataText)],
    inn: [],
    registrationNumber: [],
  },
  initialValues: {
    legalFormId: props.supplier?.legalFormId,
    fullName: props.supplier?.fullName,
    isResident: props.supplier?.isResident,
    inn: props.supplier?.inn,
    registrationNumber: props.supplier?.registrationNumber,
  },
});

const fields = {
  legalFormId: useField<number>('legalFormId'),
  fullName: useField<string>('fullName'),
  isResident: useField<boolean>('isResident'),
  inn: useField<string>('inn'),
  registrationNumber: useField<string>('registrationNumber'),
}

const isJuridical = computed(() => {
  const found = props.legalForms.find((item) => item.id === fields.legalFormId.value.value);
  return !!found && found.isJuridical;
});

function proceed(): void {
  if (someFieldIsInvalidUtil(errors.value)) {
    Object.values(fields).forEach((field) => field.setTouched(true));
  } else {
    if (values.isResident) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const {registrationNumber, ...result} = values;
      emit('proceed', result);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const {inn, ...result} = values;
      emit('proceed', result);
    }
  }
}

watch(() => isJuridical.value, (current, previous) => {
  if (current !== undefined && previous !== undefined && current !== previous) {
    fields.inn.value.value = '';
  }
});

watch(() => [
  fields.legalFormId.value.value,
  fields.isResident.value.value,
  fields.inn.value.value,
  fields.registrationNumber.value.value,
], ([legalForm, isResident]) => {
  if (isResident && legalForm) {
    if (isJuridical.value) {
      schema.inn = juridicalInnValidators;
    } else {
      schema.inn = individualInnValidators;
    }
    schema.registrationNumber = [];
  } else {
    schema.inn = [];
    schema.registrationNumber = registrationNumberValidators;
  }
});


</script>

<template>
  <div class="d-flex flex-column align-items-center mt100">
    <AuthAlert>
      <div class="d-flex flex-column">
        <div class="mm-headline-3 mm-font-500 mb32">Регистрация контрагента</div>
        <Select
          v-model="fields.legalFormId.value.value"
          label="Организационно-правовая форма"
          labelField="name"
          valueField="id"
          :validation-field="fields.legalFormId"
          :options="legalForms"
          :disabled="isLoading"
          :searchable="false"
          is-only-value-field
          @load-more="emit('loadMoreLegalForms')"
        />
        <TextField
          v-model="fields.fullName.value.value"
          icon-path=""
          :clearable="false"
          :disabled="isLoading"
          :label="isJuridical ? 'Полное наименование (без организационно-правовой формы)' : 'ФИО'"
          :validation-field="fields.fullName"
        />
        <Select
          v-model="fields.isResident.value.value"
          label="Резидент РФ"
          :options="selectResidentStatusOptions"
          :validation-field="fields.isResident"
          :disabled="isLoading"
          :searchable="false"
          is-only-value-field
        />
        <TextField
          v-if="fields.isResident.value.value && fields.legalFormId.value.value"
          v-model="fields.inn.value.value"
          icon-path=""
          label="ИНН"
          :clearable="false"
          :disabled="isLoading"
          :validation-field="fields.inn"
          :mask-options="isJuridical ? juridicalInnMaskOptions : individualInnMaskOptions"
        />
        <TextField
          v-if="!fields.isResident.value.value && fields.isResident.value.value !== undefined"
          v-model="fields.registrationNumber.value.value"
          icon-path=""
          :clearable="false"
          :disabled="isLoading"
          label="№ регистрации в стране регистрации"
          :validation-field="fields.registrationNumber"
        />
        <div class="position-relative mt22">
          <button
            class="btn btn-primary button56 w-100"
            :disabled="someFieldIsInvalidUtil(errors) || isLoading"
            @click="proceed"
          >
            <span v-if="!isLoading">Далее</span>
          </button>
          <SvgIcon
            v-if="isLoading"
            class="uploading-icon"
            :src="IconPathEnum.IndicatorsProgressSvg"
          />
        </div>
      </div>
    </AuthAlert>
    <NoAccount
      class="mt40"
      title="Уже есть аккаунт?"
      to="login"
      actionText="Пройдите авторизацию"
    />
  </div>
</template>

<style scoped lang="scss">

</style>
