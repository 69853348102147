import type { AxiosStatic } from 'axios';
import type { IUserProfileData } from '@/views/srm/account-settings/models/userProfileData.model';
import type { IPatchUserProfileReqBody } from '@/views/srm/account-settings/models/userProfileUpdate.model';
import type { IUpdatePasswordReqBody } from '@/views/srm/account-settings/models/userPasswordUpdate.model';
import { getCurrentInstance } from 'vue';

export function useUserApi() {
  const baseApiURL = `${import.meta.env.VITE_API_URL}/users/profile`;
  const baseCurrentApiUrl = `${import.meta.env.VITE_API_URL}/users/current`;

  const axios: AxiosStatic = getCurrentInstance().appContext.config.globalProperties.$axios;

  return {
    getUserProfile: async (): Promise<IUserProfileData> => {
      const res = await axios.get(`${baseApiURL}/`)
      return res.data
    },
    updateUserProfile: async (reqBody: IPatchUserProfileReqBody): Promise<IUserProfileData> => {
      const res = await axios.patch(`${baseApiURL}/`, reqBody)
      return res.data
    },
    changeUserPassword: async (reqBody: IUpdatePasswordReqBody): Promise<void> => {
      await axios.patch(`${baseApiURL}/password`, reqBody)
    },
    getCurrent: async (): Promise<IUserProfileData> => {
      const res = await axios.get<IUserProfileData>(`${baseCurrentApiUrl}/`)
      return res.data;
    },
  }
}
