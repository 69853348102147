<script setup lang="ts">
import PageTitle from '@/common/components/page/PageTitle.vue';
import { computed, inject, onBeforeMount, provide, type Ref, ref, watch } from 'vue';
import type { ITab } from '@/ui-kit/models/tabs.model';
import PageTabs from '@/common/components/page/PageTabs.vue';
import PageHead from '@/common/components/page/PageHead.vue';
import type { IAxiosParams } from '@/common/models/filters/axios-params';
import FiltersBar from '@/common/components/filters/FiltersBar.vue';
import type { FilterComponentParams, IFilterUpdateDataValue } from '@/common/models/filters/filter-model';
import { FilterUtils } from '@/common/utils/filterUtils';
import type { IPagination } from '@/common/models/pagination';
import Loader from '@/ui-kit/utils/loaderHelper.util';
import { TABLE_DEFAULT_PAGE, TABLE_DEFAULT_PAGE_SIZE } from '@/common/constants/tableDefaults.const';
import Notificator from '@/ui-kit/services/notificator.service';
import ClipLoader from '@/ui-kit/components/ClipLoader.vue';
import EmptyBanner from '@/common/components/banners/EmptyBanner.vue';
import { useRoute, useRouter } from 'vue-router';
import { useUpdate } from '@/ui-kit/composables/useUpdate';
import { useRoledRoutes } from '@/common/composables/useRoledRoutes';
import { PROJECTS_SKIP_FILTERS_MAP } from '@/views/srm/projects/projects/projectsSkipFiltersMap.const';
import { PROJECTS_NEEDED_FILTERS } from '@/views/srm/projects/projects/projectsNeededFilters.const';
import ModalManager from '@/ui-kit/services/modalManager.service';
import { AppTableSelectService } from '@/common/services/appTableSelect.service';
import type { IDefaultParams } from '@/ui-kit/models/table.model';
import { RouteTypeEnum } from '@/common/enums/routeType.enum';
import type { IProjectsFilter } from '@/views/srm/projects/api/models/filters/projectsFilter';
import type { IProjectsQueryParams } from '@/views/srm/projects/api/models/filters/projectsQueryParams';
import { ProjectsOrderByEnum } from '@/views/srm/projects/api/models/enums/projectsOrderBy.enum';
import { useProjectsApi } from '@/views/srm/projects/api/useProjectsApi';
import { ProjectsFiltersEnum } from '@/views/srm/projects/api/models/enums/projectsFilters.enum';
import type { IProjectFilters } from '@/views/srm/projects/api/models/responses/projectFilters.model';
import type { TProjectsRequest } from '@/views/srm/projects/api/models/projectsRequest.model';
import type { ILoadCountersRequest } from '@/views/srm/projects/api/models/filters/loadCountersRequest';
import type { IProjectCounters } from '@/views/srm/projects/api/models/responses/projectCounters.model';
import { ProjectCountersEnum } from '@/views/srm/projects/api/models/enums/projectCounters.enum';
import type { IProjectItem } from '@/views/srm/projects/api/models/responses/projectItem.model';
import { TAB_NAMES } from '@/views/srm/projects/api/models/const/tab-names.const';
import type { IProjectCounter } from '@/views/srm/projects/api/models/responses/projectCounter.model';
import { mapProjectsFilters } from '@/views/srm/projects/utils/mapProjectsFilters.util';
import ProjectsTable from '@/views/srm/projects/ProjectsTable.vue';
import ConfirmModal from '@/common/components/modals/ConfirmModal.vue';
import { PROJECTS_DEFAULT_OPEN_TAB_MAP } from '@/views/srm/projects/api/constants/projectsDefaultOpenTabMap.const';
import { PROJECTS_ROUTE_NAME_MAP } from '@/views/srm/projects/api/constants/projectsRouteNameMap.const';
import { isEnumValue } from '@/common/utils/isEnumValue.util';

defineProps(['title']);

const pageHeadRef = ref(null);
const tabList = ref<Array<ITab>>([]) as Ref<ITab[]>;
const { routeType } = useRoledRoutes();
const filtersParams = ref<IAxiosParams<IProjectsFilter, IProjectsQueryParams>>({
  data: {} as IProjectsFilter,
  query: {
    descending: false,
    orderBy: ProjectsOrderByEnum.offerEnd,
  },
});
const {
  loadFilters, loadProjects, loadCounters,
} = useProjectsApi();

const GET_FILTERS_REQUEST_BY_ROUTE_TYPE_MAP = new Map<RouteTypeEnum, (data: ProjectsFiltersEnum[]) => Promise<IProjectFilters>>([
  [RouteTypeEnum.Supplier, loadFilters],
]);
const GET_PROJECTS_LIST_REQUEST_BY_ROUTE_TYPE_MAP = new Map<RouteTypeEnum, TProjectsRequest>([
  [RouteTypeEnum.Supplier, loadProjects],
]);
const GET_COUNTERS_REQUEST_BY_ROUTE_TYPE_MAP = new Map<RouteTypeEnum, (data?: ILoadCountersRequest) => Promise<IProjectCounters>>([
  [RouteTypeEnum.Supplier, loadCounters],
]);
const filters = ref<Array<FilterComponentParams>>([]);
const isFiltersEmpty = computed(() =>
  FilterUtils.isFiltersEmpty(filtersParams.value?.data, ['search', 'tab']),
);
const skipSearchUpdateOnce = ref<boolean>(false);
const route = useRoute();
const currentTabName = ref<ProjectCountersEnum>(route.params.tabName as ProjectCountersEnum);
const router = useRouter();
const tableData = ref<IPagination<IProjectItem>>();
const isLoading = Loader.getReactiveInstance();
const currentPage = ref<number>(TABLE_DEFAULT_PAGE);
const isFiltersLoading = Loader.getReactiveInstance();
const currentPageSize = ref<number>(TABLE_DEFAULT_PAGE_SIZE);
const isNoProjects = computed<boolean>(
  () => !tableData.value?.total && isFiltersEmpty.value && !filtersParams.value?.data?.search,
);
const emptyText = computed<string>(() => {
  return 'Здесь будут отображаться <br/>' +
      'соответствующие разделу проекты';
});
const { updateVersion, incrementVersion } = useUpdate();
const modalManager = inject<ModalManager>(ModalManager.getServiceName());
const tableSelectService = new AppTableSelectService(tableData.value);

provide('clearFilters', updateVersion);
provide('tableSelectService', tableSelectService);

async function onClearFilters(): Promise<void> {
  incrementVersion();
  filtersParams.value.data = {
    tab: currentTabName.value,
  } as IProjectsFilter;
  filtersParams.value.query = {
    descending: false,
    orderBy: ProjectsOrderByEnum.offerEnd,
  };
  await loadData();
}

async function onUpdateSearch(searchValue: string): Promise<void> {
  if (!searchValue) {
    tableSelectService.deselectAll();
  }
  filtersParams.value.data.search = searchValue;
  currentPage.value = 1;
  if (skipSearchUpdateOnce.value) {
    skipSearchUpdateOnce.value = false;
  } else {
    await loadData();
  }
}

async function onUpdateQuery(newQuery: IProjectsQueryParams): Promise<void> {
  filtersParams.value.query = newQuery;
  await loadData();
}

async function onUpdateData(newData: IFilterUpdateDataValue): Promise<void> {
  if (newData.key === 'projectType') {
    if (newData.data.length === 2) {
      filtersParams.value.data['isOpen'] = null;
    }
    filtersParams.value.data['isOpen'] = !!newData.data.find((item) => item === 1);
  }
  filtersParams.value.data[newData.key] = newData.data;
  await loadData();
}

async function onUpdatePagination(pagination: IDefaultParams): Promise<void> {
  currentPage.value = pagination.page;
  currentPageSize.value = pagination.pageSize;
  await loadData();
}

async function openChangeFiltersConfirmModal(): Promise<unknown> {
  if (!tableSelectService.selectedCount) {
    return true;
  }

  return await modalManager.openAsyncModal(ConfirmModal, {
    attrs: {
      title: 'Уверены, что хотите изменить условия фильтрации?',
      message: `Это сбросит ваш выбор в списке этапов на согласовании. <br />
                Сейчас выбрано этапов: ${tableSelectService.selectedCount || 0}`,
      cancelText: 'Нет, не изменять',
      confirmText: 'Да, изменить',
      confirmButtonClass: 'btn-decline',
    },
  });
}

async function loadTabs(): Promise<boolean> {
  isLoading.activate();
  try {
    const requestFn = GET_COUNTERS_REQUEST_BY_ROUTE_TYPE_MAP.get(routeType.value);

    const counters = await requestFn();

    tabList.value = counters.counts.map((counter: IProjectCounter) => {
      const counterName = counter.name || counter.tab;
      return { label: TAB_NAMES[counterName], value: counterName, unselectable: false, path: counterName, count: counter.count };
    });
    const tabNameFromRoute = isEnumValue<ProjectCountersEnum>(ProjectCountersEnum, route.params.tabName as string)
      ? route.params.tabName
      : null;

    if (!!route.params.tabName && !tabNameFromRoute) {
      await router.push('/not-found');
      return false;
    }

    let tabNameFromRole = PROJECTS_DEFAULT_OPEN_TAB_MAP.get(routeType.value);
    currentTabName.value = (tabNameFromRoute || tabNameFromRole || tabList.value[0].value || ProjectCountersEnum.Active) as ProjectCountersEnum;
    const routeName = PROJECTS_ROUTE_NAME_MAP.get(routeType.value);

    if (!tabNameFromRoute) {
      await router.push({
        name: routeName,
        params: { tabName: currentTabName.value },
      });
    }
    return true;
  } catch (e) {
    console.error(e);
    return false;
  } finally {
    isLoading.deactivate();
  }
}

async function loadProjectsFilters(): Promise<void> {
  if (isFiltersLoading.value) {
    return;
  }

  try {
    isFiltersLoading.activate();
    const apiFunction = GET_FILTERS_REQUEST_BY_ROUTE_TYPE_MAP.get(routeType.value);

    filters.value = mapProjectsFilters(
      await apiFunction(PROJECTS_NEEDED_FILTERS),
      pageHeadRef,
      PROJECTS_SKIP_FILTERS_MAP.get(currentTabName.value),
    );

  } catch (error) {
    console.error(error);
    Notificator.showDetachedNotification('Произошла ошибка при загрузке фильтров списка проектов');
  }
  finally {
    isFiltersLoading.deactivate();
  }
}

async function loadData(loadFilters = false): Promise<void> {
  if (!currentTabName.value) {
    const res = await loadTabs();
    if (!res) {
      return;
    }
  }

  if (isLoading.value) {
    return;
  }

  filtersParams.value.data.tab = currentTabName.value || ProjectCountersEnum.Active;

  try {
    isLoading.activate();

    if (loadFilters) {
      await loadProjectsFilters();
    }

    let isOpenStatus: boolean
    let offerEndValue

    if(filtersParams.value?.data.isOpen) {
      const isOpen = filtersParams.value?.data.isOpen as Array<boolean>;
      if (isOpen.length === 1) {
        isOpenStatus = filtersParams.value.data.isOpen[0]
      } else if (isOpen.length > 1) {
        isOpenStatus = null;
      }
    }

    if(filtersParams.value?.data.offerEnd) {
      offerEndValue = filtersParams.value?.data.offerEnd[0]
    }

    const params = {
      data: {
        ...filtersParams.value?.data,
        isOpen: isOpenStatus,
        offerEnd: offerEndValue,
      },
      query: {
        ...filtersParams.value?.query,
        page: currentPage.value,
        size: currentPageSize.value,
      },
    }

    const requestFn = GET_PROJECTS_LIST_REQUEST_BY_ROUTE_TYPE_MAP.get(routeType.value);

    tableData.value = await requestFn(
      currentTabName.value,
      params,
    );

    tableSelectService.updateTableData(tableData.value, loadFilters);
    currentPage.value = tableData.value?.page;
    currentPageSize.value = tableData.value?.size;
    tableSelectService.updateTableData(tableData.value);
  } catch (error) {
    console.error(error);
    Notificator.showDetachedNotification('Произошла ошибка при загрузке проектов');
  } finally {
    isLoading.deactivate();
  }
}

async function onSearchClear() {
  skipSearchUpdateOnce.value = true;
  filtersParams.value.data.search = null;
  await loadData();
}

watch(() => route.params, async (params, oldParams) => {

  if (oldParams.tabName && !params.tabName) {
    return router.replace({
      path: route.path + '/' + oldParams.tabName,
    })
  }

  if (params.tabName !== currentTabName.value) {
    currentTabName.value = params.tabName as ProjectCountersEnum;
    await onClearFilters();
    currentPage.value = 1;
    currentPageSize.value = 20;
    await loadData(true);
  }
});

onBeforeMount(async () => {
  const res = await loadTabs();

  if (!res) {
    return;
  }

  await loadData(true);
});
</script>

<template>
  <PageHead ref="pageHeadRef">
    <PageTitle
      class="mb20"
      :title="title"
    >
    </PageTitle>

    <PageTabs
      v-if="tabList.length"
      class="mb20"
      :tab-list="tabList"
    />
    <FiltersBar
      class="mb20 projects-filters"
      :class="{'large-search-margin': filters.length < 9}"
      :parent-ref="pageHeadRef"
      :filters="filters as Array<FilterComponentParams>"
      :filter-params="filtersParams"
      :is-filters-empty="isFiltersEmpty"
      :disabled="isLoading.value || isNoProjects"
      :update-confirm-callback="openChangeFiltersConfirmModal"
      search-placeholder="Название или № проекта..."
      @clear-filters="onClearFilters"
      @update:search="onUpdateSearch"
      @update:query="onUpdateQuery"
      @update:data="onUpdateData"
    />
  </PageHead>

  <div class="projects--wrapper">
    <ClipLoader v-if="isLoading.value" />

    <EmptyBanner v-else-if="isNoProjects">
      <template #title>
        У вас пока <br />
        нет проектов
      </template>
      <template #description>
        <span v-html="emptyText"></span>
      </template>
    </EmptyBanner>

    <EmptyBanner v-else-if="!tableData?.total && filtersParams.data.search">
      <template #title>
        К сожалению, <br />
        нет подходящих проектов
      </template>
      <template #description> Убедитесь, что ввели поисковый запрос без ошибок</template>
      <template #button>
        <button
          class="btn btn-primary"
          @click="onSearchClear"
        >
          Сбросить поисковый запрос
        </button>
      </template>
    </EmptyBanner>

    <EmptyBanner v-else-if="!tableData?.total && !FilterUtils.isFiltersEmpty(filtersParams, [])">
      <template #title>
        К сожалению, <br />
        нет подходящих проектов
      </template>
      <template #description>
        Убедитесь, что в значениях фильтров не установлены <br />
        взаимоисключающие значения, или попробуйте смягчить <br />
        требования подбора
      </template>
      <template #button>
        <button
          class="btn btn-primary"
          @click="onClearFilters"
        >
          Очистить все фильтры
        </button>
      </template>
    </EmptyBanner>

    <ProjectsTable
      v-else
      :projects="tableData"
      @update:pagination="onUpdatePagination"
    />
  </div>
</template>

<style scoped lang="scss">
@import '@styles/base/common/variables';

:global(.btn-decline) {
  color: $red;
  background: $light-gray;
  font-weight: 500;

  &:hover {
    background: $light-gray !important;
    color: $red !important;
  }
}

:deep(.menu-opened) {
  top: 39px;
}

.projects--wrapper {
  height: 100%;
  margin-left: 32px;
  margin-right: 32px;

  .mm-clip-loader {
    position: fixed;
  }

  .with-icon {
    padding: 6px 10px 6px 16px;
  }

  .empty-banner {
    max-width: 1520px;
    margin: 0 auto;
  }
}

.projects-filters {
  :deep(.search-container) {
    margin-left: 22px;
  }
}

.large-search-margin {
  :deep(.search-container) {
    margin-left: 65px;
  }
}

</style>
