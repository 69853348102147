import type { AxiosInstance, AxiosStatic } from 'axios';
import { getCurrentInstance } from 'vue';
import type {
  INotificationsSetting,
  INotificationsSettingItem,
} from '@/views/srm/profile/notifications/api/models/notificationsSettings.model';

export function useNotificationsSettingsApi(axiosInstance?: AxiosInstance): INotificationsSettingsApi {
  const baseApiUrl = `${import.meta.env.VITE_API_URL}/notification/settings`;
  const axios: AxiosStatic = axiosInstance || getCurrentInstance().appContext?.config?.globalProperties?.$axios;

  return {
    getNotificationSettings: async (): Promise<Array<INotificationsSetting>> => {
      const res = await axios.get(`${baseApiUrl}/`);
      return res.data;
    },
    updateNotificationSettings: async (settings: Array<Partial<INotificationsSettingItem>>): Promise<void> => {
      const res = await axios.put(`${baseApiUrl}/`, settings);
      return res.data;
    },
  }
}

export interface INotificationsSettingsApi {
  getNotificationSettings: () => Promise<Array<INotificationsSetting>>;
  updateNotificationSettings: (settings: Array<Partial<INotificationsSettingItem>>) => Promise<void>;
}
