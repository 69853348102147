<script setup lang="ts">

import StaticPage from '@/common/components/static/StaticPage.vue';
import { useRouter } from 'vue-router';
import { inject, onBeforeMount, type Ref } from 'vue';
import type { IStatuses } from '@/common/models/status.model';
import { STATUSES_DATA } from '@/common/constants/status.const';

const props = defineProps<{
  imageUrl: string,
  containerWidth: string
}>();

const router = useRouter();
const { clearUpdateStatuses } = inject<Ref<IStatuses>>(STATUSES_DATA);

function navigateToLogin(): void {
  router.push('/auth/login');
}

onBeforeMount(async () => {
  clearUpdateStatuses();
})
</script>

<template>
  <div>
    <StaticPage :image-url="props.imageUrl">
      <div>
        <div
          class="text-wrapper"
          :style="{'width': containerWidth}"
        >
          <p class="main-text">Рабочая сессия<br>была завершена</p>
          <p class="text">
            Для продолжения работы требуется<br>повторить вход в систему
          </p>
          <button
            class="btn btn-primary"
            @click="navigateToLogin"
          >
            Войти
          </button>
        </div>
      </div>
    </StaticPage>
  </div>
</template>

<style scoped lang="scss">
@import '@assets/styles/base/common/_variables.scss';

:global(.auth-wrapper) {
  background: white !important;
}

.not-found-content {
  height: 502px;
  justify-content: center;
  align-items: center;
  margin-top: 120px;

  .text-wrapper {
    margin-left: 135px;

    .main-text {
      font-size: 32px;
      font-weight: 500;
      line-height: 36px;
    }

    .text {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;

    }
  }

}

</style>
