import type { AxiosStatic } from 'axios';
import { getCurrentInstance } from 'vue';
import type {
  INewNotificationsResponse,
  INotificationListItem,
  IProfileNotificationsCounters,
} from '@/common/models/notifications.model';
import type { IPagination } from '@/common/models/pagination';
import type { IStatuses } from '@/common/models/status.model';

export function useNotificationsApi(): INotificationsApi {
  const baseApiUrl = `${import.meta.env.VITE_API_URL}/notifications`;
  const statusApiUrl = `${import.meta.env.VITE_API_URL}/status/`;

  const axios: AxiosStatic = getCurrentInstance().appContext?.config?.globalProperties?.$axios;

  return {
    getNotificationsCount: async (): Promise<IProfileNotificationsCounters> => {
      const res = await axios.get(`${baseApiUrl}/count/`, {
        headers: {
          'Ignore-Refresh-Token': 'true',
        },
      });
      return res.data;
    },
    getNotificationsListByRoleId: async (
      roleId: number,
      page: number,
      size: number,
    ): Promise<IPagination<INotificationListItem>> => {
      const res = await axios.get(`${baseApiUrl}/list/`, {
        params: {
          roleId,
          page,
          size,
        },
      });
      return res.data;
    },
    readNotification: async (notificationId: number): Promise<void> => {
      const res = await axios.post(`${baseApiUrl}/read/`, {
        notificationId,
      });
      return res.data;
    },
    readAllNotifications: async (roleId: number): Promise<void> => {
      const res = await axios.post(`${baseApiUrl}/readall/?roleId=${roleId}`);
      return res.data;
    },
    getNewNotifications: async (): Promise<INewNotificationsResponse> => {
      const res = await axios.get(`${baseApiUrl}/new/`, {
        headers: {
          'Ignore-Refresh-Token': 'true',
        },
      });
      return res.data;
    },
    setNotificationAsShown: async (notificationId: number): Promise<void> => {
      const res = await axios.post(`${baseApiUrl}/show/`, {
        notificationId,
      });
      return res.data;
    },
    getStatuses: async (
      page: number,
      size: number,
      includeCounters?: boolean,
    ): Promise<IStatuses> => {
      const res = await axios.get(`${statusApiUrl}`, {
        params: {
          page,
          size,
          includeCounters,
        },
      });
      return res.data;
    },
  }
}

export interface INotificationsApi {
  getNotificationsCount: () => Promise<IProfileNotificationsCounters>;
  getNotificationsListByRoleId: (
    roleId: number,
    page: number,
    size: number,
  ) => Promise<IPagination<INotificationListItem>>;
  readNotification: (id: number) => Promise<void>;
  readAllNotifications: (roleId: number) => Promise<void>;
  getNewNotifications: () => Promise<INewNotificationsResponse>;
  setNotificationAsShown: (notificationId: number) => Promise<void>;
  getStatuses: (page: number, size: number, includeCounters?: boolean,) => Promise<IStatuses>;
}
