<template>
  <BaseVueModal
    title="Политика обработки персональных данных"
    class="confirm-pd-modal"
    disable-animation
    @cancel="onCancel"
  >
    <div class="cookie-warning-policy-modal">
      <PerfectScrollbar>
        <PersonalDataPolicy />
      </PerfectScrollbar>
      <div class="cookie-warning-policy-modal-footer">
        <button
          class="btn btn-primary"
          @click="onConfirm"
        >
          Понятно
        </button>
      </div>
    </div>
  </BaseVueModal>
</template>

<script lang="ts" setup>
import BaseVueModal from '@/ui-kit/components/modal/BaseVueModal.vue';
import PersonalDataPolicy from '@/common/components/PersonalDataPolicy.vue';

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'confirm', value: true): void;
}>();

function onConfirm() {
  emit('confirm', true)
}

function onCancel() {
  emit('cancel')
}

</script>

<style lang="scss" scoped>
:global(.confirm-pd-modal .vfm__content) {
  width: 836px !important;
}

.ps {
  height: 500px;
}

.cookie-warning-policy-modal {
  &-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &-footer {
    margin-top: 40px;

    .btn {
      height: 56px;
      width: 100%;
      display: flex;
      justify-content: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }
}
</style>
