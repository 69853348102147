/**
 * Icons
 */
import ActionUpdateSvg from '@/assets/icons/action/update.svg';
import ActionUpdateAccentSvg from '@/assets/icons/action/update-accent.svg';
import ActionAttachSvg from '@/assets/icons/action/attach.svg';
import ActionCartMiniSvg from '@/assets/icons/action/cart-mini.svg';
import ActionCartSvg from '@/assets/icons/action/cart.svg';
import ActionCatalog from '@/assets/icons/action/catalogs.svg';
import ActionCloseSvg from '@/assets/icons/action/close.svg';
import ActionComment24PxSvg from '@/assets/icons/action/comment-24px.svg';
import ActionCommentReview24PxSvg from '@/assets/icons/action/comment-review-24px.svg';
import ActionCommentReviewSvg from '@/assets/icons/action/comment-review.svg';
import ActionCommentSvg from '@/assets/icons/action/comment.svg';
import ActionComment20Svg from '@/assets/icons/action/comment-20px.svg';
import ActionCompanySvg from '@/assets/icons/action/company.svg';
import ActionCompareSvg from '@/assets/icons/action/compare.svg';
import ActionCopySvg from '@/assets/icons/action/copy.svg';
import ActionDateSvg from '@/assets/icons/action/date.svg';
import ActionDeliverySvg from '@/assets/icons/action/delivery.svg';
import ActionDownloadSvg from '@/assets/icons/action/download.svg';
import ActionEyeSvg from '@/assets/icons/action/eye.svg';
import ActionFavouritesSvg from '@/assets/icons/action/favourites.svg';
import ActionFileErrorSvg from '@/assets/icons/action/file-error.svg';
import ActionGalleryViewSvg from '@/assets/icons/action/gallery-view.svg';
import ActionFileChecked from '@/assets/icons/action/file-checked.svg';
import ActionFileChecking from '@/assets/icons/action/file-checking.svg'
import ActionFileCheckError from '@/assets/icons/action/file-check-error.svg'
import ActionDocumentChecked from '@/assets/icons/action/document-checked.svg';
import ActionDocumentChecking from '@/assets/icons/action/document-checking.svg'
import ActionDocumentCheckError from '@/assets/icons/action/document-check-error.svg'
import ActionDocumentUploading from '@assets//icons/action/document-uploading.svg';
import ActionDocumentErrorSvg from '@/assets/icons/action/document-error.svg';
import ActionGluingSvg from '@/assets/icons/action/gluing.svg';
import ActionIconMinusDarkSvg from '@/assets/icons/action/icon-minus-dark.svg';
import ActionIconMinusSvg from '@/assets/icons/action/icon-minus.svg';
import ActionIconPlusDarkSvg from '@/assets/icons/action/icon-plus-dark.svg';
import ActionIconPlusSvg from '@/assets/icons/action/icon-plus.svg';
import ActionInfoSvg from '@/assets/icons/action/info.svg';
import ActionLimitsSvg from '@/assets/icons/action/limits.svg';
import ActionLinkSvg from '@/assets/icons/action/link.svg';
import ActionListingViewMiniSvg from '@/assets/icons/action/listing-view-mini.svg';
import ActionListingViewSvg from '@/assets/icons/action/listing-view.svg';
import ActionMinusSvg from '@/assets/icons/action/minus.svg';
import ActionModerationSvg from '@/assets/icons/action/moderation.svg';
import ActionMoreDotsSvg from '@/assets/icons/action/more-dots.svg';
import ActionOrderSvg from '@/assets/icons/action/order.svg';
import ActionPauseSvg from '@/assets/icons/action/pause.svg';
import ActionPdfPerforatedSvg from '@/assets/icons/action/pdf-perforated.svg';
import ActionPdfSvg from '@/assets/icons/action/pdf.svg';
import ActionPenEditSvg from '@/assets/icons/action/pen-edit.svg';
import ActionPlusSvg from '@/assets/icons/action/plus.svg';
import ActionPriceRangeSvg from '@/assets/icons/action/price-range.svg';
import ActionPrintSvg from '@/assets/icons/action/print.svg';
import ActionRangeSvg from '@/assets/icons/action/range.svg';
import ActionRepeatSvg from '@/assets/icons/action/repeat.svg';
import ActionReportSvg from '@/assets/icons/action/reports.svg';
import ActionReviewSvg from '@/assets/icons/action/review.svg';
import ActionSettingsSvg from '@/assets/icons/action/settings.svg';
import ActionSpinnerSvg from '@/assets/icons/action/spinner.svg';
import ActionTaskListSvg from '@/assets/icons/action/task-list.svg';
import ActionQuestionSvg from '@/assets/icons/action/question.svg';
import ActionThumbsDownSvg from '@/assets/icons/action/thumbs-down.svg';
import ActionThumbsUpSvg from '@/assets/icons/action/thumbs-up.svg';
import ActionTrashSvg from '@/assets/icons/action/trash.svg';
import ActionTrashTransparentSvg from '@/assets/icons/action/trash-transparent.svg';
import ActionTransferSvg from '@/assets/icons/action/transfer.svg';
import ActionUploadProcessingSvg from '@/assets/icons/action/upload-processing.svg';
import ActionUploadSvg from '@/assets/icons/action/upload.svg';
import ActionZoomInSvg from '@/assets/icons/action/zoom-in.svg';
import ActionVideoWhiteSvg from '@/assets/icons/action/video-icon-white.svg';
import ActionVideoGradientSvg from '@/assets/icons/action/video-icon-gradient.svg';
import ActionNotificationSvg from '@/assets/icons/action/notification.svg';
import IndicatorsBestPriceSvg from '@/assets/icons/indicators/best-price.svg';
import IndicatorsBulletDotSvg from '@/assets/icons/indicators/bullet-dot.svg';
import IndicatorsBulletNumberSvg from '@/assets/icons/indicators/bullet-number.svg';
import IndicatorsCartItemsSvg from '@/assets/icons/indicators/cart-items.svg';
import IndicatorsCheckmarkRoundErrorSvg from '@/assets/icons/indicators/checkmark-round-error.svg';
import IndicatorsCheckmarkRoundFileSvg from '@/assets/icons/indicators/checkmark-round-file.svg';
import IndicatorsCheckmarkRoundWatchSvg from '@/assets/icons/indicators/checkmark-round-watch.svg';
import IndicatorsCheckmarkRoundSvg from '@/assets/icons/indicators/checkmark-round.svg';
import IndicatorsCheckmarkRoundGreenSvg from '@/assets/icons/indicators/checkmark-round-green.svg';
import IndicatorsCheckmarkWarnSvg from '@/assets/icons/indicators/checkmark-warn.svg';
import IndicatorsCheckmarkRoundDarkSvg from '@/assets/icons/indicators/checkmark-round-dark.svg';
import IndicatorsCheckmarkSimpleSvg from '@/assets/icons/indicators/checkmark-simple.svg';
import IndicatorsCheckmarkSimpleGreySvg from '@/assets/icons/indicators/checkmark-simple-grey.svg';
import IndicatorsCheckmarkSvg from '@/assets/icons/indicators/checkmark.svg';
import IndicatorsFastShippingSvg from '@/assets/icons/indicators/fast-shipping.svg';
import IndicatorsInfoDarkSvg from '@/assets/icons/indicators/info-dark.svg';
import IndicatorsInfoOutlineSvg from '@/assets/icons/indicators/info-outline.svg';
import IndicatorsProgressSvg from '@/assets/icons/indicators/progress.svg';
import IndicatorsUploadingSvg from '@/assets/icons/indicators/uploading.svg';
import ActionFileUploading from '@assets//icons/action/file-uploading.svg';
import IndicatorsProgressDarkSvg from '@/assets/icons/indicators/progress-dark.svg';
import IndicatorsProgressLightSvg from '@/assets/icons/indicators/progress-light.svg';
import IndicatorsStarSvg from '@/assets/icons/indicators/star.svg';
import IndicatorsStepCloseSvg from '@/assets/icons/indicators/step-close.svg';
import IndicatorsWarningSvg from '@/assets/icons/indicators/warning.svg';
import NavigationArrowDown20PxSvg from '@/assets/icons/navigation/arrow-down-20px.svg';
import NavigationArrowDownSmallSvg from '@/assets/icons/navigation/arrow-down-small.svg';
import NavigationArrowDownSvg from '@/assets/icons/navigation/arrow-down.svg';
import NavigationQualificationSvg from '@/assets/icons/navigation/qualification-fill.svg';
import NavigationArrowLeft20PxSvg from '@/assets/icons/navigation/arrow-left-20px.svg';
import NavigationArrowLeftSmallSvg from '@/assets/icons/navigation/arrow-left-small.svg';
import NavigationArrowLeftSvg from '@/assets/icons/navigation/arrow-left.svg';
import NavigationArrowRight20PxSvg from '@/assets/icons/navigation/arrow-right-20px.svg';
import NavigationArrowRightSmallSvg from '@/assets/icons/navigation/arrow-right-small.svg';
import NavigationArrowRightSvg from '@/assets/icons/navigation/arrow-right.svg';
import NavigationArrowTop20PxSvg from '@/assets/icons/navigation/arrow-top-20px.svg';
import NavigationArrowUpSmallSvg from '@/assets/icons/navigation/arrow-up-small.svg';
import NavigationArrowUpSvg from '@/assets/icons/navigation/arrow-up.svg';
import NavigationClockSvg from '@/assets/icons/navigation/clock.svg';
import NavigationClose20PxSvg from '@/assets/icons/navigation/close-20px.svg';
import NavigationClose24PxSvg from '@/assets/icons/navigation/close-24px.svg';
import NavigationClose36PxSvg from '@/assets/icons/navigation/close-36px.svg';
import NavigationFilterNewSvg from '@/assets/icons/navigation/filter-new.svg';
import NavigationFilterSvg from '@/assets/icons/navigation/filter.svg';
import NavigationHelpSvg from '@/assets/icons/navigation/help.svg';
import NavigationHomeSvg from '@/assets/icons/navigation/home.svg';
import NavigationInfo from '@/assets/icons/navigation/info.svg';
import NavigationInfoFillSvg from '@/assets/icons/navigation/info-fill.svg';
import NavigationInfoFill16Svg from '@/assets/icons/navigation/info-fill-16.svg';
import NavigationInfoOrangeSvg from '@/assets/icons/navigation/info-orange.svg';
import NavigationInfoOutlineSvg from '@/assets/icons/navigation/info-outline.svg';
import NavigationInfoWhiteSvg from '@/assets/icons/navigation/info-white.svg';
import NavigationInfoYellowSvg from '@/assets/icons/navigation/info-yellow.svg';
import NavigationLkSvg from '@/assets/icons/navigation/lk.svg';
import NavigationLocationSvg from '@/assets/icons/navigation/location.svg';
import NavigationLoginSvg from '@/assets/icons/navigation/login.svg';
import NavigationLogin20pxSvg from '@/assets/icons/navigation/login-20px.svg';
import NavigationMainArrowSvg from '@/assets/icons/navigation/main-arrow.svg';
import NavigationMenuSvg from '@/assets/icons/navigation/menu.svg';
import NavigationSearchSvg from '@/assets/icons/navigation/search.svg';
import NavigationSmallArrowDownSvg from '@/assets/icons/navigation/small-arrow-down.svg';
import NavigationSmallArrowLeftSvg from '@/assets/icons/navigation/small-arrow-left.svg';
import NavigationSmallArrowRightSvg from '@/assets/icons/navigation/small-arrow-right.svg';
import NavigationSmallArrowUpSvg from '@/assets/icons/navigation/small-arrow-up.svg';
import NavigationSortingSvg from '@/assets/icons/navigation/sorting.svg';
import NavigationArrowBack from '@/assets/icons/navigation/arrow-back.svg';
import NavigationArrowLongDown20pxSvg from '@/assets/icons/navigation/arrow-long-down-20px.svg';
import NavigationOpenNewTabSvg from '@/assets/icons/navigation/open-new-tab.svg';
import SocialTelegramLightSvg from '@/assets/icons/social/telegram-light.svg';
import SocialTelegramSvg from '@/assets/icons/social/telegram.svg';
import SocialYoutubeLightSvg from '@/assets/icons/social/youtube-light.svg';
import SocialYoutubeSvg from '@/assets/icons/social/youtube.svg';
import SystemHandSvg from '@/assets/icons/system/hand.svg';
import SystemProfileSvg from '@/assets/icons/system/profile.svg';
import TextFormattingAlignSvg from '@/assets/icons/text-formatting/align.svg';
import TextFormattingBackgroundColorSvg from '@/assets/icons/text-formatting/background-color.svg';
import TextFormattingBoldSvg from '@/assets/icons/text-formatting/bold.svg';
import TextFormattingBulletListSvg from '@/assets/icons/text-formatting/bullet-list.svg';
import TextFormattingColorSvg from '@/assets/icons/text-formatting/color.svg';
import TextFormattingHeadingSvg from '@/assets/icons/text-formatting/heading.svg';
import TextFormattingIndentRightSvg from '@/assets/icons/text-formatting/indent-right.svg';
import TextFormattingItalicsSvg from '@/assets/icons/text-formatting/italics.svg';
import TextFormattingOrderedListSvg from '@/assets/icons/text-formatting/ordered-list.svg';
import TextFormattingOutdentSvg from '@/assets/icons/text-formatting/outdent.svg';
import TextFormattingPolygonSvg from '@/assets/icons/text-formatting/polygon.svg';
import TextFormattingRedoSvg from '@/assets/icons/text-formatting/redo.svg';
import TextFormattingStrikeSvg from '@/assets/icons/text-formatting/strike.svg';
import TextFormattingUnderlineSvg from '@/assets/icons/text-formatting/underline.svg';
import TextFormattingUndoSvg from '@/assets/icons/text-formatting/undo.svg';
import LogoDescOffSvg from '@/assets/icons/logo-desc-off.svg';
import PlaySvg from '@/assets/icons/action/play.svg';
import PlayInvertedSvg from '@/assets/icons/action/play-inverted.svg';
import ChevronBackSvg from '@/assets/icons/navigation/chevron-back.svg';
import LockSvg from '@/assets/icons/action/lock.svg';
import UnLockSvg from '@/assets/icons/action/unlock.svg';
import FilesSvg from '@/assets/icons/action/files.svg';
import ActionDownloadTransparentSvg from '@/assets/icons/action/download-transparent.svg';
import NavigationMainArrowTransparent from '@/assets/icons/navigation/main-arrow-transparent.svg';
import IndicatorsProcessDarkGreen from '@/assets/icons/indicators/process-dark-green.svg';
import ActionFileCheckedGreen from '@/assets/icons/action/file-checked-green.svg';
import ActionFileCheckErrorRed from '@/assets/icons/action/file-check-error-red.svg';
import SmallArrow from '@/assets/icons/action/small-arrow.svg';
import SmallLoupe from '@/assets/icons/action/small-loupe.svg';
/**
 * images
 */
import ImagesLogoInvertedDescOn from '/images/logo-inverted-desc-on.svg';
import ImagesLogoDescOn from '/images/logo-desc-on.svg';
import ImagesLogoInvertedDescOff from '/images/logo-inverted-desc-off.svg';
import ImagesLogoDescOff from '/images/logo-desc-off.svg';
import ImagesLogoIcon from '/images/logo-icon.svg';
import ImagesLogoIconInverted from '/images/logo-icon-inverted.svg';
import { IconPathEnum } from '@/ui-kit/enums/iconPath.enum';

export const ALL_ICONS_BY_PATH = new Map<IconPathEnum, string>([
  /**
   * Icons
   */
  [IconPathEnum.ActionUpdate, ActionUpdateSvg],
  [IconPathEnum.ActionUpdateAccent, ActionUpdateAccentSvg],
  [IconPathEnum.ActionAttachSvg, ActionAttachSvg],
  [IconPathEnum.ActionCartMiniSvg, ActionCartMiniSvg],
  [IconPathEnum.ActionCartSvg, ActionCartSvg],
  [IconPathEnum.ActionCatalogSvg, ActionCatalog],
  [IconPathEnum.ActionCloseSvg, ActionCloseSvg],
  [IconPathEnum.ActionComment24PxSvg, ActionComment24PxSvg],
  [IconPathEnum.ActionCommentReview24PxSvg, ActionCommentReview24PxSvg],
  [IconPathEnum.ActionCommentReviewSvg, ActionCommentReviewSvg],
  [IconPathEnum.ActionCommentSvg, ActionCommentSvg],
  [IconPathEnum.ActionCompanySvg, ActionCompanySvg],
  [IconPathEnum.ActionCompareSvg, ActionCompareSvg],
  [IconPathEnum.ActionComment20Svg, ActionComment20Svg],
  [IconPathEnum.ActionDateSvg, ActionDateSvg],
  [IconPathEnum.ActionDownloadSvg, ActionDownloadSvg],
  [IconPathEnum.ActionFileChecked, ActionFileChecked],
  [IconPathEnum.ActionFileCheckError, ActionFileCheckError],
  [IconPathEnum.ActionFileChecking, ActionFileChecking],
  [IconPathEnum.ActionDocumentChecked, ActionDocumentChecked],
  [IconPathEnum.ActionDocumentCheckError, ActionDocumentCheckError],
  [IconPathEnum.ActionDocumentChecking, ActionDocumentChecking],
  [IconPathEnum.ActionDocumentUploading, ActionDocumentUploading],
  [IconPathEnum.ActionDocumentErrorSvg, ActionDocumentErrorSvg],
  [IconPathEnum.ActionDeliverySvg, ActionDeliverySvg],
  [IconPathEnum.ActionEyeSvg, ActionEyeSvg],
  [IconPathEnum.ActionFavouritesSvg, ActionFavouritesSvg],
  [IconPathEnum.ActionFileErrorSvg, ActionFileErrorSvg],
  [IconPathEnum.ActionGalleryViewSvg, ActionGalleryViewSvg],
  [IconPathEnum.ActionGluingSvg, ActionGluingSvg],
  [IconPathEnum.ActionIconMinusDarkSvg, ActionIconMinusDarkSvg],
  [IconPathEnum.ActionIconMinusSvg, ActionIconMinusSvg],
  [IconPathEnum.ActionIconPlusDarkSvg, ActionIconPlusDarkSvg],
  [IconPathEnum.ActionIconPlusSvg, ActionIconPlusSvg],
  [IconPathEnum.ActionInfoSvg, ActionInfoSvg],
  [IconPathEnum.ActionLinkSvg, ActionLinkSvg],
  [IconPathEnum.ActionLimitsSvg, ActionLimitsSvg],
  [IconPathEnum.ActionListingViewMiniSvg, ActionListingViewMiniSvg],
  [IconPathEnum.ActionListingViewSvg, ActionListingViewSvg],
  [IconPathEnum.ActionCopySvg, ActionCopySvg],
  [IconPathEnum.ActionMinusSvg, ActionMinusSvg],
  [IconPathEnum.ActionModerationSvg, ActionModerationSvg],
  [IconPathEnum.ActionMoreDotsSvg, ActionMoreDotsSvg],
  [IconPathEnum.ActionOrderSvg, ActionOrderSvg],
  [IconPathEnum.ActionPauseSvg, ActionPauseSvg],
  [IconPathEnum.ActionPdfPerforatedSvg, ActionPdfPerforatedSvg],
  [IconPathEnum.ActionPdfSvg, ActionPdfSvg],
  [IconPathEnum.ActionPenEditSvg, ActionPenEditSvg],
  [IconPathEnum.ActionPlusSvg, ActionPlusSvg],
  [IconPathEnum.ActionPriceRangeSvg, ActionPriceRangeSvg],
  [IconPathEnum.ActionPrintSvg, ActionPrintSvg],
  [IconPathEnum.ActionFileUploading, ActionFileUploading],
  [IconPathEnum.ActionRangeSvg, ActionRangeSvg],
  [IconPathEnum.ActionRepeatSvg, ActionRepeatSvg],
  [IconPathEnum.ActionReportSvg, ActionReportSvg],
  [IconPathEnum.ActionReviewSvg, ActionReviewSvg],
  [IconPathEnum.ActionSettingsSvg, ActionSettingsSvg],
  [IconPathEnum.ActionSpinnerSvg, ActionSpinnerSvg],
  [IconPathEnum.ActionTaskListSvg, ActionTaskListSvg],
  [IconPathEnum.ActionQuestionSvg, ActionQuestionSvg],
  [IconPathEnum.ActionThumbsDownSvg, ActionThumbsDownSvg],
  [IconPathEnum.ActionThumbsUpSvg, ActionThumbsUpSvg],
  [IconPathEnum.ActionTrashSvg, ActionTrashSvg],
  [IconPathEnum.ActionTrashTransparentSvg, ActionTrashTransparentSvg],
  [IconPathEnum.ActionTransferSvg, ActionTransferSvg],
  [IconPathEnum.ActionUploadProcessingSvg, ActionUploadProcessingSvg],
  [IconPathEnum.ActionUploadSvg, ActionUploadSvg],
  [IconPathEnum.ActionZoomInSvg, ActionZoomInSvg],
  [IconPathEnum.ActionVideoWhiteSvg, ActionVideoWhiteSvg],
  [IconPathEnum.ActionVideoGradientSvg, ActionVideoGradientSvg],
  [IconPathEnum.ActionNotificationSvg, ActionNotificationSvg],
  [IconPathEnum.IndicatorsBestPriceSvg, IndicatorsBestPriceSvg],
  [IconPathEnum.IndicatorsBulletDotSvg, IndicatorsBulletDotSvg],
  [IconPathEnum.IndicatorsBulletNumberSvg, IndicatorsBulletNumberSvg],
  [IconPathEnum.IndicatorsCartItemsSvg, IndicatorsCartItemsSvg],
  [IconPathEnum.IndicatorsCheckmarkRoundErrorSvg, IndicatorsCheckmarkRoundErrorSvg],
  [IconPathEnum.IndicatorsCheckmarkRoundFileSvg, IndicatorsCheckmarkRoundFileSvg],
  [IconPathEnum.IndicatorsCheckmarkRoundWatchSvg, IndicatorsCheckmarkRoundWatchSvg],
  [IconPathEnum.IndicatorsCheckmarkRoundDarkSvg, IndicatorsCheckmarkRoundDarkSvg],
  [IconPathEnum.IndicatorsCheckmarkRoundSvg, IndicatorsCheckmarkRoundSvg],
  [IconPathEnum.IndicatorsCheckmarkRoundGreenSvg, IndicatorsCheckmarkRoundGreenSvg],
  [IconPathEnum.IndicatorsCheckmarkWarnSvg, IndicatorsCheckmarkWarnSvg],
  [IconPathEnum.IndicatorsCheckmarkSimpleSvg, IndicatorsCheckmarkSimpleSvg],
  [IconPathEnum.IndicatorsCheckmarkSimpleGreySvg, IndicatorsCheckmarkSimpleGreySvg],
  [IconPathEnum.IndicatorsCheckmarkSvg, IndicatorsCheckmarkSvg],
  [IconPathEnum.IndicatorsFastShippingSvg, IndicatorsFastShippingSvg],
  [IconPathEnum.IndicatorsInfoDarkSvg, IndicatorsInfoDarkSvg],
  [IconPathEnum.IndicatorsInfoOutlineSvg, IndicatorsInfoOutlineSvg],
  [IconPathEnum.IndicatorsProgressSvg, IndicatorsProgressSvg],
  [IconPathEnum.IndicatorsProgressDarkSvg, IndicatorsProgressDarkSvg],
  [IconPathEnum.IndicatorsProgressLightSvg, IndicatorsProgressLightSvg],
  [IconPathEnum.IndicatorsStarSvg, IndicatorsStarSvg],
  [IconPathEnum.IndicatorsStepCloseSvg, IndicatorsStepCloseSvg],
  [IconPathEnum.IndicatorsUploadingSvg, IndicatorsUploadingSvg],
  [IconPathEnum.IndicatorsWarningSvg, IndicatorsWarningSvg],
  [IconPathEnum.NavigationArrowDown20PxSvg, NavigationArrowDown20PxSvg],
  [IconPathEnum.NavigationArrowDownSmallSvg, NavigationArrowDownSmallSvg],
  [IconPathEnum.NavigationArrowDownSvg, NavigationArrowDownSvg],
  [IconPathEnum.NavigationArrowLeft20PxSvg, NavigationArrowLeft20PxSvg],
  [IconPathEnum.NavigationArrowLeftSmallSvg, NavigationArrowLeftSmallSvg],
  [IconPathEnum.NavigationArrowLeftSvg, NavigationArrowLeftSvg],
  [IconPathEnum.NavigationArrowRight20PxSvg, NavigationArrowRight20PxSvg],
  [IconPathEnum.NavigationArrowRightSmallSvg, NavigationArrowRightSmallSvg],
  [IconPathEnum.NavigationArrowRightSvg, NavigationArrowRightSvg],
  [IconPathEnum.NavigationArrowTop20PxSvg, NavigationArrowTop20PxSvg],
  [IconPathEnum.NavigationArrowUpSmallSvg, NavigationArrowUpSmallSvg],
  [IconPathEnum.NavigationArrowUpSvg, NavigationArrowUpSvg],
  [IconPathEnum.NavigationClockSvg, NavigationClockSvg],
  [IconPathEnum.NavigationClose20PxSvg, NavigationClose20PxSvg],
  [IconPathEnum.NavigationQualificationFill, NavigationQualificationSvg],
  [IconPathEnum.NavigationClose24PxSvg, NavigationClose24PxSvg],
  [IconPathEnum.NavigationClose36PxSvg, NavigationClose36PxSvg],
  [IconPathEnum.NavigationFilterNewSvg, NavigationFilterNewSvg],
  [IconPathEnum.NavigationFilterSvg, NavigationFilterSvg],
  [IconPathEnum.NavigationHelpSvg, NavigationHelpSvg],
  [IconPathEnum.NavigationHomeSvg, NavigationHomeSvg],
  [IconPathEnum.NavigationInfo, NavigationInfo],
  [IconPathEnum.NavigationInfoFillSvg, NavigationInfoFillSvg],
  [IconPathEnum.NavigationInfoFill16Svg, NavigationInfoFill16Svg],
  [IconPathEnum.NavigationInfoOrangeSvg, NavigationInfoOrangeSvg],
  [IconPathEnum.NavigationInfoOutlineSvg, NavigationInfoOutlineSvg],
  [IconPathEnum.NavigationInfoWhiteSvg, NavigationInfoWhiteSvg],
  [IconPathEnum.NavigationInfoYellowSvg, NavigationInfoYellowSvg],
  [IconPathEnum.NavigationLkSvg, NavigationLkSvg],
  [IconPathEnum.NavigationLocationSvg, NavigationLocationSvg],
  [IconPathEnum.NavigationLoginSvg, NavigationLoginSvg],
  [IconPathEnum.NavigationLogin20pxSvg, NavigationLogin20pxSvg],
  [IconPathEnum.NavigationMainArrowSvg, NavigationMainArrowSvg],
  [IconPathEnum.NavigationMenuSvg, NavigationMenuSvg],
  [IconPathEnum.NavigationSearchSvg, NavigationSearchSvg],
  [IconPathEnum.NavigationSmallArrowDownSvg, NavigationSmallArrowDownSvg],
  [IconPathEnum.NavigationSmallArrowLeftSvg, NavigationSmallArrowLeftSvg],
  [IconPathEnum.NavigationSmallArrowRightSvg, NavigationSmallArrowRightSvg],
  [IconPathEnum.NavigationSmallArrowUpSvg, NavigationSmallArrowUpSvg],
  [IconPathEnum.NavigationSortingSvg, NavigationSortingSvg],
  [IconPathEnum.NavigationArrowBack, NavigationArrowBack],
  [IconPathEnum.NavigationArrowLongDown20pxSvg, NavigationArrowLongDown20pxSvg],
  [IconPathEnum.NavigationOpenNewTabSvg, NavigationOpenNewTabSvg],
  [IconPathEnum.SocialTelegramLightSvg, SocialTelegramLightSvg],
  [IconPathEnum.SocialTelegramSvg, SocialTelegramSvg],
  [IconPathEnum.SocialYoutubeLightSvg, SocialYoutubeLightSvg],
  [IconPathEnum.SocialYoutubeSvg, SocialYoutubeSvg],
  [IconPathEnum.SystemHandSvg, SystemHandSvg],
  [IconPathEnum.SystemProfileSvg, SystemProfileSvg],
  [IconPathEnum.TextFormattingAlignSvg, TextFormattingAlignSvg],
  [IconPathEnum.TextFormattingBackgroundColorSvg, TextFormattingBackgroundColorSvg],
  [IconPathEnum.TextFormattingBoldSvg, TextFormattingBoldSvg],
  [IconPathEnum.TextFormattingBulletListSvg, TextFormattingBulletListSvg],
  [IconPathEnum.TextFormattingColorSvg, TextFormattingColorSvg],
  [IconPathEnum.TextFormattingHeadingSvg, TextFormattingHeadingSvg],
  [IconPathEnum.TextFormattingIndentRightSvg, TextFormattingIndentRightSvg],
  [IconPathEnum.TextFormattingItalicsSvg, TextFormattingItalicsSvg],
  [IconPathEnum.TextFormattingOrderedListSvg, TextFormattingOrderedListSvg],
  [IconPathEnum.TextFormattingOutdentSvg, TextFormattingOutdentSvg],
  [IconPathEnum.TextFormattingPolygonSvg, TextFormattingPolygonSvg],
  [IconPathEnum.TextFormattingRedoSvg, TextFormattingRedoSvg],
  [IconPathEnum.TextFormattingStrikeSvg, TextFormattingStrikeSvg],
  [IconPathEnum.TextFormattingUnderlineSvg, TextFormattingUnderlineSvg],
  [IconPathEnum.TextFormattingUndoSvg, TextFormattingUndoSvg],
  [IconPathEnum.LogoDescOffSvg, LogoDescOffSvg],
  [IconPathEnum.PlaySvg, PlaySvg],
  [IconPathEnum.PlayInvertedSvg, PlayInvertedSvg],
  [IconPathEnum.ChevronBackSvg, ChevronBackSvg],
  [IconPathEnum.LockSvg, LockSvg],
  [IconPathEnum.UnLockSvg, UnLockSvg],
  [IconPathEnum.FilesSvg, FilesSvg],
  [IconPathEnum.ActionDownloadTransparentSvg, ActionDownloadTransparentSvg],
  [IconPathEnum.NavigationMainArrowTransparent, NavigationMainArrowTransparent],
  [IconPathEnum.IndicatorsProcessDarkGreen, IndicatorsProcessDarkGreen],
  [IconPathEnum.ActionFileCheckedGreen, ActionFileCheckedGreen],
  [IconPathEnum.ActionFileCheckErrorRed, ActionFileCheckErrorRed],
  [IconPathEnum.SmallArrow, SmallArrow],
  [IconPathEnum.SmallLoupe, SmallLoupe],

  /**
   * Images
   */
  [IconPathEnum.ImagesLogoInvertedDescOn, ImagesLogoInvertedDescOn],
  [IconPathEnum.ImagesLogoDescOn, ImagesLogoDescOn],
  [IconPathEnum.ImagesLogoInvertedDescOff, ImagesLogoInvertedDescOff],
  [IconPathEnum.ImagesLogoDescOff, ImagesLogoDescOff],
  [IconPathEnum.ImagesLogoIcon, ImagesLogoIcon],
  [IconPathEnum.ImagesLogoIconInverted, ImagesLogoIconInverted],
]);
