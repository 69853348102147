import { ProviderService } from '../models/providerService.model';
import { useModal } from 'vue-final-modal';
import type { Component } from 'vue';
import type { IModalOptions } from '@/ui-kit/models/modal-options.model';

export default class ModalManager extends ProviderService {
  static readonly serviceName = 'modalManager';
  private static _instance: ModalManager;

  public static getInstance() {
    return this._instance;
  }

  constructor() {
    if (ModalManager._instance) {
      return ModalManager._instance;
    }
    super();
    ModalManager._instance = this;
  }

  public async openAsyncModal<T extends Component, K>(
    component: Component,
    options?: IModalOptions<T>,
  ): Promise<unknown> {
    if (!options) {
      options = {};
    }
    if (!options.attrs) {
      options.attrs = {};
    }
    return new Promise((resolve) => {
      const { open, close } = useModal({
        component: component,
        attrs: {
          onConfirm(data: K) {
            close();
            resolve(data);
          },
          onCancel() {
            close();
            resolve(null);
          },
          ...options.attrs,
          clickToClose: false,
          escToClose: false,
        },
        slots: options.slots,
      });
      open();
    });
  }
}
