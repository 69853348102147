import { getCurrentInstance } from 'vue';
import type { AxiosInstance, AxiosStatic } from 'axios';
import type { IAuthToken } from '@/common/models/api/auth/authToken.model';
import type { ILogin } from '@/common/models/api/auth/login.model';
import type {
  ISupplierBaseInfo,
  ISupplierProfileEmployees,
  ISupplierProfilePage, ISupplierProfileSecurityComment,
} from '@/views/srm/supplier-profile/api/models/supplierProfilePage.model';
import type { IPageSize } from '@/common/models/pageSize.model';
import type { IPagination } from '@/common/models/pagination';

export function useSupplierApi(axiosInstance?: AxiosInstance): IProfileApi {
  const baseApiUrl = `${import.meta.env.VITE_API_URL}/auth`;
  const baseSupplierApiUrl = `${import.meta.env.VITE_API_URL}/suppliers`;
  const axios: AxiosStatic = axiosInstance || getCurrentInstance().appContext?.config?.globalProperties?.$axios;

  return {
    login: async (body: ILogin): Promise<IAuthToken> => {
      const res = await axios.post(`${baseApiUrl}/login/`, body);
      return res.data;
    },
    getCompleteProfile: async (): Promise<ISupplierProfilePage> => {
      const res = await axios.get(`${baseSupplierApiUrl}/profile/`);
      return res.data;
    },
    getBaseProfile: async (): Promise<ISupplierBaseInfo> => {
      const res = await axios.get(`${baseSupplierApiUrl}/statuses/`, {
        params: {
          skipAuth: true,
        },
      });
      return res.data;
    },
    getProfileEmployees: async (supplierId: number): Promise<Array<ISupplierProfileEmployees>> => {
      const res = await axios.get(`${baseSupplierApiUrl}/${supplierId}/employees/`);
      return res.data;
    },
    getSecurityComments: async (pagination: IPageSize): Promise<IPagination<ISupplierProfileSecurityComment>> => {
      const res = await axios.get(`${baseSupplierApiUrl}/profile/security-comments/`, {
        params: pagination,
      });
      return res.data;
    },
  }
}

export interface IProfileApi {
  login: (body: ILogin) => Promise<IAuthToken>;
  getCompleteProfile: () => Promise<ISupplierProfilePage>;
  getBaseProfile: () => Promise<ISupplierBaseInfo>;
  getProfileEmployees: (supplierId: number) => Promise<Array<ISupplierProfileEmployees>>;
  getSecurityComments: (pagination: IPageSize) => Promise<IPagination<ISupplierProfileSecurityComment>>;
}
