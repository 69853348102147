<script setup lang="ts">
import StaticPage from '@/common/components/static/StaticPage.vue';
import { useRoute, useRouter } from 'vue-router';
import { useAuthToken } from '@/common/composables/useAuthToken';
import Loader from '@/ui-kit/utils/loaderHelper.util';
import { onBeforeMount, ref } from 'vue';
import ClipLoader from '@/ui-kit/components/ClipLoader.vue';
import Notificator from '@/ui-kit/services/notificator.service';
import { useAuthorizationApi } from '@/views/auth/api/useAuthorizationApi';
import { ErrorTypeEnum } from '@/common/enums/error-type.enum';

const props = defineProps<{
  imageUrl: string,
  containerWidth: string
}>();

const router = useRouter();
const route = useRoute();

const isLoading = Loader.getReactiveInstance();
const {isAuthorized} = useAuthToken();
const api = useAuthorizationApi();

const otp = route.params['otp'] as string;
const isConfirmed = ref(false);

async function confirmEmail(): Promise<void> {
  try {
    isLoading.value = true;
    await api.confirmEmail(otp);
    isConfirmed.value = true;
  } catch (e) {
    console.error((e))
    const errors = e.response?.data?.errors;
    const expiredError = errors?.find((item) => item.code === ErrorTypeEnum.Expired);
    const conflictingState = errors?.find((item) => item.code === ErrorTypeEnum.ConflictingState);
    const notFoundError = errors?.find((item) => item.code === ErrorTypeEnum.NotFound || item.code === ErrorTypeEnum.UuidParsing);
    if (expiredError) {
      await router.push('/auth/link-expired');
    } else if (conflictingState) {
      await router.push('/auth/login');
    } else if (notFoundError) {
      await router.push('/auth/not-found');
    } else {
      Notificator.showDetachedNotification('Произошла непредвиденная ошибка');
    }
  } finally {
    isLoading.value = false;
  }
}

function navigateToProfile(): void {
  if (isAuthorized()) {
    router.push('/account-settings/general-information');
  } else {
    router.push('/auth/login');
  }
}

onBeforeMount(() => {
  confirmEmail();
})
</script>

<template>
  <ClipLoader v-if="isLoading.value" />
  <div>
    <StaticPage
      v-if="isConfirmed"
      :image-url="props.imageUrl"
    >
      <div>
        <div
          class="text-wrapper"
          :style="{'width': containerWidth}"
        >
          <p class="main-text">Адрес электронной почты успешно подтвержден</p>
          <p class="text">
            Настроить уведомления на почту и изменить адрес<br>можно в настройках профиля в любой момент
          </p>
          <button
            class="btn btn-primary"
            @click="navigateToProfile"
          >
            Перейти в профиль
          </button>
        </div>
      </div>
    </StaticPage>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/styles/base/common/variables';

:global(.auth-wrapper) {
  background: white !important;
}

.not-found-content {
  height: 502px;
  justify-content: center;
  align-items: center;
  margin-top: 120px;

  .text-wrapper {
    margin-left: 135px;

    .main-text {
      font-size: 32px;
      font-weight: 500;
      line-height: 36px;
    }

    .text {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
    }
  }
}
</style>
