<script setup lang="ts">

import StaticPage from '@/common/components/static/StaticPage.vue';
import { useRouter } from 'vue-router';
import { useAuthToken } from '@/common/composables/useAuthToken';

const props = defineProps<{
  imageUrl: string,
  containerWidth: string
}>();

const router = useRouter();
const { isAuthorized } = useAuthToken();

function navigateToProfile(): void {
  if (isAuthorized()) {
    router.push('/account-settings/general-information');
  } else {
    router.push('/auth/login');
  }
}
</script>

<template>
  <div>
    <StaticPage :image-url="props.imageUrl">
      <div>
        <div
          class="text-wrapper"
          :style="{'width': containerWidth}"
        >
          <p class="main-text">Срок действия ссылки для подтверждения почты истек</p>
          <p class="text">
            Запросите  новую ссылку для подтверждения почты<br>в настройках профиля
          </p>
          <button
            class="btn btn-primary"
            @click="navigateToProfile"
          >
            Перейти в профиль
          </button>
        </div>
      </div>
    </StaticPage>
  </div>
</template>

<style scoped lang="scss">

@import '../../../assets/styles/base/common/variables';

:global(.auth-wrapper) {
  background: white !important;
}

.not-found-content {
  height: 502px;
  justify-content: center;
  align-items: center;
  margin-top: 120px;

  .text-wrapper {
    margin-left: 135px;

    .main-text {
      font-size: 32px;
      font-weight: 500;
      line-height: 36px;
    }

    .text {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
    }
  }
}

</style>
