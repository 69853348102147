import { POSITION, TYPE, useToast } from 'vue-toastification';
import type { INotificator } from '../models/notificator.model';
import NotificatorSettings from '../constants/notificator.const';
import { ProviderService } from '../models/providerService.model';
import { NotificationActionsEnum } from '../enums/notificationActions.enum';
import { ref } from 'vue';
import type { ToastContent, ToastID, ToastOptions } from 'vue-toastification/dist/types/types';
import InfoIcon from '../components/icons/InfoNotification.vue';
import CloseIcon from '../components/icons/CloseNotification.vue';
import RoundSuccessIcon from '@/ui-kit/components/icons/RoundSuccessIcon.vue';
import FloatingUserNotification from '@/common/components/FloatingUserNotification.vue';
import type { INotificationListItem } from '@/common/models/notifications.model';
import { type INotificationsApi } from '@/common/api/useNotificationsApi';

export default class Notificator extends ProviderService implements INotificator {
  static readonly serviceName = 'notificator';

  public notificatorAction = ref<NotificationActionsEnum | null>(null);

  constructor() {
    super();
  }

  static showDetachedNotification(message: ToastContent, settings: ToastOptions & { type?: TYPE.INFO } = {}) {
    useToast().updateDefaults({
      ...NotificatorSettings.DEFAULT_NOTIFICATION_PLUGIN_SETTINGS,
      newestOnTop: true,
    });

   return useToast().info(message, {
      ...NotificatorSettings.DEFAULT_NOTIFICATION_SETTINGS,
      icon: InfoIcon,
      closeButton: CloseIcon,
      ...settings,
      bodyClassName: `${NotificatorSettings.DEFAULT_NOTIFICATION_SETTINGS.bodyClassName} ${settings.bodyClassName ?? ''}`,
      toastClassName: `${NotificatorSettings.DEFAULT_NOTIFICATION_SETTINGS.toastClassName} ${settings.toastClassName ?? ''}`,

    });
  }

  static showDetachedSuccessNotification(message: string, settings: Record<string, unknown> = {}) {
    useToast().updateDefaults({
      ...NotificatorSettings.DEFAULT_NOTIFICATION_PLUGIN_SETTINGS,
      newestOnTop: true,
    });

    useToast().info(message, {
      ...NotificatorSettings.DEFAULT_NOTIFICATION_SETTINGS,
      icon: RoundSuccessIcon,
      closeButton: CloseIcon,
      ...settings,
    });
  }

  private static closeToast(toastId: ToastID): void {
    setTimeout(() => {
      useToast().dismiss(toastId);
    }, NotificatorSettings.DEFAULT_NOTIFICATION_PLUGIN_SETTINGS.timeout);
  }

  static showFloatingUserNotification(notification: INotificationListItem, api: INotificationsApi, timeout: number): void {
    useToast().updateDefaults({
      ...NotificatorSettings.DEFAULT_NOTIFICATION_PLUGIN_SETTINGS,
      newestOnTop: false,
      transition: 'Vue-Toastification__slideBlurred',
    });

    const toastId  = useToast().info({
      component: FloatingUserNotification,
      props: {
        notification,
        api,
      },
      listeners: {
        close: () => {
          useToast().dismiss(toastId);
        },
      },
    }, {
      ...NotificatorSettings.DEFAULT_NOTIFICATION_SETTINGS,
      bodyClassName: 'mm-floating-notification__body',
      toastClassName: 'mm-floating-notification',
      position: POSITION.TOP_RIGHT,
      timeout,
    });
  }

  static dismissNotification(id: ToastID) {
    useToast().dismiss(id);
  }

  static clearAllNotifications(): void {
    useToast().clear();
  }
}
