<template>
  <div
    :class="[
      'mm-tab',
      { 'mm-tab--active': props.isActive },
      { 'mm-tab--passed': props.isPassed },
      { 'mm-tab--disabled': props.unselectable },
      props.customClass
    ]"
  >
    <slot :is-active="props.isActive" />
  </div>
</template>

<script lang="ts" setup>
import type { TTabValue } from '@/ui-kit/models/tabs.model';

const props = withDefaults(
  defineProps<{
    value?: TTabValue; // Значение вкладки
    isActive?: boolean; // Выбрана ли вкладка
    isPassed?: boolean; // Выбрана ли вкладка
    unselectable?: boolean; // Отключить возможность выбора
    customClass?: string;
  }>(),
  {},
);
</script>

<style lang="scss" scoped>
@import '@styles/base/common/variables';

.mm-tab {
  display: flex;
  align-items: center;
  color: $text-black;
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  position: relative;
  padding: 10px 0;

  &--active {
    border-bottom-color: $link;
    color: $link;
    font-weight: 500;
  }

  &--disabled {
    pointer-events: none;
  }
}
</style>
