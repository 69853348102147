import { getCurrentInstance } from 'vue';
import type { AxiosInstance } from 'axios';
import type { IAuthToken } from '@/common/models/api/auth/authToken.model';
import type { IRefreshToken } from '@/common/models/api/auth/refreshToken.model';
import type { IBaseUser } from '@/common/models/base-user';

export function useAuthApi(axiosInstance?: AxiosInstance): IProfileApi {
  const baseApiUrl = `${import.meta.env.VITE_API_URL}/auth`;

  const axios = axiosInstance || getCurrentInstance().appContext?.config?.globalProperties?.$axios;

  return {
    refreshToken: async (body: IRefreshToken): Promise<IAuthToken> => {
      const res = await axios.post(`${baseApiUrl}/refresh/`, body, {
        headers: {
          'Ignore-Access-Token': 'true',
        },
      });
      return res.data;
    },
    getCurrentUser: async (): Promise<IBaseUser> => {
      const res = await axios.get(`${import.meta.env.VITE_API_URL}/users/profile/`);
      return res.data;
    },
  };
}

export interface IProfileApi {
  refreshToken: (body: IRefreshToken) => Promise<IAuthToken>;
  getCurrentUser: () => Promise<IBaseUser>;
}
