import { watchEffect } from 'vue';
import { useProfileStore } from '@/stores/profile.store';

// waitForCurrentUser() should be preceded by waitForCurrentRole, interceptor will allow profile request only when role is known to set role header
export function waitForCurrentUser<T>(resolveFn: () => T, authOnly = false): Promise<T> {
  const userStore = useProfileStore();
  return new Promise((resolve) => {
    if (authOnly ? userStore.getCurrent : userStore.getProfile) {
      resolve(resolveFn());
    } else {
      watchEffect(() => {
        if (authOnly ? userStore.getCurrent : userStore.getProfile) {
          resolve(resolveFn());
        }
      });
    }
  });
}

// waitForCurrentRole should precede waitForCurrentUser, interceptor will stop profile request to fill role header
export function waitForCurrentRole(): Promise<string> {
  const userStore = useProfileStore();
  return waitForCurrentUser<string>(() => {
    const defaultRoleName = userStore.current?.roles?.[0]?.name as string;
    const currentRole = sessionStorage.getItem('roleName') || defaultRoleName;
    userStore.setCurrentRoleName(currentRole);
    sessionStorage.setItem('roleName', currentRole);
    return currentRole;
  }, true);
}
