import { afterNDays } from '../utils/date.util';

export class CookieManager {
  static MAX_COOKIE_EXPIRATION_DAYS = 400;

  public static setCookie(key: string, value: string, expirationDays: number = CookieManager.MAX_COOKIE_EXPIRATION_DAYS) {
    const expirationDate = afterNDays(expirationDays);
    const cookieValue = encodeURIComponent(key) + '=' + encodeURIComponent(value) + '; expires=' + expirationDate.toUTCString() + '; path=/';
    document.cookie = cookieValue;
  }

  public static getCookie(key: string): string | null {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }

      const cookieParts = cookie.split('=');

      if (cookieParts.length >= 2) {
        const cookieKey = decodeURIComponent(cookieParts[0].trim());
        const cookieValue = decodeURIComponent(cookieParts.slice(1).join('=').trim());

        if (cookieKey === key) {
          return cookieValue;
        }
      }
    }
    return null;
  }
}
