<script lang="ts" setup>
import type IProductCharacteristic from '@/ui-kit/models/productCharacteristic.model';
import ProductCharacteristic from '@/ui-kit/components/ProductCharacteristic.vue';
import AppTable from '@/ui-kit/components/AppTable.vue';

const contentList: Array<IProductCharacteristic> = [
  {
    label: '1.\tНазначение и область применения',
    value: '3',
  },
  {
    label: '2.\tТермины и сокращения',
    value: '3',
  },
  {
    label: '3.\tОбщие положения',
    value: '5',
  },
  {
    label: '4.\tПорядок и условия обработки ПДн в Обществе, соблюдение конфиденциальности персональных данных',
    value: '8',
  },
  {
    label: '5.\tПрава и обязанности субъектов ПДн, обрабатываемых Обществом',
    value: '9',
  },
  {
    label: '6.\tПрава и обязанности Оператора',
    value: '9',
  },
  {
    label: '7.\tНормативные ссылки',
    value: '11',
  },
  {
    label: '8.\tПорядок актуализации и внесения изменений',
    value: '11',
  },
  {
    label: '9.\tКонтроль и ответственность за исполнение Политики',
    value: '11',
  },
];

const termines = [
  {
    termine: 'Автоматизированная обработка персональных данных',
    desc: 'обработка персональных данных с помощью средств вычислительной техники',
  },
  {
    termine: 'Биометрические персональные данные',
    desc: 'сведения, которые характеризуют физиологические и биологические особенности человека, на основании которых можно установить его личность и которые используются оператором для установления личности субъекта персональных данных',
  },
  {
    termine: 'Блокирование персональных данных',
    desc: 'временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных)',
  },
  {
    termine: 'Закон',
    desc: 'Федеральный закон от 27 июля 2006 г. №152-ФЗ «О персональных данных»',
  },
  {
    termine: 'Браузер',
    desc: 'прикладное программное обеспечение установленное на устройстве  для просмотра страниц сети Интернет',
  },
  {
    termine: 'Сookie файлы',
    desc: 'фрагмент данных об истории посещения веб - страниц, сохраняемый в браузере на устройстве пользователя Сайта.',
  },
  {
    termine: 'Информационная система персональных данных',
    desc: 'совокупность содержащихся в базах данных персональных данных и обеспечивающих их обработку информационных технологий и технических средств',
  },
  {
    termine: 'Контрагент',
    desc: 'физические, юридические лица и индивидуальные предприниматели, заключающие или заключившие договор с Обществом',
  },
  {
    termine: 'Конфиденциальность персональных данных',
    desc: 'Состояние защищенности персональных данных, при котором Операторы и иные лица, получившие доступ к персональным данным, не раскрывают третьим лицам и не распространяют персональные данные без согласия субъекта персональных данных, если иное не предусмотрено федеральным законом',
  },
  {
    termine: 'Обработка персональных данных',
    desc: 'любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление), предоставление доступа или осуществление логических и (или) арифметических операций с такими данными, обезличивание, блокирование, удаление, уничтожение персональных данных',
  },
  {
    termine: 'Оператор персональных данных',
    desc: 'государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными',
  },
  {
    termine: 'Общество',
    desc: 'Акционерное общество «РАЗВИТИЕ»',
  },
  {
    termine: 'Персональные данные',
    desc: 'любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных)',
  },
  {
    termine: 'Персональные данные, разрешенные субъектом персональных данных для распространения',
    desc: 'персональные данные, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных путем дачи согласия на обработку персональных данных, разрешенных субъектом персональных данных для распространения, в порядке, предусмотренном Законом',
  },
  {
    termine: 'Предоставление персональных данных',
    desc: 'действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц',
  },
  {
    termine: 'Распространение персональных данных',
    desc: 'действия Оператора или самого субъекта персональных данных, направленные на раскрытие персональных данных неопределенному кругу лиц',
  },
  {
    termine: 'Специальные категории персональных данных',
    desc: 'данные, касающиеся расовой, национальной принадлежности, политических взглядов, религиозных или философских убеждений, состояния здоровья, интимной жизни',
  },
  {
    termine: 'Субъект персональных данных',
    desc: 'физическое лицо, которое прямо или косвенно определено или определяемо с помощью персональных данных',
  },
  {
    termine: 'Трансграничная передача персональных данных',
    desc: 'передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому лицу или иностранному юридическому лицу',
  },
  {
    termine: 'Пользователь Сайта',
    desc: 'субъект персональных данных, персональные данные которого обрабатываются в связи с посещением веб - сайта https://industrservice.ru/',
  },
  {
    termine: 'Сайт',
    desc: 'Веб - сайт https://industrservice.ru/',
  },
  {
    termine: 'Третьи лица',
    desc: 'государственный орган, муниципальный орган, юридическое или физическое лицо, осуществляющее обработку ПДн, которому переданы, предоставлен доступ к ПДн, либо поручена обработка ПДн по поручению оператора ПДн с согласия субъекта ПДн, если иное не предусмотрено федеральным законом',
  },
  {
    termine: 'Уничтожение персональных данных',
    desc: 'действия, в результате которых становится невозможным восстановить содержание персональных данных в информационной системе персональных данных и (или) в результате которых уничтожаются материальные носители персональных данных',
  },
  {
    termine: 'Уполномоченные представители Контрагента',
    desc: 'лица, уполномоченные представлять организацию Контрагента на основании ее учредительных документов либо доверенности.',
  },
];

const shortences = [
  {
    short: 'ИСПДн',
    desc: 'информационная система персональных данных',
  },
  {
    short: 'ЛНА',
    desc: 'локальный нормативный акт',
  },
  {
    short: 'ПДн',
    desc: 'персональные данные',
  },
  {
    short: 'РФ',
    desc: 'Российская Федерация',
  },
  {
    short: 'ФЗ',
    desc: 'Федеральный закон',
  },
];
</script>

<template>
  <div class="personal-data-policy">
    <div class="right w500">Приложение №1</div>
    <div class="mt24" />
    <div class="right text-left">
      <p>
        <span class="w500">УТВЕРЖДЕНА</span> <br>
        приказом Генерального директора <br>
        АО «РАЗВИТИЕ» <br>
        от «___»_____________2024 г. №____
      </p>
    </div>
    <div class="mt60" />

    <div class="center">
      <p class="w500 text-center">
        ПОЛИТИКА <br>
        АО «РАЗВИТИЕ» <br>
        в отношении обработки персональных данных <br>
        № ___________ версия 2
      </p>
    </div>

    <div class="mt240" />

    Содержание

    <div class="mt24" />
    <ProductCharacteristic
      :characteristics="contentList"
      big-margin-bottom
      big-value-font
      wrap-value
    />

    <div class="mt60" />

    <div class="center w500">1. Назначение и область применения</div>
    <div class="indent justify">
      1.1 Настоящая Политика в отношении обработки персональных данных (далее – Политика) предназначена для определения общих принципов обработки персональных данных работников АО «РАЗВИТИЕ» (далее – Общество) и иных лиц, чьи персональные данные обрабатываются Обществом с целью обеспечения защиты прав и свобод человека и гражданина - субъекта персональных данных при обработке его персональных данных (далее - ПДн).
    </div>
    <div class="indent justify">
      1.2 В соответствии с подпунктом 2 статьи 3 Федерального закона от 27 июля 2006 г. No152-ФЗ «О персональных данных» (далее - Закон) Общество является оператором, то есть юридическим лицом, самостоятельно или совместно с другими лицами организующим и (или) осуществляющим обработку ПДн, а также определяющим цели обработки ПДн, состав ПДн, подлежащих обработке, действия (операции), совершаемые с ПДн.
    </div>
    <div class="indent justify">
      1.3 Действие настоящей Политики распространяется на все операции, совершаемые в Обществе с ПДн с использованием средств автоматизации или без их использования.
    </div>
    <div class="indent justify">
      1.4 Настоящая Политика обязательна для ознакомления и исполнения всеми работниками Общества.
    </div>
    <div class="indent justify">
      1.5 Обеспечение неограниченного доступа к настоящей Политике реализуется путем
      ее публикации на сайте Общества «https://industrservice.ru/» в сети Интернет, либо иным способом.
    </div>

    <div class="mt60" />

    <div class="center w500">2. Термины и сокращения</div>
    <div class="indent justify">
      2.1 Термины
    </div>
    <Suspense>
      <AppTable
        :columns="[{ title: 'Термин', field: 'termine' }, { title: 'Определение', field: 'desc' }]"
        :data="termines"
      />
    </Suspense>
    <div class="mt24" />
    <div class="indent justify">
      2.2 Сокращения
    </div>
    <Suspense>
      <AppTable
        :columns="[{ title: 'Сокращение', field: 'short' }, { title: 'Расшифровка', field: 'desc' }]"
        :data="shortences"
      />
    </Suspense>

    <div class="mt60" />
    <div class="center w500">3. Общие положения</div>

    <div class="indent justify">
      3.1 Общество, являясь Оператором ПДн (регистрационный номер в реестре операторов персональных данных Роскомнадзора №24-23-016312), осуществляет обработку персональных данных нижеуказанных субъектов персональных данных по заранее определённым основаниям, целям, составу и срокам:
    </div>

    <div class="mt24" />

    <div class="indent justify w500">
      &mdash; Соискатели на замещение вакантных должностей (кандидаты)
    </div>
    <div class="indent justify">
      В составе и сроком, необходимых для цели:
    </div>
    <div class="indent justify">
      принятия Обществом решения о приеме на работу, либо отказе в приеме на работу,
    </div>
    <div class="indent justify">
      а также содействия в трудоустройстве, включая соискателей откликнувшихся
    </div>
    <div class="indent justify">
      вакансию по средствам сайта  https://industrservice.ru/, с согласия субъекта ПДн.
    </div>
    <div class="indent justify">
      Основанием обработки ПДн является согласие субъекта ПДн, Трудовой кодекс Российской Федерации, требования законодательства об архивном хранении документов, образующихся в процессе деятельности организаций.
    </div>

    <div class="mt24" />

    <div class="indent justify w500">
      &mdash; Родственники кандидатов, иные связанные и взаимозависимые с кандидатом лица
    </div>
    <div class="indent justify">
      В составе и сроком, необходимых для цели:
    </div>
    <div class="indent justify">
      управления рисками конфликта интересов при рассмотрении вопроса о трудоустройстве кандидата.
    </div>
    <div class="indent justify">
      Основанием обработки ПДн является поручение кандидата на обработку ПДн его родственников, иных связанных и взаимозависимых с ним лиц, либо согласие субъекта ПДн.
    </div>

    <div class="mt24" />

    <div class="indent justify w500">
      &mdash; Работники Общества
    </div>
    <div class="indent justify">
      В составе и сроком, необходимых для целей:
    </div>
    <div class="indent justify">
      выполнения возложенных законодательством РФ на Общество функций, полномочий и обязанностей как работодателя (в т.ч. осуществление кадрового, бухгалтерского, пенсионного, налогового учета, обязательного социального страхования работников, выполнения санитарно-эпидемиологических требований и норм, требований к охране труда и промышленной безопасности, представление установленной законодательством РФ отчетности).
    </div>
    <div class="indent justify">
      Основанием обработки являются требования Законодательства.
    </div>

    <div class="mt24" />

    <div class="indent justify">
      А также в составе и сроком, необходимых для целей:
    </div>
    <ul>
      <li>o выполнения бизнес-процессов Общества, предусмотренных ЛНА.</li>
      <li>o предоставления компенсаций и льгот, страхования в случаях, предусмотренных ЛНА Общества.</li>
    </ul>
    <div class="indent justify">
      Основанием обработки ПДн является согласие субъекта ПДн, требования законодательства об архивном хранении документов, образующихся в процессе деятельности организаций.
    </div>

    <div class="mt24" />

    <div class="indent justify w500">
      &mdash; Родственники и иждивенцы работников Общества
    </div>
    <div class="indent justify">
      В составе и сроком, необходимых для цели:
    </div>
    <div class="indent justify">
      предоставление работникам и членам их семей дополнительных гарантий, компенсаций и льгот.
    </div>
    Основанием обработки ПДн является согласие субъекта ПДн.
    <div class="indent justify">
      А также в составе и сроком, необходимых для целей:
    </div>
    <div class="indent justify">
      выполнения возложенных законодательством на Общество полномочий и обязанностей как работодателя; в том числе извещение о несчастном случае с работником Общества.
    </div>
    <div class="indent justify">
      Основанием обработки ПДн являются требования законодательства о расследовании несчастных случаев, об архивном хранении документов, образующихся в процессе деятельности организаций.
    </div>

    <div class="mt24" />

    <div class="indent justify w500">
      &mdash; Лица, ранее состоящие в трудовых отношениях с Обществом (бывшие работники Общества)
    </div>
    <div class="indent justify">
      В составе и сроком, необходимых для целей:
    </div>
    <div class="indent justify">
      выполнения требований нормативных правовых актов об архивном хранении документов, образующихся в процессе деятельности организаций.
    </div>
    <div class="indent justify">
      Основанием обработки ПДн являются требования нормативно-правовых актов об архивном хранении документов, образующихся в процессе деятельности организаций.
    </div>

    <div class="mt24" />

    <div class="indent justify w500">
      &mdash; Контрагенты Общества
    </div>
    <div class="indent justify">
      В составе и сроком, необходимых для целей:
    </div>
    <div class="indent justify">
      исполнения договора, стороной которого либо выгодоприобретателем или поручителем, по которому является контрагент, а также для заключения договора по инициативе субъекта персональных данных или договора, по которому субъект персональных данных будет являться выгодоприобретателем или поручителем.
    </div>
    <div class="indent justify">
      Основанием обработки ПДн являются заключаемые договоры с Контрагентом, требования законодательства об архивном хранении документов, образующихся в процессе деятельности организаций.
    </div>

    <div class="mt24" />

    <div class="indent justify w500">
      &mdash; Работники Контрагентов Общества
    </div>
    <div class="indent justify">
      В составе и сроком, необходимых для целей:
    </div>
    <div class="indent justify">
      исполнения договора, стороной которого либо выгодоприобретателем или поручителем, по которому является контрагент, а также для заключения договора по инициативе субъекта персональных данных или договора, по которому субъект персональных данных будет являться выгодоприобретателем или поручителем.
    </div>
    <div class="indent justify">
      Основанием обработки ПДн являются заключаемые договоры с Контрагентом, требования законодательства об архивном хранении документов, образующихся в процессе деятельности организаций.
    </div>

    <div class="mt24" />

    <div class="indent justify w500">
      &mdash; Пользователи сайта Общества
    </div>
    <div class="indent justify">
      В составе и сроком, необходимых для целей:
    </div>
    <div class="indent justify">
      обеспечения возможности использования Сайта - https://industrservice.ru/, сбора статистической информации, связанной с работой Сайта и адаптации его работы с учетом устройства пользователя Сайта.
    </div>
    <div class="indent justify">
      При первом посещении Сайта Общества Пользователь уведомляется о порядке использования файлов cookies.
    </div>

    <div class="mt24" />

    <div class="indent justify w500">
      &mdash; Отправители входящей корреспонденции (официальные письма, телеграммы, почтовые отправления)
    </div>
    <div class="indent justify">
      В составе и сроком, необходимых для целей:
    </div>
    <div class="indent justify">
      подготовки ответа на официальные письма, телеграммы, почтовые отправления, электронные письма, поступившие в адрес Общества.
    </div>
    <div class="indent justify">
      Основанием обработки ПДн является согласие субъекта ПДн, выраженное в конклюдентной форме посредством направления письма в адрес Общества; требования законодательства об архивном хранении документов, образующихся в процессе деятельности организаций.
    </div>

    <div class="mt24" />

    <div class="indent justify">
      Сроки обработки ПДн для каждой категории субъектов ПДн определяются Обществом с учетом:
    </div>
    <div class="indent justify">
      &mdash; установленных целей обработки ПДн;
    </div>
    <div class="indent justify">
      &mdash; сроков, указанных в договорах с субъектами ПДн
    </div>
    <div class="indent justify">
      &mdash; сроков, указанных в согласиях субъектов ПДн;
    </div>
    <div class="indent justify">
      &mdash; сроков, указанных в нормативно-правовых актах, устанавливающих сроки хранения ПДн.
    </div>
    <div class="indent justify">
      Сроки обрабатываемых Обществом ПДн, перечень ПДн для каждой категории субъектов ПДн применительно к целям обработки, а также перечень действий с персональными данными перечислены в Методических указаниях АО «РАЗВИТИЕ» «Перечень обрабатываемых персональных данных».
    </div>

    <div class="mt60" />
    <div class="center text-center half-width half-width-center w500">4. Порядок и условия обработки ПДн в Обществе, соблюдение конфиденциальности персональных данных</div>
    <div class="indent justify">
      4.1 Общество осуществляет обработку ПДн на законной и справедливой основе.
    </div>
    <div class="indent justify">
      4.2 Обработка ПДн в Обществе ограничивается достижением конкретных, заранее определенных и законных целей. Содержание и объем обрабатываемых ПДн соответствуют заявленным целям обработки. Обрабатываемые персональные данные не являются избыточными по отношению к заявленным целям их обработки.
    </div>
    <div class="indent justify">
      4.3 Не допускается обработка ПДн, несовместимая с целями сбора ПДн.
    </div>
    <div class="indent justify">
      4.4 Не допускается обработка ПДн по истечению установленных сроков обработки.
    </div>
    <div class="indent justify">
      4.5 Не допускается объединение баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой.
    </div>
    <div class="indent justify">
      4.6 При обработке ПДн обеспечиваются их точность, достаточность, а в необходимых случаях и актуальность по отношению к целям обработки ПДн. Общество принимает необходимые меры либо обеспечивает их принятие по удалению или уточнению неполных, или неточных данных.
    </div>
    <div class="indent justify">
      В случае подтверждения факта неточности персональных данных данные подлежат актуализации оператором. При выявлении неправомерности обработки ПДн, обработка должна быть прекращена.
    </div>
    <div class="indent justify">
      4.7 Общество может осуществлять обработку ПДн, разрешенных субъектом ПДн для распространения, при соблюдении положений ст. 10.1 Закона и при наличии согласия субъекта ПДн, которое оформляется отдельно от иных согласий субъекта ПДн на обработку его ПДн.
    </div>
    <div class="indent justify">
      4.8 Общество не осуществляет обработку специальных категорий ПДн за исключением случаев, предусмотренных частью 2 статьи 10 Закона.
    </div>
    <div class="indent justify">
      4.9 Общество не осуществляет обработку биометрических ПДн.
    </div>
    <div class="indent justify">
      4.10 При сборе ПДн, в том числе посредством информационно-телекоммуникационной сети «Интернет», и при осуществлении хранения ПДн Общество использует базы данных, находящиеся на территории Российской Федерации.
    </div>
    <div class="indent justify">
      4.11 Общество не осуществляет обработку ПДн в целях продвижения товаров, работ и услуг Общества на рынке (рекламных целях).
    </div>
    <div class="indent justify">
      4.12 В Обществе не осуществляется принятие решений, порождающих юридические последствия в отношении субъекта ПДн или иным образом затрагивающих его права и законные интересы, на основании исключительно автоматизированной обработки ПДн.
    </div>
    <div class="indent justify">
      4.13 Общество может осуществлять обработку ПДн по поручению других операторов.
    </div>
    <div class="indent justify">
      4.14 С согласия субъекта ПДн Общество может поручить обработку ПДн иным лицам, осуществляющим обработку ПДн. Передача и поручение обработки ПДн третьим лицам осуществляется при условии получения согласия субъекта ПДн и при наличии договора поручения на обработку персональных данных, соответствующего требованиям Закона. Ответственность перед субъектом ПДн за третьих лиц, которым поручена обработка Пдн, несет Общество.
    </div>
    <div class="indent justify">
      4.15 Доступ к персональным данным может быть предоставлен субъекту персональных данных по его соответствующему запросу в адрес Общества.
    </div>
    <div class="indent justify">
      4.16 Порядок реагирования на запросы/обращения субъектов персональных данных и их представителей, уполномоченных органов по поводу неточности персональных данных, неправомерности их обработки, отзыва согласия и доступа субъекта персональных данных к своим данным, а также соответствующие формы запросов/обращений изложены в Положении об обработке персональных данных АО «РАЗВИТИЕ».
    </div>
    <div class="indent justify">
      4.17 Общество вправе передавать персональные данные органам дознания и следствия, иным уполномоченным органам по основаниям, предусмотренным действующим законодательством Российской Федерации.
    </div>
    <div class="indent justify">
      4.18 Общество осуществляет обработку ПДн с использованием средств автоматизации и без их использования. При этом Общество выполняет все требования к автоматизированной и неавтоматизированной обработке ПДн, предусмотренные Законом и принятыми в соответствии с ним нормативными правовыми актами.
    </div>
    <div class="indent justify">
      В Обществе разработаны и введены в действие ЛНА и документы, устанавливающие порядок обработки и обеспечения безопасности ПДн, которые обеспечивают соответствие требованиям Закона и принятых в соответствии с ним нормативных правовых актов.
    </div>
    <div class="indent justify">
      4.19 Общество не осуществляет трансграничную передачу персональных данных.
    </div>
    <div class="indent justify">
      4.20 Обрабатываемые персональные данные подлежат уничтожению либо обезличиванию по достижении целей обработки или, в случае утраты необходимости, в достижении этих целей, а также по обращению субъекта персональных данных, если иное не предусмотрено федеральным законодательством.
    </div>

    <div class="mt60" />
    <div class="center text-center half-width half-width-center w500">5. Права и обязанности субъектов ПДн, обрабатываемых Обществом</div>
    <div class="indent justify">
      5.1 Субъект ПДн вправе обжаловать действия или бездействие Общества в уполномоченный орган по защите прав субъектов ПДн или в судебном порядке, в случае если он считает, что обработка его ПДн осуществляется с нарушением требований Закона или иным образом нарушает его права и свободы.
    </div>
    <div class="indent justify">
      5.2 Субъект ПДн имеет право на защиту своих прав и законных интересов, в том числе на возмещение убытков и (или) компенсацию морального вреда в судебном порядке.
    </div>
    <div class="indent justify">
      5.3 Субъект ПДн имеет право на получение информации, касающейся обработки его ПДн, за исключением случаев, предусмотренных ч. 8 ст. 14 Закона, когда такое право может быть ограничено в соответствии с федеральными законами.
    </div>
    <div class="indent justify">
      Для получения указанной информации субъект ПДн может отправить письменный запрос по месту нахождения Общества в порядке, установленном ч. 3 – 5 ст.14 Закона.
    </div>
    <div class="indent justify">
      5.4 Субъект персональных данных в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, вправе требовать от Общества прекращения обработки персональных данных, их уточнения, блокирования или уничтожения, а также принимать предусмотренные Законом меры по защите своих прав.
    </div>

    <div class="mt60" />
    <div class="center text-center half-width half-width-center w500">6. Права и обязанности Оператора</div>
    <div class="indent justify">
      6.1 Общество, являясь Оператором, уведомило уполномоченный орган по защите прав субъектов ПДн о своем намерении осуществлять обработку ПДн и внесено в Реестр операторов ПДн.
    </div>
    <div class="indent justify">
      6.2 Общество выполняет обязанности Оператора при сборе персональных данных, предусмотренные ст. 18 Закона при сборе ПДн.
    </div>
    <div class="indent justify">
      6.3 Общество обязано прекратить обработку ПДн в следующих случаях:
    </div>
    <div class="indent justify">
      &mdash; по достижении сроков и (или) целей обработки, либо в случае утраты необходимости в достижении этих целей;
    </div>
    <div class="indent justify">
      &mdash; по требованию субъекта ПДн, если обрабатываемые в Обществе персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки;
    </div>
    <div class="indent justify">
      &mdash; в случае выявления неправомерной обработки ПДн, если обеспечить правомерность обработки ПДн невозможно;
    </div>
    <div class="indent justify">
      &mdash; в случае отзыва субъектом ПДн согласия на обработку его ПДн (если персональные данные обрабатываются Обществом на основании согласия субъекта ПДн);
    </div>
    <div class="indent justify">
      &mdash; устранения причины, вследствие которых осуществлялась обработка ПДн, если иное не установлено федеральным законом;
    </div>
    <div class="indent justify">
      &mdash; в случае ликвидации Общества.
    </div>
    <div class="indent justify">
      6.4 Общество в соответствии со ст. 18.1 Закона определило состав и перечень мер, необходимых и достаточных для обеспечения выполнения обязанностей, предусмотренных Законом и принятыми в соответствии с ним нормативными правовыми актам, к которым, в частности, относятся следующие меры:
    </div>
    <div class="indent justify">
      &mdash; Приказом Генерального директора Общества назначен Ответственный за организацию обработки ПДн;
    </div>
    <div class="indent justify">
      &mdash; изданы локальные акты по вопросам обработки и обеспечения безопасности ПДн, а также локальные акты, устанавливающие процедуры, направленные на предотвращение и выявление нарушений законодательства РФ, проведение внутреннего контроля соблюдения требований к обработке ПДн и устранение последствий нарушений;
    </div>
    <div class="indent justify">
      &mdash; утвержден перечень информационных систем, в которых осуществляется обработка ПДН;
    </div>
    <div class="indent justify">
      &mdash; утвержден перечень лиц, которым необходим доступ в информационные системы персональных данных для выполнения должностных обязанностей и лиц, осуществляющих неавтоматизированную обработку ПДн;
    </div>
    <div class="indent justify">
      &mdash; осуществляется внутренний контроль и (или) аудит соответствия обработки персональных данных Закону и принятым в соответствии с ним нормативным правовым актам, требованиям к защите персональных данных, настоящей Политике и иным локальным актам Общества по вопросам обработки ПДн;
    </div>
    <div class="indent justify">
      &mdash; проводится оценка вреда, который может быть причинен субъектам ПДн в случае нарушения Закона и соотношения указанного вреда и принимаемых мер, направленных на обеспечение выполнения обязанностей Оператора, предусмотренных настоящим Федеральным законом;
    </div>
    <div class="indent justify">
      &mdash; работники, непосредственно осуществляющие обработку ПДн, ознакомлены с положениями законодательства Российской Федерации о ПДн, в том числе с требованиями к защите ПДн, документами, определяющими политику оператора в отношении обработки персональных данных, локальными актами по вопросам обработки персональных данных. Проводится внутреннее обучение указанных работников;
    </div>
    <div class="indent justify">
      &mdash; применены организационные и технические меры по обеспечению безопасности ПДн при их обработке в информационных системах ПДн, необходимых для выполнения требований к защите ПДн, исполнение которых обеспечивает установленные Правительством Российской Федерации уровни защищенности ПДн<sup>1</sup>
    </div>
    <div class="indent justify">
      &mdash; обеспечен неограниченный доступ к документу, определяющему Политику Общества в отношении обработки персональных данных, к сведениям о реализуемых требованиях к защите ПДн;
    </div>
    <div class="indent justify">
      &mdash; соблюдаются обязательные требования по обеспечению записи, систематизации, накоплению, хранению, уточнению (обновлению, изменению), извлечению ПДн граждан Российской Федерации с использованием баз данных, находящихся на территории Российской Федерации, при сборе ПДн;
    </div>
    <div class="indent justify">
      &mdash; реализованы требования, установленные Постановлением Правительства РФ от 15 сентября 2008 г. №687 «Об утверждении Положения об особенностях обработки ПДн, осуществляемой без использования средств автоматизации»;
    </div>
    <div class="indent justify">
      &mdash; обеспечивается направление информации о компьютерных инцидентах, повлекших неправомерную передачу (предоставление, распространение, доступ) персональных данных и уведомление о результатах внутреннего расследования путем заполнения уведомления, содержащегося на официальном сайте Роскомнадзора в информационно-телекоммуникационной сети "Интернет" в сроки, установленные приказом ФСБ № 77 от 13.02.2023 г. «О порядке взаимодействия операторов с государственной системой обнаружения предупреждения и ликвидации последствий компьютерных атак на информационные ресурсы Российской Федерации».
    </div>

    <div class="mt24" />
    <div>
      <hr style="width: 35%; margin: 0; height: 1px"> <br>
      <sup>1</sup> Постановление Правительства Российской Федерации от 1 ноября 2012 г. № 1119 «Об утверждении Требований к защите персональных данных при их обработке в информационных системах персональных данных».
    </div>

    <div class="mt60" />
    <div class="center text-center half-width half-width-center w500">7. Нормативные ссылки</div>
    <div class="indent justify">
      &mdash; Федеральным законом от 27 июля 2006 г. №152-ФЗ «О персональных данных»;
    </div>
    <div class="indent justify">
      &mdash; Постановление Правительства РФ от 15 сентября 2008 г. №687 «Об утверждении Положения об особенностях обработки ПДн, осуществляемой без использования средств автоматизации»;
    </div>
    <div class="indent justify">
      &mdash; Постановление Правительства РФ от 01.11.2012 N 1119 "Об утверждении требований к защите персональных данных при их обработке в информационных системах персональных данных";
    </div>
    <div class="indent justify">
      &mdash; Конвенция Совета Европы №108 о защите личности в связи с автоматической обработкой ПДн;
    </div>

    <div class="mt60" />
    <div class="center text-center half-width half-width-center w500">8. Порядок актуализации и внесения изменений</div>
    <div class="indent justify">
      8.1 Политика подлежит актуализации в случаях:
    </div>
    <div class="indent justify">
      &mdash; изменения законодательства РФ о ПДн;
    </div>
    <div class="indent justify">
      &mdash; выявления несоответствий, затрагивающих обработку и (или) защиту ПДн, по результатам контроля выполнения требований по обработке и (или) защите ПДн;
    </div>
    <div class="indent justify">
      &mdash; Изменения процессов обработки ПДн (состав обрабатываемых ПДн, цели и способы обработки ПДн, сроки обработки ПДн и т.д.).
    </div>
    <div class="indent justify ml32">
      8.2 Ответственным за актуализацию и изменение Политики является Ответственный за организацию обработки ПДн Общества. До фактического изменения процессов обработки ПДн, подлежащих отражению в Политике, планируемые изменения предоставляются специалисту по информационной безопасности Общества для оценки применимости изменений в условиях текущей системы защиты ПДн Общества. После согласования специалистом по информационной безопасности, новая редакция Политики об обработке ПДн проходит процедуру согласования и утверждения в установленном в Обществе порядке.
    </div>

    <div class="mt60" />
    <div class="center text-center half-width half-width-center w500">9. Контроль и ответственность за исполнением Политики</div>
    <div class="indent justify">
      9.1 Контроль выполнения требований Политики
    </div>
    <div class="indent justify">
      Контроль за соблюдением требований Политики осуществляет ответственный за организацию обработки ПДн в Обществе.
    </div>
    <div class="indent justify">
      9.2 Ответственность работников.
    </div>
    <div class="indent justify">
      Работники Общества, осуществляющие обработку персональных данных, несут дисциплинарную и иную предусмотренную законодательством ответственность за несоблюдение требований Политики.
    </div>
    <div class="indent justify">
      9.3 Наложение дисциплинарных взысканий за неисполнение или ненадлежащее исполнения требований настоящей Политики проводится в соответствии с нормами трудового законодательства Российской Федерации и в соответствии с действующими ЛНА Общества.
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>
