export enum PermissionsEnum {
  /*
    Просмотр реестра проектов
   */
  SupplierProjectList = 'supplier.project.list',

  /*
  Просмотр карточки проекта
 */
  SupplierProjectView = 'supplier.project.view',

  /*
    Подача предложения по этапу
  */
  SupplierProjectStageOfferCreate = 'supplier.project.stage.offer.create',

  /*
    Просмотр и редактирование анкеты квалификации СБ
  */
  SupplierKycEdit = 'supplier.kyc.edit',

  /*
    Просмотр и скачивание предложений по этапу
  */
  SupplierProjectStageOfferView = 'supplier.project.stage.offer.view',

  /*
    Просмотр детальной информации о компании
  */
  SupplierProfileView = 'supplier.profile.view',

  /*
    Редактирование информации о контрагенте (все поля)
  */
  SupplierProfileEdit = 'supplier.profile.edit',

  /*
    Редактирование профиля сотрудника контрагента
  */
  SupplierUsersProfileEdit = 'supplier.users.profile.edit',

  /*
    Просмотр контактов сотрудников контрагентов в ЛК КА
  */
  SupplierUsersView = 'supplier.users.view',

  /*
    Создание / удаление / редактирование сотрудников контрагента в ЛК КА
  */
  SupplierUsersEdit = 'supplier.users.edit',

  /*
    Отправка приглашений в ЛК сотрудникам контрагента
  */
  SupplierUsersInviteSend = 'supplier.users.invite.send',

  // последние 2 пермишена под вопросом, надо уточнить у аналитики
  /*
    Запрос на восстановление пароля
  */
  SupplierUsersResetPassword = 'supplier.users.reset_password',

  /*
    Настройка уведомлений
  */
  NotificationSettingEdit = 'notification.setting.edit',
}
