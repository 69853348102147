import { ProjectCountersEnum } from '@/views/srm/projects/api/models/enums/projectCounters.enum';

export const TAB_NAMES = {
  [ProjectCountersEnum.Active]: 'Активные',
  [ProjectCountersEnum.All]: 'Все',
  [ProjectCountersEnum.Finished]: 'Завершенные',
  [ProjectCountersEnum.Confirmed]: 'Участие подтверждено',
  [ProjectCountersEnum.Invited]: 'Приглашен',
  [ProjectCountersEnum.SubmittedOffer]: 'Подано предложение',
}
