<script setup lang="ts">
import Filter from '@/common/components/filters/Filter.vue';
import { IconPathEnum } from '@/ui-kit/enums/iconPath.enum';
import TextField from '@/ui-kit/components/TextField.vue';
import { TextFieldTypesEnum } from '@/ui-kit/enums/textFieldTypes.enum';
import IMask from 'imask';
import { ref, watch } from 'vue';
import type { INumberPeriod } from '@/common/models/filters/number-period';
import type { INumberPeriodFilterParams } from '@/common/models/filters/filter-model';

const props = withDefaults(defineProps<INumberPeriodFilterParams>(), {
  confirmButtonText: 'Применить',
  width: 247,
});

const isOpened = ref<boolean>(false);
const viewValue = ref<string>('');

const fromMask = ref<IMask.AnyMaskedOptions>(null);
const toMask = ref<IMask.AnyMaskedOptions>(null);
const fromValue = ref<number>(null);
const toValue = ref<number>(null);
initDefaults();

function placeholderFrom(): string {
  return `от  ${props.min}`;
}

function placeholderTo(): string {
  return `До  ${props.max}`;
}

function fromValueFocusChanged(value: boolean) {
  if (!value) {
    if (fromValue.value) {
      toMask.value['min'] = +fromValue.value;
    } else {
      toMask.value['min'] = props.min;
    }
  }
}

function toValueFocusChanged(value: boolean) {
  if (!value) {
    if (toValue.value) {
      fromMask.value['max'] = +toValue.value;
    } else {
      fromMask.value['max'] = props.max;
    }
  }
}

function confirm(): void {
  isOpened.value = false;
  const newValue = {
    start: fromValue.value ? +fromValue.value : null,
    end: toValue.value ? +toValue.value : null,
  };
  emits('update:modelValue', newValue);
  setViewValue(newValue);
}

function openChanged(open: boolean): void {
  if (!open) {
    initDefaults();
  }
}

function initDefaults() {
  fromMask.value = {
    mask: Number,
    min: props.min,
    max: props.max,
    thousandsSeparator: '',
    radix: '.',
    scale: 0,
  };

  toMask.value = {
    mask: Number,
    min: props.min,
    max: props.max,
    thousandsSeparator: '',
    radix: '.',
    scale: 0,
  };
  fromValue.value = props.modelValue?.start || null;
  toValue.value = props.modelValue?.end || null;
  setViewValue(
    props.modelValue || {
      start: null,
      end: null,
    },
  );
}

function setViewValue(value: INumberPeriod) {
  if (value.start || value.end) {
    viewValue.value = `${value.start || '...'} — ${value.end || '...'}`;
  } else {
    viewValue.value = '';
  }
}

function viewValueUpdated(value: string): void {
  if (!value) {
    fromValue.value = null;
    toValue.value = null;
    const newValue = {
      start: fromValue.value ? +fromValue.value : null,
      end: toValue.value ? +toValue.value : null,
    };
    emits('update:modelValue', newValue);
  }
}

watch(
  () => props.modelValue,
  (value) => {
    setViewValue(
      value || {
        start: null,
        end: null,
      },
    );
  },
);

const emits = defineEmits<{
  (e: 'update:modelValue', value?: INumberPeriod): void;
}>();
</script>

<template>
  <Filter
    v-model:opened="isOpened"
    :label="label"
    :parent-ref="parentRef"
    :icon="IconPathEnum.NavigationArrowDown20PxSvg"
    :view-value="viewValue"
    :disabled="disabled"
    @update:opened="openChanged($event)"
    @update:view-value="viewValueUpdated($event)"
  >
    <div
      class="d-flex flex-column"
      :style="{ width: width + 'px' }"
    >
      <div class="mm-body-medium-m mm-text-no-wrap mb16">{{ title }}</div>
      <div
        v-if="isOpened"
        class="d-flex align-items-center mb24"
      >
        <TextField
          v-model="fromValue"
          container-class="text-field-no-padding-40"
          class="field-width"
          :placeholder="placeholderFrom()"
          :type="TextFieldTypesEnum.Number"
          :mask-options="fromMask"
          icon-disabled
          size="small"
          @focus-change="fromValueFocusChanged($event)"
        />
        <div class="dash mm-body-regular-s">—</div>
        <TextField
          v-model="toValue"
          container-class="text-field-no-padding-40"
          class="field-width"
          :placeholder="placeholderTo()"
          :type="TextFieldTypesEnum.Number"
          :mask-options="toMask"
          icon-disabled
          size="small"
          @focus-change="toValueFocusChanged($event)"
        />
      </div>
      <button
        class="btn btn-primary d-flex justify-content-center"
        @click="confirm"
      >
        {{ confirmButtonText }}
      </button>
    </div>
  </Filter>
</template>

<style scoped lang="scss">
@import '@styles/base/common/variables';

.field-width {
  width: 113.5px;
}

.dash {
  color: $text-disabled;
  padding: 0 5px;
}
</style>
