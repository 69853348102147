<script setup lang="ts">
import { IconPathEnum } from '@/ui-kit/enums/iconPath.enum';
import SvgIcon from '@/ui-kit/components/SvgIcon.vue';
import { ref, watch } from 'vue';
import { useClickOutside } from '@/ui-kit/composables/useClickOutside';

const props = withDefaults(
  defineProps<{
    label: string;
    icon?: IconPathEnum;
    rotateIconWhenOpened?: boolean;
    iconRotated?: boolean;
    disabled?: boolean;
    opened?: boolean;
    viewValue?: string;
    parentRef: unknown;
  }>(),
  {
    rotateIconWhenOpened: true,
    iconRotated: false,
    disabled: false,
    active: false,
    opened: false,
    viewValue: '',
  },
);

const emit = defineEmits(['update:opened', 'update:viewValue']);

const isOpened = ref<boolean>(props.opened);
const alignLeft = ref<boolean>(false);
const menu = ref(null);
const wrapperRef = ref<HTMLDivElement>();

function open(): void {
  if (props.disabled) {
    return;
  }

  checkOverflow();
  isOpened.value = !props.opened;
  emit('update:opened', isOpened.value);
}

function checkOverflow() {
  const parentNativeElement = props.parentRef?.$el || props.parentRef;
  const menuNativeElement = menu.value;
  const containerRect = parentNativeElement.getBoundingClientRect();
  const dropdownRect = menuNativeElement.getBoundingClientRect();
  alignLeft.value = dropdownRect.left < containerRect.left;
}

function stopPropagation(event: Event) {
  event.stopPropagation();
}

function getLabel(): string {
  if (props.viewValue) {
    return `${props.label}:`;
  } else {
    return props.label;
  }
}

function clear(event: Event): void {
  event.stopPropagation();
  emit('update:viewValue', '');
}

useClickOutside(wrapperRef, () => {
  emit('update:opened', false);
  isOpened.value = false;
});

watch(
  () => props.opened,
  (value) => {
    isOpened.value = value;
  },
  { immediate: true },
);
</script>

<template>
  <div
    ref="wrapperRef"
    class="filter d-flex align-items-center"
    :class="{
      'filter-active': viewValue,
      'filter-opened': isOpened && !viewValue,
      'filter-disabled': disabled
    }"
    @click="open"
  >
    <div class="d-flex align-items-center">
      <div
        class="filter-label mm-body-regular-s"
        :class="{ mr4: icon || viewValue }"
      >
        {{ getLabel() }}
      </div>
      <div
        v-if="viewValue"
        class="filter-value mm-body-regular-s mr4"
      >
        {{ viewValue }}
      </div>
    </div>
    <SvgIcon
      v-if="icon && (!viewValue || disabled)"
      class="filter-icon"
      :wrapper-classes="disabled ? 'icon--inactive' : ''"
      :class="{ 'filter-icon-rotate': (isOpened && rotateIconWhenOpened) || iconRotated }"
      :src="icon"
    />
    <SvgIcon
      v-if="viewValue && !disabled"
      class="filter-icon"
      wrapper-classes="icon--white"
      :src="IconPathEnum.NavigationClose20PxSvg"
      @click="clear"
    />
    <div
      ref="menu"
      class="menu"
      :class="{ 'menu-opened': isOpened, 'menu-left': alignLeft && isOpened }"
      @click="stopPropagation"
    >
      <slot />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@styles/base/common/variables';

.filter {
  padding: 7px 7px 7px 15px;
  border: 1px solid $gray-200;
  border-radius: 6px;
  cursor: pointer;
  position: relative;

  &-icon {
    width: 20px;
    height: 20px;
    transition: all 300ms ease-out;

    &-rotate {
      transform: rotate(180deg);
    }
  }

  &-active {
    background: $text-dark-green;

    .filter-label {
      color: white !important;
      opacity: 0.8;
    }
  }

  &-opened {
    background: $light-gray;
  }

  &-label {
    white-space: nowrap;
    color: $text-dark-green;
  }

  &-value {
    color: white;
  }

  &-disabled {
    border: 1px solid $gray-200;
    background: $light-gray;

    .filter-label {
      color: $text-disabled !important;
    }

    .filter-value {
      color: $text-disabled;
    }

    .filter-icon {
      rect {
        fill: $text-disabled;
      }
    }
  }
}

.menu {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  box-shadow: -2px 2px 16px 0px #0319121f;
  padding: 0;
  visibility: hidden;
  z-index: 999;
  background: $white;
  cursor: default;
  border-radius: 8px;

  &-opened {
    visibility: visible;
    padding: 16px;
    top: 40px;
  }

  &-left {
    left: 0;
    transform: translateX(0);
  }
}
</style>
