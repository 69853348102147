import { computed } from 'vue';
import type { Ref } from 'vue';
import type { ITreeViewItem, IUseTreeView } from '@/ui-kit/models/treeView.model';
import { TreeHelperUtils } from '@/ui-kit/utils/treeHelper.util';
export function useTreeView(model: Ref<ITreeViewItem>, checkable = false): IUseTreeView {
  const isChildren = computed<boolean>(() => !!model.value.children?.length);
  const isSelectedChild = computed<boolean>(
    () =>
      checkable &&
      isChildren.value &&
      !!TreeHelperUtils.searchNodeByFilter<ITreeViewItem>(
        model.value.children,
        'children',
        (child) => child.selected,
      ),
  );
  const isNotSelectedChild = computed<boolean>(
    () =>
      !!(
        checkable &&
        isChildren.value &&
        TreeHelperUtils.searchNodeByFilter<ITreeViewItem>(
          model.value.children,
          'children',
          (child) => !child.selected,
        )
      ),
  );
  const isPartiallyChildrenSelected = computed<boolean>(
    () => checkable && isChildren.value && isSelectedChild.value && isNotSelectedChild.value,
  );

  function changeChildrenSelected(selected: boolean): void {
    TreeHelperUtils.changeNodeValue<ITreeViewItem>(
      model.value.children,
      'children',
      (child) => (child.selected = selected),
    );
  }

  function expandChildren(expand: boolean): void {
    TreeHelperUtils.changeNodeValue<ITreeViewItem>(
      model.value.children,
      'children',
      (child) => (child.opened = expand),
    );
  }

  return {
    isChildren,
    isSelectedChild,
    isNotSelectedChild,
    isPartiallyChildrenSelected,

    changeChildrenSelected,
    expandChildren,
  };
}
