export enum SupplierStatusEnum {
  Registered = 'REGISTERED',
  Draft = 'DRAFT',
  Invited = 'INVITED'
}

export enum SupplierStatusDefinitionEnum {
  Registered = 'Зарегистрирован',
  Draft = 'Черновик',
  Invited = 'Приглашен'
}
