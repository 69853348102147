<template>
  <component
    :is="currentComponent"
    :class="wrapperClasses"
  />
</template>

<script lang="ts" setup>
/**
 * Данный компонент является оберткой https://github.com/shrpne/vue-inline-svg
 * Для src используется базовая директория иконок src/shared/icons
 * Необходимо указать поддиректорию и название иконки
 */
import type { IconPathEnum } from '@/ui-kit/enums/iconPath.enum';
import { shallowRef, watch } from 'vue';
import { ALL_ICONS_BY_PATH } from '@/ui-kit/constants/allIconsByPath.const';

const props = defineProps<{
  wrapperClasses?: string | Array<string>;
  src: IconPathEnum;
}>();

const currentComponent = shallowRef();

function setIcon() {
  currentComponent.value = ALL_ICONS_BY_PATH.get(props.src);
  if (!currentComponent.value) {
    throw Error(`Иконка по пути ${props.src} не найдена. Необходимо добавить её в константы.`);
  }
  return currentComponent;
}

watch(() => props.src, setIcon, { immediate: true });
</script>
