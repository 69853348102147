export enum InputMasksEnum {
  Phone = '{+7}-(000)-000-00-00',
  PhoneSpaces = '{+7} (000) 000 00 00',
  PhoneSpaced = '{+7} 000 000 00 00',
  RegistrationJuridicalInn = '0000000000',
  RegistrationInn = '000000000000',
  Passport = '0000 000000',
  PassportCode = '000-000',
  Date = '00.00.0000',
}
