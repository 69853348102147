<script lang="ts" setup>
import { IconPathEnum } from '@/ui-kit/enums/iconPath.enum';
import SvgIcon from '@/ui-kit/components/SvgIcon.vue';
import SMSCodeInput from '@/common/components/SMSCodeInput.vue';
import { formatPhoneNumber } from '@/common/utils/formatPhone.util';
import type { IRepeatSMSCodeTimeoutResponse } from '@/views/auth/models/auth.model';
import { computed } from 'vue';

const props = defineProps<{
  phone: string;
  alternativeText?: boolean;
  getSmsTimeoutFn: () => Promise<IRepeatSMSCodeTimeoutResponse>;
  getSmsFn?: () => Promise<void>;
  callbackFn?: (code: string) => Promise<void>;
}>();

defineEmits<{
  (e: 'back');
}>();

const phoneWithPlus = computed(() => {
  return props.phone.startsWith('+') ? props.phone : `+${props.phone}`;
})

</script>

<template>
  <div class="sms-view">
    <button
      class="prev-button"
      @click="$emit('back')"
    >
      <SvgIcon
        class="mm-pagination-arrow left"
        :src="IconPathEnum.NavigationArrowRightSmallSvg"
      />
      Назад
    </button>

    <div class="sms-view--wrapper">
      <h4>Введите проверочный код</h4>
      <slot
        name="message"
        :phone="phone"
      >
        <p v-if="!alternativeText">
          На указанный вами телефон <span>{{ formatPhoneNumber(phoneWithPlus, true) }}</span><br>
          поступит СМС с проверочным кодом
        </p>
        <p v-else>
          На указанный вами телефон <span>{{ formatPhoneNumber(phoneWithPlus, true) }}</span>, при наличии<br>
          зарегистрированного аккаунта, поступит СМС с проверочным кодом
        </p>
      </slot>

      <SMSCodeInput
        :get-sms-timeout-fn="getSmsTimeoutFn"
        :request-fn="callbackFn"
        :get-message-fn="getSmsFn"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@styles/base/common/variables';

.sms-view {
  width: 582px;
  margin: 0 auto;
  padding-top: 40.5px;

  .prev-button {
    color: $text-dark-green;
    font-size: 14px;
    line-height: 20px;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 97px;
    font-weight: 500;
    margin-bottom: 24px;

    .mm-pagination-arrow {
      width: 6px;
      height: 8px;

      &.left {
        margin-right: 16px;
      }
    }
  }

  &--wrapper {
    border-radius: 12px;
    border: 1px solid $gray-200;
    padding: 32px 40px 40px 40px;

    h4 {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      color: $text-black;
      margin-bottom: 16px;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $text-black;
      margin-bottom: 40px;

      span {
        font-weight: 500;
      }
    }
  }
}
</style>
