<template>
  <div
    class="mm-tabs"
    :class="additionalClasses"
  >
    <div
      class="mm-tabs__tabs-wrapper"
      :class="{
        'd-flex justify-content-center': props.centeredHorizontal,
        'mm-tabs__tabs-wrapper-no-border': hideBorder
      }"
    >
      <component
        v-for="(tab, index) in tabList"
        :key="tab?.props?.key ?? index"
        :is="tab"
        :is-active="isMounted && isTabActive(tab?.props?.value)"
        :is-passed="isMounted && isTabPassed(tab?.props?.value)"
        @click="onSelectTab(tab?.props?.value)"
      />
    </div>
    <ContentWrapper :value="internalValue">
      <template
        v-for="(slot, index) in defaultSlots"
        :key="index"
      >
        <component :is="slot" />
      </template>
    </ContentWrapper>
  </div>
</template>

<script lang="ts" setup>
import type { TTabValue } from '@/ui-kit/models/tabs.model';
import { TabsPositionEnum } from '@/ui-kit/enums/tabs/tabs-position.enum';
import { computed, onMounted, onUnmounted, ref, useSlots, type VNode, watch } from 'vue';
import { getCorrectVNodeList } from '@/ui-kit/utils/tabsHelper.util';
import Tab from '@/ui-kit/components/tabs/Tab.vue';
import ContentWrapper from '@/ui-kit/components/content/ContentWrapper.vue';

const props = withDefaults(
  defineProps<{
    // value вкладок
    modelValue?: TTabValue;
    // Позицианирование вкладок
    position?: TabsPositionEnum;
    // Время для автопереключения в мс
    autoPlayTimeout?: number;
    // Выравнивание по горизонтали
    centeredHorizontal?: boolean;
    hideBorder?: boolean;
    isTabActiveFn?: (value: TTabValue) => boolean;
  }>(),
  {
    position: TabsPositionEnum.Top,
    centeredHorizontal: true,
  },
);

const defaultSlots = useSlots()?.default?.() || [];

const timeout = ref<ReturnType<typeof setTimeout> | null>(null);
const isMounted = ref(false);

const emits = defineEmits<{
  (e: 'update:modelValue', value: TTabValue): void;
}>();

const internalValue = ref<TTabValue>();

const tabList = computed<Array<VNode>>(() =>
  getCorrectVNodeList(defaultSlots, Tab),
);

const additionalClasses = computed<Array<string>>(() => [`mm-tabs--${props.position}`]);

function onSelectTab(value: TTabValue): void {
  setInternalValue(value);
  emits('update:modelValue', value);
  if (props.autoPlayTimeout) {
    startTimer();
  }
}

function isTabActive(value: TTabValue): boolean {
  if (props.isTabActiveFn) {
    return props.isTabActiveFn(value);
  }
  return internalValue.value === value;
}

function isTabPassed(value: TTabValue): boolean {
  if (internalValue.value && value) {
    return internalValue.value > value;
  } else {
    return false;
  }
}

function setInternalValue(value: TTabValue): void {
  internalValue.value = value;
}

function getNextTab(): VNode {
  return (
    tabList.value[tabList.value.findIndex((tab) => tab.props?.value === internalValue.value) + 1] ||
    tabList.value[0]
  );
}

function clearTimer(): void {
  if (!timeout.value) {
    return;
  }

  clearTimeout(timeout.value);
  timeout.value = null;
}

function startTimer(): void {
  clearTimer();
  timeout.value = setTimeout(() => {
    onSelectTab(getNextTab()?.props?.value || 0);
  }, props.autoPlayTimeout);
}

function valueExists(value: unknown): boolean {
  return value !== undefined && value !== null;
}

watch(
  () => props.modelValue,
  (value: TTabValue) => setInternalValue(valueExists(value) ? value : null),
  { immediate: true },
);

onMounted(() => {
  isMounted.value = true;
  if (props.autoPlayTimeout) {
    startTimer();
  }
});

onUnmounted(() => {
  clearTimer();
});
</script>

<style scoped lang="scss">
@import '@styles/base/common/variables';

$-content-indent: 10px;

.mm-tabs {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__tabs-wrapper {
    position: relative;
    overflow: auto;
    display: flex;

    &-no-border {
      &:before {
        border-bottom: none !important;
      }
    }

    &::before {
      content: '';
      position: absolute;
    }
  }

  .mm-content-wrapper {
    flex: 1;
  }

  &--top,
  &--bottom {
    .mm-tabs__tabs-wrapper {
      max-width: 100%;

      &::before {
        left: 0;
        right: 0;
      }

      :deep(.mm-tab:not(:first-child)) {
        margin-left: 40px;
      }
    }
  }

  &--top {
    .mm-tabs__tabs-wrapper {
      &::before {
        bottom: 0;
        border-bottom: solid 1px $gray-200;
      }

      :deep(.mm-tab) {
        border-bottom: solid 2px transparent;
        position: relative;
      }

      :deep(.mm-tab--active), :deep(.mm-tab:has(.header-tab-text-active)) {
        &::after {
          content: '';
          width: 100%;
          display: block;
          position: absolute;
          bottom: -2px;
          left: 0;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          background: $tab-green-gradient;
          height: 3px;
        }
      }
    }
  }

  &--bottom {
    flex-direction: column-reverse;

    .mm-tabs__tabs-wrapper {
      &::before {
        border-top: solid 1px $gray-200;
      }

      :deep(.mm-tab) {
        border-top: solid 2px transparent;
      }

      :deep(.mm-tab--active) {
        border-top-color: $link;
      }
    }

    .mm-content-wrapper {
      margin-bottom: $-content-indent;
    }
  }

  &--left,
  &--right {
    flex-direction: row;

    .mm-tabs__tabs-wrapper {
      flex-direction: column;

      &::before {
        top: 0;
        bottom: 0;
      }
    }
  }

  &--left {
    .mm-tabs__tabs-wrapper {
      &::before {
        border-right: solid 1px $gray-200;
        right: 0;
      }

      :deep(.mm-tab) {
        border-right: solid 2px transparent;
        padding-right: $-content-indent;
      }

      :deep(.mm-tab--active) {
        border-right-color: $link;
      }
    }

    .mm-content-wrapper {
      margin-left: $-content-indent;
    }
  }

  &--right {
    flex-direction: row-reverse;

    .mm-tabs__tabs-wrapper {
      &::before {
        border-left: solid 1px $gray-200;
        left: 0;
      }

      :deep(.mm-tab) {
        border-left: solid 2px transparent;
        padding-left: $-content-indent;
      }

      :deep(.mm-tab--active) {
        border-left-color: $link;
      }
    }

    .mm-content-wrapper {
      margin-right: $-content-indent;
    }
  }
}
</style>
