<template>
  <div class="tree-view">
    <template v-if="availableCheckAll && checkable">
      <Checkbox
        class="tree-view-checkbox"
        :model-value="!isNotSelectedChild"
        :partially="isPartiallyChildrenSelected && !model.selected"
        :disabled="disabled"
        @update:model-value="onChangeAll"
      >
        {{ checkableTitle }}
      </Checkbox>
      <hr />
    </template>
    <div class="tree-view-list">
      <template v-if="model.children?.length">
        <TreeViewNode
          v-for="item in model.children"
          :key="item.id"
          :item="item"
          :prev-item="prevLinked"
          :level="0"
          :checkable="checkable"
          :selectable="selectable"
          :outlined="outlined"
          :right-label-key="rightLabelKey"
          @selected-item="onSelectedItem"
          @linked-item="onLinkedItem"
          @expand-changed="expandChanged"
        />
        <div
          v-if="nothingFoundVisible()"
          class="tree-view-list-empty"
        >
          <slot name="empty-data">
            {{ emptyText }}
          </slot>
        </div>
      </template>
      <div
        v-else
        class="tree-view-list-empty"
      >
        <slot name="empty-data">
          {{ emptyText }}
        </slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import TreeViewNode from './TreeViewNode.vue';
import Checkbox from '../Checkbox.vue';
import { TreeHelperUtils } from '../../utils/treeHelper.util';
import { useTreeView } from '../../composables/useTreeView';
import { onMounted, ref, watch } from 'vue';
import type { ITreeViewItem, ITreeViewProps } from '@/ui-kit/models/treeView.model';

const props = withDefaults(defineProps<ITreeViewProps>(), {
  // checkable режим для компонента
  checkable: false,
  // selectable режим для компонента
  selectable: false,
  // Развернуть дерево по умолчанию
  defaultExpandAll: false,
  // отключить компонент
  disabled: false,
  // Заголовок для выбора всего
  checkableTitle: 'Все',
  // Разрешить выбрать все
  availableCheckAll: true,
  // Текст пустого дерева
  emptyText: 'Нет данных',
  // outlined-стилизация дочерних блоков
  outlined: false,
  // Вернет только значения верхнего уровня, если все дети отмечены (перекрывает includeParents)
  getValueOnlyTopNodes: false,
  // Включит в список родителей, если все дети выбраны. Если отключено - вернет только терминальные элементы
  includeParents: false,
});

const emit = defineEmits<{
  /**
   * Для режима checkable вернет массив значений.
   * Для режима selectable вернет одно значение
   */
  (e: 'change', selectedItems: Array<ITreeViewItem> | ITreeViewItem): void;
  (e: 'link', item: ITreeViewItem): void;
  (e: 'update:items', items: Array<ITreeViewItem>);
  (e: 'expandChanged', value: void);
}>();

const model = ref<ITreeViewItem>({ id: 0, children: props.items });

const prevLinked = ref(null);

const { isNotSelectedChild, isPartiallyChildrenSelected, changeChildrenSelected, expandChildren } =
  useTreeView(model, props.checkable);

function onChangeAll(selected: boolean): void {
  changeChildrenSelected(selected);
}

function deselectPrevItem(item: ITreeViewItem): void {
  const selectedItem = TreeHelperUtils.searchNodeByFilter<ITreeViewItem>(
    model.value.children,
    'children',
    (child) => child.selected && item.id != child.id,
  );
  if (selectedItem) {
    selectedItem.selected = false;
  }
}

function onSelectedItem(item: ITreeViewItem): void {
  deselectPrevItem(item);
}

function onLinkedItem(item: ITreeViewItem): void {
  emit('link', item);
  setPrevId(item);
}

function init(): void {
  if (props.defaultExpandAll) {
    expandChildren(true);
  }
}

function onChangeChildren(newChildren: Array<ITreeViewItem>): void {
  if (props.checkable || props.selectable) {
    const filter = (child) => {
      return child.selected && (!props.checkable || props.getValueOnlyTopNodes || props.includeParents || !child.children.length);
    };
    const selectedNodes = TreeHelperUtils.searchNodesByFilter<ITreeViewItem>(
      newChildren,
      'children',
      filter,
      props.getValueOnlyTopNodes,
    );
    emit('change', selectedNodes);
  }
}

function setModel(newItems: Array<ITreeViewItem>): void {
  model.value.children = newItems;
}

function setPrevId(item) {
  if (prevLinked.value === item.name) {
    return;
  }

  prevLinked.value = item.name;

  localStorage.setItem('Uikit_Last_Item', JSON.stringify(prevLinked.value));
}

function nothingFoundVisible(): boolean {
  return !props.items.find((item) => !item.hidden);
}

function expandChanged(): void {
  emit('expandChanged');
}

watch(() => model.value.children, onChangeChildren, { deep: true });
watch(
  () => model.value.children,
  (newValue) => {
    emit('update:items', newValue);
  },
  { deep: true },
);

watch(() => props.items, setModel, { deep: true, immediate: true });

onMounted(() => {
  init();
});

onMounted(() => {
  const storedData = JSON.parse(localStorage.getItem('Uikit_Last_Item')) || 'Actions';
  prevLinked.value = storedData;
});
</script>

<style lang="scss" scoped>
@import '@styles/base/common/variables';

.tree-view {
  &-checkbox {
    padding: 12px;
    transition: background-color 0.2s;
    border-radius: 8px;

    &:hover {
      background-color: $tree-node-bg;
      cursor: pointer;
    }
  }

  hr {
    margin-left: 12px;
    margin-bottom: 0;
    margin-top: 4px;
    color: $tree-view-header-br;
    opacity: 1;
  }

  &-list {
    margin-left: -11px;
    &-empty {
      margin-left: 11px;
    }
    & > :deep(.tree-node) {
      &.is-expanded {
        & > .tree-node-label {
          path {
            fill: $text-dark-green;
          }
        }
      }
    }
  }
}
</style>
