import axios from 'axios';
import type { IDictionary } from '@/common/models/dictionary';
import type { IPagination } from '@/common/models/pagination';
import type { IPageSize, IPageSizeIsActive } from '@/common/models/pageSize.model';
import type { ITimezone } from '@/common/models/timezone.model';
import type { INomenclatureGroups } from '@/common/models/nomenclature-group';
import type { ITermOfPolicy } from '@/common/models/term-of-policy.model';

export function useDictionaryApi(): IDictionaryApi {
  return {
    loadLegalForms: async (params?: IPageSize): Promise<IPagination<IDictionary>> => {
      const res = await axios.get('/api/v1/dictionaries/legal-forms/', {
        params: {
          ...params,
          skipAuth: true,
        },
      });
      return res.data
    },
    loadSupplierCategories: async (params?: IPageSizeIsActive): Promise<IPagination<IDictionary>> => {
      const res = await axios.get('/api/v1/dictionaries/supplier-categories/', {
        params,
      });
      return res.data
    },
    loadTimezones: async (): Promise<Array<ITimezone>> => {
      const res = await axios.get('/api/v1/dictionaries/timezones/');
      return res.data;
    },
    loadAllNomenclatureGroups: async (params: IPageSizeIsActive): Promise<Array<INomenclatureGroups>> => {
      const res = await axios.get('/api/v1/dictionaries/nomenclature-groups/all/', {
        params,
      });
      return res.data;
    },
    loadTermsOfUsage: async (): Promise<Array<ITermOfPolicy>> => {
      const res = await axios.get('/api/v1/dictionaries/supplier-policies/actual/');
      return res.data;
    },
  }
}

export interface IDictionaryApi {
  loadLegalForms: (params?: IPageSize) => Promise<IPagination<IDictionary>>;
  loadSupplierCategories: (params?: IPageSizeIsActive) => Promise<IPagination<IDictionary>>;
  loadTimezones: () => Promise<Array<ITimezone>>;
  loadAllNomenclatureGroups: (params: IPageSizeIsActive) => Promise<Array<INomenclatureGroups>>;
  loadTermsOfUsage: () => Promise<Array<ITermOfPolicy>>;
}
