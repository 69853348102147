import { defineStore } from 'pinia';
import type { ITimezone } from '@/views/srm/account-settings/models/timezone.model';

export const useTimezonesStore = defineStore('timezones', {
    state: () => ({
        timezones: [] as Array<ITimezone>,
    }),
    getters: {
        getTimezones: (state): Array<ITimezone> => state.timezones?.map((timezone: ITimezone) => ({
            ...timezone,
            name: `GMT${timezone.offset > 0 ? '+' : ''}${timezone.offset} ${timezone.name}`,
        })) || [],
    },
    actions: {
        setTimezones(newTimezones: Array<ITimezone>): void {
            this.timezones = newTimezones;
        },
    },
});
