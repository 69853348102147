import axios from 'axios';
import type { ITenant } from '@/common/models/tenant.model';
import { IGNORE_ACCESS_TOKEN } from '@/common/constants/routing.const';

export function useTenantsApi(): ITenantsApi {
  const baseApiUrl = `${import.meta.env.VITE_API_URL}`;
  return {
    getCurrentTenant: async (): Promise<ITenant> => {
      const res = await axios.get(`${baseApiUrl}/contact-info/`, {headers: {[IGNORE_ACCESS_TOKEN]: 'ignore'}});
      return res.data;
    },
  }
}

export interface ITenantsApi {
  getCurrentTenant: () => Promise<ITenant>;
}
