<script setup lang="ts">
import SupplierDataForm from '@/views/auth/pages/registration/components/SupplierDataForm.vue';
import { useDictionaryApi } from '@/common/api/useDictionaryApi';
import { onBeforeMount, ref, watch } from 'vue';
import type { IDictionary } from '@/common/models/dictionary';
import Notificator from '@/ui-kit/services/notificator.service';
import type { ISupplierRegister } from '@/views/auth/pages/registration/models/supplierRegister.model';
import UserDataForm from '@/views/auth/pages/registration/components/UserDataForm.vue';
import type { IUserRegister } from '@/views/auth/pages/registration/models/userRegister.model';
import type { ICreateUser, ICreateUserConfirmRequest } from '@/views/auth/pages/registration/models/createUser.model';
import { ActiveRegistrationScreenEnum } from '@/views/auth/pages/registration/enums/activeRegistrationScreen.enum';
import RegistrationResultAlert from '@/views/auth/pages/registration/components/RegistrationResultAlert.vue';
import type { IPagination } from '@/common/models/pagination';
import type { IPageSize } from '@/common/models/pageSize.model';
import SMSInputView from '@/views/auth/components/SMSInputView.vue';
import { useAuthorizationApi } from '@/views/auth/api/useAuthorizationApi';
import { SMSOtpTypeEnum } from '@/views/auth/api/enums/smsOtpType.enum';
import { useRouter } from 'vue-router';
import { useAuthToken } from '@/common/composables/useAuthToken';
import { useSupplierApi } from '@/common/api/useSupplierApi';
import { useUserApi } from '@/views/srm/account-settings/api/useUserApi';
import { useProfileStore } from '@/stores/profile.store';
import { useTenantStore } from '@/stores/tenant.store';
import type { AxiosError } from 'axios';
import { ErrorTypeEnum } from '@/common/enums/error-type.enum';

const { loadLegalForms } = useDictionaryApi();
const api = useAuthorizationApi();
const { saveToken } = useAuthToken();
const profileApi = useSupplierApi();
const { getCurrent } = useUserApi();

const legalFormsParam = ref<IPageSize>({
  page: 1,
  size: 20,
});
const legalForms = ref<IPagination<IDictionary>>();
const isLegalFormsLoading = ref<boolean>(false);
const supplier = ref<ISupplierRegister>();
const user = ref<IUserRegister>();
const router = useRouter();
const userStore = useProfileStore();
const tenantStore = useTenantStore();
const isLoading = ref<boolean>(false);
const activeScreen = ref<ActiveRegistrationScreenEnum>(ActiveRegistrationScreenEnum.Supplier);
let previousScreen: ActiveRegistrationScreenEnum = null;
const captchaToken = ref<string>();
const tempUserToken = ref<string>();
const captchaResetter = ref<number>(0);
const isUnblockTimerStart = ref<boolean>(false);
const unblockTimerMsValue = ref<number>(0);
const attemptsLeftMsValue = ref<number>(0);

async function loadLegalFormsDictionary(loadMore?: boolean): Promise<void> {
  try {
    if (!loadMore) {
      isLegalFormsLoading.value = true;
      legalForms.value = await loadLegalForms(legalFormsParam.value);
    }
    if (loadMore && !isLegalFormsLoading.value && legalForms.value && legalForms.value.total > legalForms.value.items.length) {
      legalFormsParam.value.page += 1;
      isLegalFormsLoading.value = true;
      const res = await loadLegalForms(legalFormsParam.value);
      legalForms.value.items.push(...res.items);
    }
  } catch (e) {
    Notificator.showDetachedNotification('Произошла непредвиденная ошибка');
  } finally {
    isLegalFormsLoading.value = false;
  }
}

async function setSupplier(data: ISupplierRegister): Promise<void> {
  supplier.value = data;
  try {
    isLoading.value = true;
    activeScreen.value = ActiveRegistrationScreenEnum.User;
  } catch (e) {
    Notificator.showDetachedNotification('Произошла непредвиденная ошибка');
  } finally {
    isLoading.value = false;
  }
}

async function checkSmsInformation() {
  return await api.checkLastOtp(SMSOtpTypeEnum.Registration, user.value?.mobilePhoneNumber);
}

async function repeatSms(): Promise<void> {
  await api.repeatSmsForRegistration(user.value?.mobilePhoneNumber, tempUserToken.value);
}

async function register(smsCode: string): Promise<void> {
  const body: ICreateUserConfirmRequest = {
    user: user.value,
    supplier: supplier.value,
    token: tempUserToken.value,
    smsOtp: smsCode,
  };
  const token = await api.createUserAndSupplier(body);
  saveToken(token);
  const profileInfo = await profileApi.getBaseProfile();
  const current = await getCurrent();
  userStore.setCurrent(current);
  userStore.setProfile(profileInfo);
  await router.push('/');
}

async function onGoToSMSStep(data: IUserRegister): Promise<void> {
  try {
    isLoading.value = true;
    user.value = data;
    captchaToken.value = data.smartToken;
    delete user.value.smartToken;
    const body: ICreateUser = {
      user: user.value,
      supplier: supplier.value,
      smartToken: captchaToken.value,
    };
    tempUserToken.value = (await api.getSmsForRegistration(body)).token;
    activeScreen.value = ActiveRegistrationScreenEnum.Sms;
  }
  catch (error: AxiosError) {
    const dataErrors = error.response.data?.errors;
    const invalidDataError = dataErrors?.find(error => error.code === ErrorTypeEnum.InvalidData);
    const alreadyExistsError = dataErrors?.find((item) => item.code === ErrorTypeEnum.AlreadyExists);
    const conflictingStateError = dataErrors?.find((item) => item.code === ErrorTypeEnum.ConflictingState)
    const forbiddenError = dataErrors?.find((item) => item.code === ErrorTypeEnum.Forbidden)
    const smsLimitExceededError = dataErrors?.find((item) => item.code === ErrorTypeEnum.SmsLimitExceeded)

    captchaResetter.value++;
    if (alreadyExistsError) {
      activeScreen.value = ActiveRegistrationScreenEnum.RegistrationIsNotPossible;
    } else if (invalidDataError) {
      activeScreen.value = ActiveRegistrationScreenEnum.RegistrationIsNotPossible;
    } else if (conflictingStateError) {
      Notificator.showDetachedNotification('Превышена частота запросов, повторите попытку позднее');
    } else if (forbiddenError) {
      const {
        unblockTimerMs,
        attemptsLeft,
      } = await api.checkLastOtp(SMSOtpTypeEnum.Registration, data.mobilePhoneNumber);
      if (unblockTimerMs) {
        unblockTimerMsValue.value = unblockTimerMs;
      }
      if (attemptsLeft >= 0) {
        attemptsLeftMsValue.value = attemptsLeft;
      }
      activeScreen.value = ActiveRegistrationScreenEnum.Sms;

    } else if (smsLimitExceededError) {
      activeScreen.value = ActiveRegistrationScreenEnum.Sms;
    } else {
      Notificator.showDetachedNotification('Произошла непредвиденная ошибка');
    }
  }
  finally {
    isLoading.value = false;
  }
}

function navigationBack(): void {
  captchaToken.value = null;
  activeScreen.value = previousScreen;
}

function backSmsInputView() {
  activeScreen.value = ActiveRegistrationScreenEnum.User;
}

function backFromUserDataForm() {
  activeScreen.value = ActiveRegistrationScreenEnum.Supplier
  isUnblockTimerStart.value = false;
}

watch(() => activeScreen.value, (newValue, previousValue) => {
  previousScreen = previousValue;
});

onBeforeMount(() => {
  loadLegalFormsDictionary();
})
</script>

<template>
  <SupplierDataForm
    v-if="legalForms && activeScreen === ActiveRegistrationScreenEnum.Supplier"
    :legalForms="legalForms.items"
    :supplier="supplier"
    :isLoading="isLoading"
    @proceed="setSupplier"
    @load-more-legal-forms="loadLegalFormsDictionary(true)"
  />

  <UserDataForm
    v-if="legalForms && supplier && activeScreen === ActiveRegistrationScreenEnum.User"
    :isLoading="isLoading"
    :supplier="supplier"
    :legalForms="legalForms.items"
    :user="user"
    disable-autocomplete
    :captcha-reset="captchaResetter"
    :unblock-timer-ms="unblockTimerMsValue"
    :attempts-left="attemptsLeftMsValue"
    :att="unblockTimerMsValue"
    :is-unblock-timer-start="isUnblockTimerStart"
    @proceed="onGoToSMSStep"
    @back="backFromUserDataForm"
    @clear-timer="isUnblockTimerStart = false"
  />

  <SMSInputView
    v-if="activeScreen === ActiveRegistrationScreenEnum.Sms"
    :key="activeScreen"
    :phone="user.mobilePhoneNumber"
    :get-sms-fn="repeatSms"
    :callback-fn="register"
    :get-sms-timeout-fn="checkSmsInformation"
    @back="backSmsInputView"
  />

  <RegistrationResultAlert
    v-if="activeScreen === ActiveRegistrationScreenEnum.RegistrationIsNotPossible"
    :title="'Невозможно зарегистрироваться \nв системе с указанными данными \nконтрагента'"
    :no-account="true"
    @back="navigationBack"
  >
    <div class="mm-body-regular-m mm-color-black">
      Для получения помощи обратитесь в техническую поддержку <br> по электронной почте <a
        class="mm-text-undecorated-link mm-font-500"
        :href="'mailto:' + tenantStore.tenant?.contactEmailExternal"
        target="_blank"
      >
        {{ tenantStore.tenant?.contactEmailExternal || '' }}
      </a>
    </div>
  </RegistrationResultAlert>
</template>

<style scoped lang="scss">
</style>

