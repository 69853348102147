<template>
  <div class="mm-content-wrapper">
    <template
      v-for="(content, index) in tabContentList"
      :key="content?.props?.value"
    >
      <KeepAlive
        v-if="isKeepAlive(content?.props)"
        :key="content?.props?.value"
      >
        <component
          v-if="content?.props?.value === value"
          :is="content"
        />
      </KeepAlive>
      <template v-else>
        <component
          v-if="content?.props?.value === value"
          :key="index"
          :is="content"
        />
      </template>
    </template>
  </div>
</template>

<script lang="ts" setup>
import Content from './Content.vue';
import { getCorrectVNodeList } from '../../utils/tabsHelper.util';
import type { TTabValue } from '@/ui-kit/models/tabs.model';
import { computed, useSlots, type VNode } from 'vue';

defineProps<{
  value: TTabValue;
}>();
const slots = useSlots();

const tabContentList = computed<VNode[]>(() =>
  getCorrectVNodeList(slots?.default?.() || [], Content),
);

function isKeepAlive(props: Record<string, unknown> | null): boolean {
  return (
    !!props &&
    (('isKeepAlive' in props && props['isKeepAlive'] !== false) ||
      ('is-keep-alive' in props && props['is-keep-alive'] !== false))
  );
}
</script>

<style scoped lang="scss">
@import '@styles/base/common/variables';

.mm-content-wrapper {
  width: 100%;
}
</style>
