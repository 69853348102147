<script lang="ts" setup>
import { SmartCaptcha } from 'vue3-smart-captcha';

defineEmits<{
  (e: 'success', token: string): void;
}>();

const captchaSiteKey = import.meta.env.VITE_CAPTCHA_CLIENT_KEY;
</script>

<template>
  <div class="yandex-captcha">
    <SmartCaptcha
      :sitekey="captchaSiteKey"
      :callback="(token) => $emit('success', token)"
      webview
    />
  </div>
</template>
