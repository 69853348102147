<script lang="ts" setup>
import { computed, onBeforeUnmount, ref } from 'vue';
import type { INotificationListItem } from '@/common/models/notifications.model';
import { type INotificationsApi } from '@/common/api/useNotificationsApi';
import { sanitize } from '@/ui-kit/directives/sanitize';
import SvgIcon from '@/ui-kit/components/SvgIcon.vue';
import { IconPathEnum } from '@/ui-kit/enums/iconPath.enum';
import { useProfileStore } from '@/stores/profile.store';

const props = defineProps<{
  notification: INotificationListItem;
  api: INotificationsApi;
}>();

const emits = defineEmits<{
  (e: 'close'): void;
}>();

const isRead = ref<boolean>(false);
const userStore = useProfileStore();

const notificationRoleMessage = computed<string>(() => {
  if (userStore.allRoles?.length === 1) {
    return '';
  }

  const foundRole = userStore.allRoles?.find((role) => role.id === props.notification?.roleId);

  if (!foundRole) {
    return '';
  }

  return `Для роли ${foundRole?.screenConfig?.roleViewValue}`
});

async function readNotification() {
  try {
    if (!isRead.value) {
      await props.api.readNotification(props.notification?.id);
      userStore.setNotificationsCounters(await props.api.getNotificationsCount());
      isRead.value = true;
    }
  }
  catch (error) {
    console.error(error);
  }
}

async function onClose() {
  await readNotification();
  emits('close');
}

onBeforeUnmount(() => {
  if (!isRead.value) {
    props.api.setNotificationAsShown(props.notification.id);
  }
});
</script>

<template>
  <div
    v-if="!!notification"
    class="floating-notification"
  >
    <div class="floating-notification--header">
      {{ notification?.title }}
    </div>

    <div
      class="floating-notification--body"
      v-html="sanitize(notification?.text)"
    />

    <a
      v-if="notification?.buttonText"
      :href="notification?.buttonUrl"
      class="btn btn-primary"
      @click="readNotification"
      @click.middle="readNotification"
      @click.right="readNotification"
    >
      {{ notification?.buttonText }}
    </a>

    <SvgIcon
      :src="IconPathEnum.ActionCloseSvg"
      class="close-icon"
      @click="onClose"
    />

    <p v-if="notificationRoleMessage">{{ notificationRoleMessage }}</p>
  </div>
</template>

<style lang="scss" scoped>
@import '@styles/base/common/variables';

.floating-notification {
  background: $text-white;
  position: relative;

  .close-icon {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: $text-light-green;
    margin-top: 12px;
    margin-bottom: 0;
  }

  &--header {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: $text-black;
    margin-bottom: 12px;
    max-width: 90%;
  }

  &--body {
    color: $text-dark-green;
    font-size: 14px;
    line-height: 20px;

    :deep(span) {
      font-weight: 500;
    }
  }

  .btn {
    margin-top: 12px;
    width: 100%;
    justify-content: center;
    font-weight: 500;
    white-space: normal;
  }
}
</style>
