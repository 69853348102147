import type { IAxiosParams } from '@/common/models/filters/axios-params';
import type { IPagination } from '@/common/models/pagination';
import { getCurrentInstance } from 'vue';
import type { ProjectCountersEnum } from '@/views/srm/projects/api/models/enums/projectCounters.enum';
import type { IProjectsFilter } from '@/views/srm/projects/api/models/filters/projectsFilter';
import type { IProjectsQueryParams } from '@/views/srm/projects/api/models/filters/projectsQueryParams';
import type { IProjectItem } from '@/views/srm/projects/api/models/responses/projectItem.model';
import type { ProjectsFiltersEnum } from '@/views/srm/projects/api/models/enums/projectsFilters.enum';
import type { IProjectFilters } from '@/views/srm/projects/api/models/responses/projectFilters.model';
import type { ILoadCountersRequest } from '@/views/srm/projects/api/models/filters/loadCountersRequest';
import type { IProjectCounters } from '@/views/srm/projects/api/models/responses/projectCounters.model';
import type { TProjectsRequest } from '@/views/srm/projects/api/models/projectsRequest.model';

export function useProjectsApi(): IProjectsApi {
  const baseApiUrl = `${import.meta.env.VITE_API_URL}/supplier/projects`;
  const axios = getCurrentInstance().appContext.config.globalProperties.$axios;

  return {
    loadProjects: async (
      tab: ProjectCountersEnum,
      params?: IAxiosParams<IProjectsFilter, IProjectsQueryParams>,
    ): Promise<IPagination<IProjectItem>> => {
      const body: IProjectsFilter = {
        ...params?.data,
        tab,
      };

      const res = await axios.post(`${baseApiUrl}/list/`, body, {
        params: params?.query,
      });

      return res.data;
    },
    loadFilters: async (data: ProjectsFiltersEnum[]): Promise<IProjectFilters> => {
      const res = await axios.post(`${baseApiUrl}/filters/`, { filters: data });
      return res.data;
    },
    loadCounters: async (): Promise<IProjectCounters> => {
      const res = await axios.get(`${baseApiUrl}/counters/`);
      return res.data;
    },
  };
}

export interface IProjectsApi {
  loadProjects: TProjectsRequest;
  loadCounters: (data: ILoadCountersRequest) => Promise<IProjectCounters>;
  loadFilters: (data: ProjectsFiltersEnum[]) => Promise<IProjectFilters>;
}
