import { onMounted, ref } from 'vue';

export function useUpdate() {
  const updateVersion = ref(0);

  function incrementVersion(): void {
    updateVersion.value++;
  }

  onMounted(() => {
    incrementVersion();
  });

  return {
    updateVersion,
    incrementVersion,
  };
}
