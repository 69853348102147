<script lang="ts" setup>
import BaseVueModal from '@/ui-kit/components/modal/BaseVueModal.vue';
import { defineEmits } from 'vue';
import PersonalDataPolicy from '@/common/components/PersonalDataPolicy.vue';

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'confirm', value: boolean): void;
}>();
</script>

<template>
  <BaseVueModal
    title="Политика обработки персональных данных"
    class="confirm-pd-modal"
    disable-animation
    @cancel="emit('cancel')"
  >
    <div>
      <PerfectScrollbar>
        <PersonalDataPolicy />
      </PerfectScrollbar>
      <button
        class="btn btn-primary mt40"
        @click="$emit('confirm', true)"
      >
        Понятно
      </button>
    </div>
  </BaseVueModal>
</template>

<style lang="scss" scoped>
@import '@styles/base/common/variables';

:global(.confirm-policies-modal .vfm__content.vfm--outline-none .d-flex.flex-column) {
  max-height: 100%;
}

:global(.confirm-pd-modal .vfm__content) {
  width: 836px !important;
}

:global(.confirm-policies-modal .vfm__content.vfm--outline-none .d-flex.flex-column .d-flex.justify-content-between.align-items-start.mb20) {
  margin-bottom: 32px !important;
}

:global(
    .confirm-policies-modal .vfm__content.vfm--outline-none .d-flex.flex-column .mm-headline-3
  ) {
  font-weight: 500;
}

.confirm-policies-modal-wrap {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $text-black;

  ol {
    padding-left: 20px;
  }
}

.ps {
  max-height: 536px;

  &:not(.ps--active-y) {
    padding-right: 0;
  }
}

.btn {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
