import { throttle } from 'lodash-es';
import { ref } from 'vue';
import { ProviderService } from '@/ui-kit/models/providerService.model';
import { DocumentBreakpointsUtils } from '@/ui-kit/utils/documentBreakpoints.util';

export default class WindowWidthManager extends ProviderService {
  static readonly serviceName = 'windowWidthManager';
  private static readonly _throttleTime = 250;
  private static _windowWidth = ref();
  private static _isMobile = ref(false);
  private static _isTablet = ref(false);
  private static _isDesktop = ref(true);
  private static _isLargeDesktop = ref(false);
  private static _isSmallMobile = ref(false);

  constructor() {
    super();
    WindowWidthManager.startObserve();
    WindowWidthManager.setValues();
  }

  private static startObserve() {
    const throttledObserver = throttle(() => {
      this._windowWidth.value = window.innerWidth;
      this.setValues();
    }, this._throttleTime);

    window.addEventListener('resize', throttledObserver);
  }

  private static setValues() {
    this._isMobile.value = DocumentBreakpointsUtils.getIsRenderedMobile();
    this._isLargeDesktop.value = DocumentBreakpointsUtils.getIsRenderLargeDesktop();
    this._isSmallMobile.value = DocumentBreakpointsUtils.getIsRenderedSmallMobile();
    this._isTablet.value = DocumentBreakpointsUtils.getIsRenderedTablet();
    this._isDesktop.value = DocumentBreakpointsUtils.getIsRenderedDesktop();
  }

  public static getAdaptivaRefs() {
    this.setValues();

    return {
      isDesktop: this._isDesktop,
      isLargeDesktop: this._isLargeDesktop,
      isSmallMobile: this._isSmallMobile,
      isTablet: this._isTablet,
      isMobile: this._isMobile,
      windowWidth: this._windowWidth,
    };
  }
}
