<script setup lang="ts">
import { VueFinalModal } from 'vue-final-modal';
import SvgIcon from '@/ui-kit/components/SvgIcon.vue';
import { IconPathEnum } from '@/ui-kit/enums/iconPath.enum';

withDefaults(
  defineProps<{
    title?: string;
    closeText?: string;
    zIndexFn?: () => number;
    disableAnimation?: boolean;
  }>(),
  {
    closeText: 'Закрыть',
  },
);

const emit = defineEmits<{
  (e: 'cancel'): void;
}>();
</script>

<template>
  <VueFinalModal
    class="vue-final-modal"
    :overlay-transition="disableAnimation ? undefined : 'vfm-fade'"
    :content-transition="disableAnimation ? undefined : 'vfm-fade'"
    :z-index-fn="zIndexFn"
  >
    <div class="d-flex flex-column">
      <div class="d-flex justify-content-between align-items-start mb20">
        <h3
          class="mm-headline-3 mb0 base-modal-title"
          v-html="title"
        ></h3>
        <button
          class="btn btn-secondary pt8 pr8 pb8 pl16"
          @click="emit('cancel')"
        >
          {{ closeText }}
          <SvgIcon :src="IconPathEnum.NavigationClose20PxSvg" />
        </button>
      </div>
      <slot />
    </div>
  </VueFinalModal>
</template>

<style lang="scss">
.vue-final-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vue-final-modal
  .d-flex.flex-column
  .d-flex.justify-content-between.align-items-start.mb20
  .mm-headline-3 {
  font-weight: 500;
  line-height: 32px;
}

.btn.btn-secondary {
  font-weight: 500;
}
</style>
