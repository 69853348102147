import { defineStore } from 'pinia';
import type {
  ISupplierBaseInfo, ISupplierProfilePage,
} from '@/views/srm/supplier-profile/api/models/supplierProfilePage.model';
import type { PermissionsEnum } from '@/common/enums/permissions.enum';
import type { IUserProfileData, IUserRole } from '@/views/srm/account-settings/models/userProfileData.model';
import type { ITimezone } from '@/common/models/timezone.model';
import type { SupplierStatusEnum } from '@/common/enums/supplierStatus.enum';
import type { IProfileNotificationsCounters } from '@/common/models/notifications.model';

export const useProfileStore = defineStore('profile', {
  state: () => ({
    profile: null as ISupplierBaseInfo,
    completeProfile: null as ISupplierProfilePage,
    currentUserUuid: '',
    current: null as IUserProfileData,
    currentRoleName: '' as string,
    timezone: {} as ITimezone,
    notificationsCounters: {} as IProfileNotificationsCounters,
  }),
  getters: {
    getProfile: (state): ISupplierBaseInfo => state.profile,
    getCompleteProfile: (state): ISupplierProfilePage => state.completeProfile,
    supplierStatus: (state): SupplierStatusEnum => state.profile?.status,
    getCurrent: (state): IUserProfileData => state.current,
    getKycProcessId: (state): number => state.profile.kycProcessId || null,
    getUuid: (state): string => state.currentUserUuid,
    currentRole: (state): IUserRole => {
      return state.current?.roles
        .find((item) => item.name === state?.currentRoleName);
    },
    getTimezone: (state): ITimezone => {
      return state.timezone;
    },
    allNotificationsCounters: (state): IProfileNotificationsCounters => state.notificationsCounters,
  },
  actions: {
    logout(): void {
      this.profile = this.completeProfile = null;
      this.current = null;
      this.currentUserUuid = null;
      this.currentRoleName = null;
      this.timezone = null;
      this.notificationsCounters = null;
    },
    setProfile(profile: ISupplierBaseInfo): void {
      this.profile = profile;
    },
    setCompleteProfile(completeProfile: ISupplierProfilePage): void {
      this.completeProfile = completeProfile;
    },
    setCurrent(current: IUserProfileData): void {
      this.current = current;
    },
    setKycProcessId(processId: number): void {
      this.profile.kycProcessId = processId;
    },
    setUserUuid(uuid: string): void {
      this.currentUserUuid = uuid;
    },
    setCurrentRoleName(name: string): void {
      this.currentRoleName = name;
      if (!this.current) {
        return;
      }
      this.current.roles = this.current?.roles
        .sort((a, b) => {
          if (a.name === name) return -1;
          if (b.name === name) return 1;
          return 0;
        });
    },
    hasPermission(permissions: Array<PermissionsEnum>): boolean {
      if (!this.current) {
        return false;
      }

      if (!permissions?.length) {
        return true;
      }

      return permissions.some((permission) =>
        (this.current?.roles
          ?.find((role) => role.name === this.currentRole?.name)?.permissions || [])
          ?.includes(permission),
      );
    },
    setTimezone(newTimezone: ITimezone): void {
      this.timezone = newTimezone;
    },
    setNotificationsCounters(counters: IProfileNotificationsCounters): void {
      this.notificationsCounters = counters;
    },
  },
});


