export enum StageSupplierParticipationStatusesEnum {
    AVAILABLE = 'AVAILABLE',
    INVITED = 'INVITED',
    CONFIRMED = 'CONFIRMED',
    SUBMITTED_OFFER = 'SUBMITTED_OFFER',
    NOT_SUBMITTED_OFFER = 'NOT_SUBMITTED_OFFER',
    SELECTED_AS_WINNER = 'SELECTED_AS_WINNER',
    NOT_SELECTED_AS_WINNER = 'NOT_SELECTED_AS_WINNER',
}

export const StatusTranslationsEnum: Record<StageSupplierParticipationStatusesEnum, string> = {
    [StageSupplierParticipationStatusesEnum.AVAILABLE]: 'Доступен для участия',
    [StageSupplierParticipationStatusesEnum.INVITED]: 'Приглашен к участию',
    [StageSupplierParticipationStatusesEnum.CONFIRMED]: 'Участие подтверждено',
    [StageSupplierParticipationStatusesEnum.SUBMITTED_OFFER]: 'Подано предложение',
    [StageSupplierParticipationStatusesEnum.NOT_SUBMITTED_OFFER]: 'Не подано предложение',
    [StageSupplierParticipationStatusesEnum.SELECTED_AS_WINNER]: 'Выбран победителем',
    [StageSupplierParticipationStatusesEnum.NOT_SELECTED_AS_WINNER]: 'Не выбран победителем',
};