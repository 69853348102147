import type { AxiosResponse, AxiosRequestConfig } from 'axios';
import _, { camelCase, isArray, isObject, map, snakeCase } from 'lodash-es';
import { CustomHeadersEnum } from '../enums/customHeaders.enum';
import { IGNORING_RESPONSE_TYPES } from '../constants/namingHelper.const';
import { SearchParamsUtils } from '@/ui-kit/utils/searchParams.util';

export class NamingHelper {
  // TODO: удалить после интеграции с BFF или полноценными сервисами
  public static responseFileChecked(data: string): AxiosResponse | string {
    try {
      return NamingHelper.fromSnakeToCamel(JSON.parse(data));
    } catch (error) {
      return data;
    }
  }

  public static responseChecked(responseItem: AxiosResponse): AxiosResponse {
    if (IGNORING_RESPONSE_TYPES.includes(responseItem.config.responseType)) {
      return responseItem;
    }

    if (
      !(
        responseItem.config?.headers?.[CustomHeadersEnum.DisableResponseTransform] ||
        responseItem.data == null
      )
    ) {
      responseItem.data = NamingHelper.fromSnakeToCamel(responseItem.data);
    }

    return responseItem;
  }

  public static requestChecked(requestItem: AxiosRequestConfig): AxiosRequestConfig {
    if (requestItem.headers?.[CustomHeadersEnum.DisableRequestTransform]) {
      return requestItem;
    }

    if (requestItem.data instanceof URLSearchParams) {
      const parsedURLParams = NamingHelper.fromCamelToSnake(
        Object.fromEntries(requestItem.data.entries()),
      );
      requestItem.data = new URLSearchParams(parsedURLParams);
    } else {
      requestItem.data = NamingHelper.fromCamelToSnake(requestItem.data);
    }

    if (requestItem.params) {
      SearchParamsUtils.deleteEmpty(requestItem.params);
      requestItem.params = NamingHelper.fromCamelToSnake(requestItem.params);
    }

    return requestItem;
  }

  public static fromSnakeToCamel(data: object) {
    if (!data) {
      return {};
    }

    return NamingHelper.transformFunction(data, camelCase);
  }

  public static fromCamelToSnake(data: object) {
    if (!data) {
      return {};
    }

    return NamingHelper.transformFunction(data, this.customSnakeCase);
  }

  private static transformFunction(data: object, transformFunction: (string?: string) => string) {
    if (isArray(data)) {
      return map(data, (v) => NamingHelper.transformFunction(v, transformFunction));
    }

    if (isObject(data)) {
      return _(data)
        .mapKeys((v, k) => transformFunction(k))
        .mapValues((v) => NamingHelper.transformFunction(v, transformFunction))
        .value();
    }

    return data;
  }

  public static customSnakeCase(str: string): string {
    return str.split(/(\d+)/).map(snakeCase).join('');
  }
}
