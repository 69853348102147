import Notificator from '@/ui-kit/services/notificator.service';
import { onMounted, onUnmounted } from 'vue';
import CookieWarning from '@/common/components/CookieWarningComponent.vue'
import { CookieManager } from '@/ui-kit/services/cookie.service';
import { SEEN_COOKIE_WARNING_KEY } from '@/ui-kit/constants/cookies.const';

export function useCookieWarning() {
  function showCookieWarning() {
    const seenWarning = CookieManager.getCookie(SEEN_COOKIE_WARNING_KEY);
    if(!seenWarning) {
       Notificator.showDetachedNotification(CookieWarning, {
        timeout: false,
        toastClassName: 'cookie-warning-notification',
        onClose: () => CookieManager.setCookie(SEEN_COOKIE_WARNING_KEY, 'true'),
      })
    }
  }

  onMounted(() => {
    window.addEventListener('load', showCookieWarning);
  });

  onUnmounted(() => {
    window.removeEventListener('load', showCookieWarning);
  });
}
