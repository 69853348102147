<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    :id="rowCharacteristicId"
    class="mm-product-characteristic-wrapper"
    :class="additionalClasses"
  >
    <div class="mm-product-characteristic-wrapper__label-column">
      <template v-if="labelParts">
        <mark class="mm-product-characteristic-mark">
          <span
            v-for="(_, index) in rowsLength"
            :key="index"
            class="mm-product-characteristic-label"
          >
            {{ dividedLabel[index] }}
          </span>
        </mark>
      </template>

      <mark
        v-else
        class="mm-product-characteristic-mark"
        :class="{ 'pre-label': !!preLabel }"
      >
        <span
          v-if="preLabel"
          class="mm-product-characteristic-pre-label"
          v-html="sanitize(preLabel)"
        />
        <span
          class="mm-product-characteristic-label"
          v-html="sanitize(label)"
        />
      </mark>
    </div>
    <div class="mm-product-characteristic-filler" />
    <div
      class="mm-product-characteristic-wrapper__value-column"
      :class="{ 'mm-product-characteristic-wrapper__value-column-handler': hasHandler }"
    >
      <template v-if="valueParts">
        <mark class="mm-product-characteristic-mark">
          <span
            v-for="(_, index) in rowsLength"
            :key="index"
            class="mm-product-characteristic-value"
          >
            {{ dividedValue[index] }}
          </span>
        </mark>
      </template>
      <mark
        v-else
        class="mm-product-characteristic-mark"
        :class="{ 'full--view': fullView }"
      >
        <span
          :id="itemCharacteristicIdValue"
          class="mm-product-characteristic-value"
          :class="{
            'big-font': bigValueFont,
            green: isHasLink
          }"
          v-html="sanitize(value)"
        />
        <slot name="rowHandler" />
      </mark>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { getDividedText } from '../utils/textDivider.util';
import { sanitize } from '../directives/sanitize';

const props = withDefaults(
  defineProps<{
    label: string;
    value: string;
    size?: string;
    highValue?: boolean;
    labelParts?: Array<number>;
    valueParts?: Array<number>;
    isLabelPartsDifferent?: boolean;
    isValuePartsDifferent?: boolean;
    hasHandler?: boolean;
    bigValueFont?: boolean;
    wrapValue?: boolean;
    bigMarginBottom?: boolean;
    preLabel?: string;
    isHasLink?: boolean;
    halfWidthMobile?: boolean;
    rowCharacteristicId?: string;
    itemCharacteristicIdValue?: string;
    fullView?: boolean;
    leftAlign?: boolean;
    halfWidth?: boolean;
  }>(),
  {
    isLabelPartsDifferent: false,
    isValuePartsDifferent: false,
  },
);

const additionalClasses = computed(() => [
  'mm-product-characteristic-item',
  {
    'size--large': !!props.size,
    'high--value': props.highValue,
    wrap: props.wrapValue,
    'big-mb': props.bigMarginBottom,
    'pre-label': props.preLabel,
    'is-link': props.isHasLink,
    'half-width-mobile': props.halfWidthMobile,
    'half-width': props.halfWidth,
    'left-align': props.leftAlign,
  },
]);

const dividedLabel = computed(() =>
  getDividedText(props.label, {
    parts: props.labelParts,
    isPartsSimilar: !props.isLabelPartsDifferent,
  }),
);
const dividedValue = computed(() =>
  getDividedText(props.value, {
    parts: props.valueParts,
    isPartsSimilar: !props.isValuePartsDifferent,
  }),
);
const rowsLength = computed(() => Math.max(dividedLabel.value.length, dividedValue.value.length));
</script>

<style lang="scss" scoped>
@import '@styles/base/common/_variables';

.mm-product-characteristic-mark {
  white-space: normal;
  max-width: 227px;
  background-color: transparent;
  font-size: 14px;
  line-height: 20px;
  margin-left: 0;
  padding: 0;
  text-align: right;
  width: auto;

  &.full--view {
    max-width: 100%;
  }
}

.mm-product-characteristic-wrapper {
  display: flex;
  text-align: center;
  justify-content: space-between;
  margin-bottom: 4px;
  //flex-basis: 460px;
  flex-shrink: 1;
  align-items: baseline;
  position: relative;

  &.half-width {
    .mm-product-characteristic-wrapper__value-column {
      flex-basis: 50%;

      .mm-product-characteristic-mark {
        max-width: unset;
      }
    }
  }

  &.left-align {
    &::before {
      width: 50%;
    }

    .mm-product-characteristic-wrapper__value-column {
      align-items: flex-start;

      .mm-product-characteristic-mark {
        text-align: left;

        .mm-product-characteristic-value {
          padding-left: 0;
        }
      }
    }
  }

  &.big-mb {
    margin-bottom: 12px;
  }

  &.is-link {
    .mm-product-characteristic-mark {
      .mm-product-characteristic-value {
        :deep(a) {
          color: $link;
          text-decoration: none;
        }
      }
    }
  }

  &.wrap {
    .mm-product-characteristic-wrapper__label-column {
      flex: unset;
    }

    .mm-product-characteristic-wrapper__value-column {
      flex: unset;
    }

    .mm-product-characteristic-mark {
      max-width: unset;

      &:has(.mm-product-characteristic-label) {
        text-align: left;
      }

      .mm-product-characteristic-label {
        white-space: normal;
        text-align: left;
      }

      .mm-product-characteristic-value {
        white-space: normal;
        text-align: right;
      }
    }
  }

  &.pre-label {
    .mm-product-characteristic-mark {
      .mm-product-characteristic-label {
        padding-left: 12px;
      }
    }
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    top: 10px;
    transform: translateY(4px);
    height: 1px;
    background-image: linear-gradient(to right, $gray-200 60%, transparent 10%);
    background-position: bottom;
    background-size: 6px 40px;
    background-repeat: repeat-x;
    opacity: 1;
  }

  &.size--large {
    margin-bottom: 12px;
  }

  &.high--value {
    .mm-product-characteristic-wrapper {
      align-items: flex-start;

      .mm-product-characteristic-filler {
        transform: translateY(14px);
      }

      .mm-product-characteristic-value {
        padding-top: 4px;
      }
    }
  }

  &__label-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 0;
    position: relative;
  }

  &__value-column {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex: 0;
    flex-basis: 100%;
  }

  &__value-column-handler {
    z-index: 1;
    color: $link;

    .mm-product-characteristic-mark {
      background-color: $text-white;
    }
  }

  .mm-product-characteristic-label,
  .mm-product-characteristic-value {
    flex-shrink: 0;
    line-height: 20px;
    white-space: nowrap;
    background-color: $white;
    z-index: 1;

    a {
      color: $link;
      text-decoration: none;
    }
  }

  .mm-product-characteristic-label {
    color: $text-light-green;
    hyphens: auto;
    padding-right: 4px;
    text-align: left;
    font-size: 14px;
  }

  .mm-product-characteristic-pre-label {
    white-space: normal;
    text-align: left;
    font-weight: 500;
    color: $text-black;
  }

  .mm-product-characteristic-filler {
    width: auto !important;
    transform: translateY(4px);
    height: 1px;
    background-image: linear-gradient(to right, $gray-200 60%, transparent 10%);
    background-position: bottom;
    background-size: 6px 40px;
    background-repeat: repeat-x;
    visibility: hidden;
  }

  .mm-product-characteristic-value {
    position: relative;
    white-space: normal;
    max-width: 227px;
    hyphens: auto;
    overflow-wrap: break-word;
    padding-left: 4px;
    text-align: right;
    font-size: 12px;
    color: $text-dark-green;

    & > a {
      color: $link !important;
      text-decoration: none !important;
    }

    &.big-font {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

@media screen and (max-width: 1599px) {
  .mm-product-characteristic-wrapper {
    .mm-product-characteristic-mark {
      max-width: 184px;
    }

    .mm-product-characteristic-value {
      max-width: 184px;
    }
  }
}

@media screen and (max-width: 1279px) {
  .mm-product-characteristic-wrapper {
    .mm-product-characteristic-mark {
      max-width: 160px;
      flex-direction: column;
    }

    .mm-product-characteristic-value {
      max-width: 160px;
    }
  }
}

@media screen and (max-width: 1023px) {
  .mm-product-characteristic-wrapper__value-column-handler {
    flex-basis: 60%;
  }
}

@media screen and (max-width: 767px) {
  .mm-product-characteristic-wrapper {
    &.half-width-mobile {
      gap: 4px;

      .mm-product-characteristic-wrapper__label-column {
        flex: 50%;
        .mm-product-characteristic-mark {
          width: 100%;
        }
      }

      .mm-product-characteristic-wrapper__value-column {
        flex: 50%;
        .mm-product-characteristic-mark {
          width: 100%;
        }
      }
    }

    &.mm-product-characteristic-value.big-font {
      font-size: 12px;
      line-height: 16px;
    }
  }
}
</style>
