<script setup lang="ts">
import type { IDictionaryValue } from '@/common/models/filters/dictionary-value';
import { ref, watch } from 'vue';
import { useTree } from '@/common/composables/useTree';
import { IconPathEnum } from '@/ui-kit/enums/iconPath.enum';
import Filter from '@/common/components/filters/Filter.vue';
import TextField from '@/ui-kit/components/TextField.vue';
import type { ITreeViewItem } from '@/ui-kit/models/treeView.model';
import TreeView from '@/ui-kit/components/tree-view/TreeView.vue';
import { cloneDeep, isArray } from 'lodash-es';
import type { IDictionaryTreeFilterParams } from '@/common/models/filters/filter-model';

const props = withDefaults(defineProps<IDictionaryTreeFilterParams>(), {
  width: 648,
  confirmButtonText: 'Применить',
});

let itemsCopy: IDictionaryValue[] = [];
let tree = ref<ITreeViewItem[]>([]);
const { parseTree, treeToArray } = useTree();
initDefaults();

const isOpened = ref<boolean>(false);
const contentVisible = ref<boolean>(true);
const viewValue = ref<string>('');
const searchValue = ref<string>('');
const value = ref<unknown[]>(props.modelValue ? [...props.modelValue] : []);
const scrollBar = ref(null);

const emits = defineEmits<{
  (e: 'update:modelValue', value?: unknown[]): void;
}>();

function confirm(): void {
  emits('update:modelValue', [...value.value]);
  setViewValue(value.value);
  isOpened.value = false;
}

function setViewValue(values: unknown[]) {
  if (!values?.length) {
    viewValue.value = '';
  }

  viewValue.value =
    values.length > 1
      ? `${values.length}`
      : props.items
          .filter((item) => values.includes(item[props.valueKey]))
          .map((item) => item[props.viewValueKey])
          .join(', ');
}

function viewValueUpdated(newViewValue: string): void {
  if (!newViewValue) {
    value.value = [];
    viewValue.value = '';
    const clone = cloneDeep(tree.value);
    clearSelected(clone);
    tree.value = clone;
    emits('update:modelValue', []);
  }
}

function openChanged(open: boolean): void {
  if (!open) {
    value.value = props.modelValue ? [...props.modelValue] : [];
    initDefaults();
  }
}

function initDefaults(): void {
  itemsCopy = cloneDeep(props.items);
  itemsCopy.forEach((item) => {
    item['selected'] = !!(props.modelValue || []).find(
      (modelValueItem) => modelValueItem === item[props.valueKey],
    );
  });
  tree.value = parseTree(itemsCopy, props.parseCodeKey, '.');
}

function onValueChanged(selectedItems: Array<ITreeViewItem> | ITreeViewItem): void {
  if (isArray(selectedItems)) {
    value.value = treeToArray(cloneDeep(selectedItems)).map(
      (item: ITreeViewItem) => item[props.valueKey],
    );
  } else {
    value.value = treeToArray(cloneDeep([selectedItems])).map(
      (item: ITreeViewItem) => item[props.valueKey],
    );
  }
}

function searchAndUpdate(tree: ITreeViewItem[], searchName: string): void {
  for (const node of tree) {
    if (!searchName) {
      node.hidden = false;
      searchAndUpdate(node.children, searchName);
      continue;
    }

    if (
      node.name.toLowerCase().includes(searchName.toLowerCase()) ||
      node[props.viewValueKey].startsWith(searchName) ||
      node[props.parseCodeKey].startsWith(searchName)
    ) {
      node.hidden = false;
      continue;
    }

    if (node.children.length > 0) {
      searchAndUpdate(node.children, searchName);
      node.hidden = !node.children.some((child) => !child.hidden);
    } else {
      node.hidden = true;
    }
  }
}

function clearSelected(tree: ITreeViewItem[]): void {
  for (const node of tree) {
    node.selected = false;
    if (node.children && node.children.length) {
      clearSelected(node.children);
    }
  }
}

function expandChanged(): void {
  if (scrollBar.value) {
    setTimeout(() => scrollBar.value.update());
  }
}

watch(
  () => searchValue.value,
  (newValue) => {
    const clone = cloneDeep(tree.value);
    searchAndUpdate(clone, newValue);
    tree.value = clone;
  },
);

watch(
  () => isOpened.value,
  (newValue) => {
    if (!newValue) {
      contentVisible.value = false;
      searchValue.value = '';
      setTimeout(() => {
        contentVisible.value = true;
      });
    }
  },
);

watch(
  () => props.modelValue,
  (newValue) => {
    if (JSON.stringify(newValue) === JSON.stringify(value.value)) {
      return;
    }

    value.value = newValue || [];
    setViewValue(value.value);
  },
);
</script>

<template>
  <Filter
    v-model:opened="isOpened"
    :label="label"
    :parent-ref="parentRef"
    :icon="IconPathEnum.NavigationArrowDown20PxSvg"
    :view-value="viewValue"
    :disabled="disabled"
    @update:view-value="viewValueUpdated($event)"
    @update:opened="openChanged($event)"
  >
    <div
      v-if="contentVisible"
      class="d-flex flex-column text-nowrap"
      :style="{ 'min-width': width + 'px' }"
    >
      <div class="mm-body-medium-m mb16">{{ title }}</div>
      <TextField
        v-if="search"
        v-model="searchValue"
        :placeholder="searchPlaceHolder"
        :icon-path="IconPathEnum.NavigationSearchSvg"
        size="small"
        class="text-field-no-padding-40 mb20"
      />
      <div class="scroll-container d-flex flex-column">
        <PerfectScrollbar
          ref="scrollBar"
          watch-options
          :options="{ wheelPropagation: false, scrollYMarginOffset: 5 }"
        >
          <TreeView
            v-model:items="tree"
            checkable
            :available-check-all="false"
            :default-expand-all="false"
            :get-value-only-top-nodes="false"
            :right-label-key="rightLabelKey"
            @change="onValueChanged($event)"
            @expand-changed="expandChanged"
          />
        </PerfectScrollbar>
      </div>

      <button
        class="btn btn-primary d-flex justify-content-center mt24"
        @click="confirm"
      >
        {{ confirmButtonText }}
      </button>
    </div>
  </Filter>
</template>

<style scoped lang="scss">
.scroll-container {
  max-height: 360px;
}
</style>
