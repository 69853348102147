export class UrlHelper {
  static isValidUrl(url: string) {
    const pattern = /^(https?:\/\/)?((([a-zа-яё\d]([a-zа-яё\d-]{0,61}[a-zа-яё\d])?\.)+[a-zа-яё]{2,13})|((\d{1,3}\.){3}\d{1,3})|localhost)(:\d{1,5})?(\/[a-zа-яё\d%_.~+:-]*)*(\?[a-zа-яё\d%_.,~+:-=;&]*)?(#[-a-zа-яё\d_]*)?$/i;
    return !!pattern.test(url);
  }

  static getRelativeUrlPath(url: string) {
    return url?.split('/').pop();
  }

  static getRootUrlPath(url: string) {
    return url
      ?.split('/')
      .filter((part) => part)
      .shift();
  }
}
