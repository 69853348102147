export class FilterUtils {
  static isFiltersEmpty(filters: object, skipKeys?: Array<string>): boolean {
    return Object.keys(filters).every((key) => {
      if (skipKeys && skipKeys?.includes(key)) {
        return true;
      }

      const value = filters[key];

      if (typeof value === 'object' && value !== null) {
        return FilterUtils.isFiltersEmpty(value);
      }

      if (Array.isArray(value)) {
        return value?.length === 0;
      }

      if (value === null) {
        return true
      }

      return value === undefined;
    });
  }
}
