import * as Yup from 'yup';
import { ValidatorsMessagesEnum } from '../enums/validators.enum';
import { ControlSumChecker } from './controlSumCheck.util';
import { UrlHelper } from './url.util';
import type { IFile } from '../models/files.model';
import { FileTemp } from '../services/fileTemp.service';
import * as dateFns from 'date-fns';
import type { Ref } from 'vue';

export class Validators {
  public static required(message: string = ValidatorsMessagesEnum.Required) {
    return (value?: string | boolean) =>
      (value != null && Boolean(String(value).trim())) || message;
  }

  public static checked(message = ValidatorsMessagesEnum.Required) {
    return (value?: boolean) => !!value || message;
  }

  public static min(
    min: number,
    message = ValidatorsMessagesEnum.Min.replace('{min}', min.toString()),
  ) {
    return (value?: string) => (value != null && Number(value) > min) || message;
  }

  public static max(
    max: number,
    message = ValidatorsMessagesEnum.Max.replace('{max}', max.toString()),
  ) {
    return (value?: string) => (value != null && Number(value) < max) || message;
  }

  public static maxIncluded(
    max: number,
    message = ValidatorsMessagesEnum.Max.replace('{max}', max.toString()),
  ) {
    return (value?: string) => (value != null && Number(value) <= max) || message;
  }

  public static onlyNumbers(message = ValidatorsMessagesEnum.OnlyNumbers) {
    return (value?: string) => !value || !isNaN(Number(value)) || message;
  }

  public static integer(message = ValidatorsMessagesEnum.Integer) {
    return (value?: string) => !value || Number(value) % 1 === 0 || message;
  }

  public static maxLength(
    maxLength: number,
    message = ValidatorsMessagesEnum.MaxLength.replace('{maxLength}', maxLength.toString()),
  ) {
    return (value?: string) => !value || value.length <= maxLength || message;
  }

  public static minLength(
    minLength: number,
    message = ValidatorsMessagesEnum.MinLength.replace('{minLength}', minLength.toString()),
  ) {
    return (value?: string) => (value && value.length >= minLength) || message;
  }

  public static neededLengths(
    lengths: Array<number>,
    message = ValidatorsMessagesEnum.NeededLengths.replace('{neededLength}', lengths.join(' или ')),
  ) {
    return (value?: string) =>
      (value != null && lengths.some((length) => String(value).length === length)) || message;
  }

  public static yupEmail(message = ValidatorsMessagesEnum.Email) {
    return (value?: string) => Yup.string().email().isValidSync(value) || message;
  }

  public static inn(message = ValidatorsMessagesEnum.Inn) {
    return (value?: string) => ControlSumChecker.checkInn(value) || message;
  }

  public static ogrn(message = ValidatorsMessagesEnum.Ogrn) {
    return (value?: string) => ControlSumChecker.checkOgrn(value) || message;
  }

  public static url(message = ValidatorsMessagesEnum.Url) {
    return (value?: string) => !value || UrlHelper.isValidUrl(value) || message;
  }

  public static phoneNumber(message = ValidatorsMessagesEnum.PhoneNumber) {
    const regExp = /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{10}$/;
    return (value?: string) => (value != null && regExp.test(String(value))) || message
  }

  public static phoneNumberMask(message = ValidatorsMessagesEnum.PhoneNumber) {
    const regExp = /^\d{1,4}\d{10}$/;
    return (value?: string) => (!value ? true : regExp.test(String(value))) || message
  }

  public static notRequiredPhoneNumber(message = ValidatorsMessagesEnum.PhoneNumber) {
    const regExp = /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{10}$/;
    return (value?: string) => (!value ? true : regExp.test(String(value)) || message);
  }

  public static isEqual(value: Ref<string>, message = ValidatorsMessagesEnum.IsEqual) {
    return (comparable?: string) => {
      return (value.value != null && comparable === value.value) || message;
    };
  }

  public static password(message: string = ValidatorsMessagesEnum.Password) {
    const regExp =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!"№;%:?*()_+\-=#$^&’”'])[A-Za-z\d!"№;%:?*()_+\-=#$^&’”']{8,}$/g;
    return (value?: string) => !!String(value).match(regExp) || message;
  }

  public static russianString(message = ValidatorsMessagesEnum.RussianString) {
    const regExp = /[^ёЁ\-А-Яа-я\s]+/g;
    return (value?: string) => !String(value).match(regExp) || message;
  }

  /**
   * Данный валидатор необходимо использовать только для валидации полей с файлами!
   * Метод проверяет обязательное наличие хотя бы одного файла с расширением, которое находится в переданном массиве расширений.
   * Например при загрузке файла с электронной подписью, загружается основной файл и вспомогательные файлы с расширениями
   * .xml и .sig. Вспомогательные файлы игнорируются и проверяется обязательное наличие основного файла по расширению, например .pdf.
   * @param fileExtensions - массив расширений в виде ['.png', '.pdf', '.jpg']
   * @param message - сообщение об ошибке, если валидация не прошла
   */
  public static requiredOneOfFileExtensions(
    fileExtensions: Array<string>,
    message = ValidatorsMessagesEnum.Required,
  ) {
    return (files?: Array<IFile> | Array<FileTemp>) =>
      files?.some((file) =>
        fileExtensions.some((fileExtension) =>
          file instanceof FileTemp
            ? file?.file instanceof File
              ? file.file.name.toLowerCase?.()?.endsWith?.(fileExtension)
              : file?.file?.fileName?.toLowerCase?.()?.endsWith?.(fileExtension)
            : file?.fileName?.toLowerCase?.()?.endsWith?.(fileExtension),
        ),
      ) || message;
  }

  public static maxDate(
    maxDate?: Date,
    message = ValidatorsMessagesEnum.Date,
    includingMax = true,
  ) {
    return (value: Date) => {
      if (!maxDate || !value) {
        return true;
      }

      if (!(value instanceof Date) || !(maxDate instanceof Date)) {
        return;
      }

      const formattedValue = value.setHours(0, 0, 0, 0);
      const formattedMaxDate = maxDate.setHours(0, 0, 0, 0);
      const dateIsBefore = dateFns.isBefore(formattedValue, formattedMaxDate);

      return (
        (includingMax
          ? dateIsBefore || dateFns.isEqual(formattedValue, formattedMaxDate)
          : dateIsBefore) || message
      );
    };
  }

  public static todayMinDate(message = ValidatorsMessagesEnum.SupplierMinDateToday) {
    return (value: Date) => {
      if (!value) {
        return true;
      }

      if (!(value instanceof Date)) {
        return;
      }

      const today = new Date(Date.now());
      today.setHours(0, 0, 0, 0);
      return dateFns.isBefore(value, today) ? message : true;
    };
  }

  public static pattern(regExp: RegExp, message: string = ValidatorsMessagesEnum.Required) {
    return (value?: string) =>
      !!String(value).match(regExp) || message;
  }

  public static compare(compareField: string, message: string = ValidatorsMessagesEnum.Required) {
    return (value: string, allValues: unknown) => {
      return value === allValues['form'][compareField] || message;
    }
  }

  public static date(message: string = ValidatorsMessagesEnum.Date) {
    const regExp = /^(0[1-9]|[12][0-9]|3[01])(0[1-9]|1[0-2])(19[0-9]{2}|20[0-9]{2}|2100|2200)$/;
    return (value?: string) => {
      if (!regExp.test(value)) {
        return message;
      }

      const day = parseInt(value.slice(0, 2), 10);
      const month = parseInt(value.slice(2, 4), 10);
      const year = parseInt(value.slice(4, 8), 10);
      const date = new Date(year, month - 1, day);

      if (date.getFullYear() !== year || (date.getMonth() + 1) !== month || date.getDate() !== day) {
        return message;
      }

      return true;
    };
  }

  public static fullName(message: string = ValidatorsMessagesEnum.CheckValidaty, withPatronymic = true) {
    return (value?: string) => {
      if (!value?.length) {
        return true;
      }

      const containsNumber = /\d/.test(value);
      const splitName = value?.split(' ');

      if (
        splitName?.length < (withPatronymic ? 3 : 2)
        || splitName?.some((namePart) => !namePart?.length)
        || containsNumber
      ) {
        return message;
      }

      return true;
    }
  }
}
