import { ProjectsFiltersRequestEnum } from '@/views/srm/projects/api/models/enums/projectsFilters.enum';
import { ProjectCountersEnum } from '@/views/srm/projects/api/models/enums/projectCounters.enum';

export const PROJECTS_SKIP_FILTERS_MAP = new Map<ProjectCountersEnum, Array<ProjectsFiltersRequestEnum>>([

  [ProjectCountersEnum.Confirmed, [
    ProjectsFiltersRequestEnum.STAGE_SUPPLIER_PARTICIPATION_STATUSES,
  ]],
  [ProjectCountersEnum.Active, [
  ]],
  [ProjectCountersEnum.Invited, [
      ProjectsFiltersRequestEnum.STAGE_SUPPLIER_PARTICIPATION_STATUSES,
  ]],
  [ProjectCountersEnum.Finished, [
  ]],
  [ProjectCountersEnum.SubmittedOffer, [
    ProjectsFiltersRequestEnum.STAGE_SUPPLIER_PARTICIPATION_STATUSES,
  ]],
]);