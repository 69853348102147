<script setup lang="ts" generic="T">

import BaseVueModal from '@/ui-kit/components/modal/BaseVueModal.vue';
import { ref } from 'vue';
import { IconPathEnum } from '@/ui-kit/enums/iconPath.enum';
import SvgIcon from '@/ui-kit/components/SvgIcon.vue';
import { sanitize } from '@/ui-kit/directives/sanitize';

const props = withDefaults(
  defineProps<{
    title: string;
    message: string;
    cancelText?: string;
    confirmText?: string;
    confirmButtonClass?: string;
    confirmAction?: () => Promise<T>;
    actionErrorMessage?: string;
  }>(),
  {
    cancelText: 'Отмена',
    confirmText: 'Применить',
  },
)

const emit = defineEmits<{
  (e: 'cancel', data?: unknown): void;
  (e: 'confirm', data: boolean | T): void;
}>();

const confirmActionInProgress = ref<boolean>(false);

async function onConfirm(): Promise<void> {
  if (props.confirmAction) {
    try {
      confirmActionInProgress.value = true;
      const res = await props.confirmAction();
      emit('confirm', res || true);
    } catch (e) {
      emit('confirm', e);
    } finally {
      confirmActionInProgress.value = false;
    }
  } else {
    emit('confirm', true);
  }
}

</script>

<template>
  <BaseVueModal
    :title="title"
    @cancel="emit('cancel')"
  >
    <div class="d-flex flex-column">
      <div
        class="mm-body-regular-s mm-color-black"
        v-html="sanitize(message)"
      />
      <div class="d-flex justify-content-between mt40">
        <button
          class="btn btn-secondary"
          :disabled="confirmActionInProgress"
          @click="emit('cancel')"
        >
          {{ cancelText }}
        </button>
        <div class="position-relative">
          <button
            class="btn btn-primary"
            :class="{
              [confirmButtonClass]: !!confirmButtonClass
            }"
            :disabled="confirmActionInProgress"
            @click="onConfirm"
          >
            <span v-if="!confirmActionInProgress">{{ confirmText }}</span>
          </button>
          <SvgIcon
            v-if="confirmActionInProgress"
            class="uploading-icon"
            :src="IconPathEnum.IndicatorsProgressSvg"
          />
        </div>
      </div>
    </div>
  </BaseVueModal>
</template>

<style scoped lang="scss">
.btn {
  width: 247px;
  height: 56px;
  display: flex;
  justify-content: center;
}
</style>
