import {
  BASIC_ESCAPE_CHAR_PATTERN,
  DOUBLE_DIGIT_ESCAPE_CHAR_PATTERN,
  MULTIPLE_SPACEBAR_PATTERN,
  QUAD_DIGIT_ESCAPE_CHAR_PATTERN,
  START_SPACEBAR_PATTERN,
} from '@/common/composables/textFieldCleanValuePatterns.const';

export function cleanStringEscapeCharsAndSpacebar(value: string): string {
  const convertEscapedToActualChars = (str: string) =>
    str.replace(DOUBLE_DIGIT_ESCAPE_CHAR_PATTERN, (match, p1) => String.fromCharCode(parseInt(p1, 16)))
      .replace(QUAD_DIGIT_ESCAPE_CHAR_PATTERN, (match, p1) => String.fromCharCode(parseInt(p1, 16)));

  return convertEscapedToActualChars(value)
    .replace(BASIC_ESCAPE_CHAR_PATTERN, '')
    .replace(START_SPACEBAR_PATTERN, '')
    .replace(MULTIPLE_SPACEBAR_PATTERN, ' ');
}
