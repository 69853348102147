<script setup lang="ts">
import { ref } from 'vue';
import { ActiveResetScreenEnum } from '@/views/auth/pages/reset/enums/activeResetScreen.enum';
import ResetPasswordForm from '@/views/auth/pages/reset/components/ResetPasswordForm.vue';
import Notificator from '@/ui-kit/services/notificator.service';
import type {
  IRequestForSmsCode,
  ITempTokenResponse,
} from '@/views/auth/models/auth.model';
import { useAuthorizationApi } from '@/views/auth/api/useAuthorizationApi';
import SMSInputView from '@/views/auth/components/SMSInputView.vue';
import FillPasswordForm from '@/views/auth/pages/reset/components/FillPasswordForm.vue';
import { useRouter } from 'vue-router';
import { SMSOtpTypeEnum } from '@/views/auth/api/enums/smsOtpType.enum';
import { useProfileStore } from '@/stores/profile.store';
import { useAuthToken } from '@/common/composables/useAuthToken';
import { useSupplierApi } from '@/common/api/useSupplierApi';
import { useUserApi } from '@/views/srm/account-settings/api/useUserApi';
import { formatPhoneNumber } from '@/common/utils/formatPhone.util';
import { ErrorTypeEnum } from '@/common/enums/error-type.enum';

const api = useAuthorizationApi();
const { saveToken } = useAuthToken();
const profileApi = useSupplierApi();
const { getCurrent } = useUserApi();

const router = useRouter();
const isLoading = ref<boolean>(false);
const requestData = ref<IRequestForSmsCode>();
const smsOtp = ref<string>();
const activeScreen = ref<ActiveResetScreenEnum>(ActiveResetScreenEnum.Reset);
const tempToken = ref<string>();
const userStore = useProfileStore();
const isUnblockTimerStart = ref<boolean>(false);
const unblockTimerMsValue = ref<number>(0);
const attemptsLeftMsValue = ref<number>(0);

async function checkSmsInformation() {
  return await api.checkLastOtp(SMSOtpTypeEnum.Recovery, requestData.value?.phone);
}

async function getSMS(): Promise<ITempTokenResponse> {
  return await api.getSmsForRecovery(requestData.value);
}

async function repeatSMS(): Promise<void> {
  await api.repeatSmsForRecovery(requestData.value?.phone, tempToken.value);
}

async function onSMSConfirm(code: string): Promise<void> {
  await api.checkSmsOtp(SMSOtpTypeEnum.Recovery, {
    phone: requestData.value?.phone,
    smsOtp: code,
  });
  smsOtp.value = code;
  activeScreen.value = ActiveResetScreenEnum.FillPassword;
}

async function onConfirm(data: IRequestForSmsCode): Promise<void> {
  try {
    isLoading.value = true;
    requestData.value = data;
    const request = await getSMS();
    tempToken.value = request?.token;
    activeScreen.value = ActiveResetScreenEnum.Sms;
  } catch (error) {
    const dataErrors = error.response.data?.errors;
    const forbiddenError = dataErrors?.find((item) => item.code === ErrorTypeEnum.Forbidden)

    if (forbiddenError) {
      const { unblockTimerMs, attemptsLeft } = await api.checkLastOtp(SMSOtpTypeEnum.Recovery, data.phone);
      if (unblockTimerMs) {
        isUnblockTimerStart.value = true;
        unblockTimerMsValue.value = unblockTimerMs;
      }
      if (attemptsLeft >= 0) {
        attemptsLeftMsValue.value = attemptsLeft;
      }
      await router.push('/auth/access-denied');
    } else if (dataErrors.find(error => error.code === ErrorTypeEnum.SmsLimitExceeded)) {
      activeScreen.value = ActiveResetScreenEnum.Sms;
    } else {
      Notificator.showDetachedNotification('Произошла непредвиденная ошибка');
    }
  } finally {
    isLoading.value = false;
  }
}

async function onNewPasswordConfirm(password: string): Promise<void> {
  try {
    isLoading.value = true;
    const token = await api.resetSupplierPassword({
      password,
      phone: requestData.value?.phone,
      smsOtp: smsOtp.value,
      token: tempToken.value,
    });
    saveToken(token);
    const profileInfo = await profileApi.getBaseProfile();
    const current = await getCurrent();
    userStore.setCurrent(current);
    userStore.setProfile(profileInfo);
    await router.push('/');
  } catch (e) {
    if (e.response.status === 404) {
      await router.push('/auth/login');
    } else {
      Notificator.showDetachedNotification('Произошла непредвиденная ошибка');
    }
  } finally {
    isLoading.value = false;
  }
}

function backSmsInputView() {
  activeScreen.value = ActiveResetScreenEnum.Reset;
}
</script>

<template>
  <ResetPasswordForm
    v-if="activeScreen === ActiveResetScreenEnum.Reset"
    :isLoading="isLoading"
    :user-phone="requestData?.phone"
    :unblock-timer-ms="unblockTimerMsValue"
    :attempts-left="attemptsLeftMsValue"
    :is-unblock-timer-start="isUnblockTimerStart"
    @reset="onConfirm"
    @clear-timer="isUnblockTimerStart = false"
  />
  <SMSInputView
    v-if="activeScreen === ActiveResetScreenEnum.Sms"
    :key="activeScreen"
    :phone="requestData?.phone"
    :get-sms-fn="repeatSMS"
    alternative-text
    :callback-fn="onSMSConfirm"
    :get-sms-timeout-fn="checkSmsInformation"
    @back="backSmsInputView"
  >
    <template #message="{ phone }">
      <p class="phone-message">
        На указанный вами телефон <span>{{ formatPhoneNumber(phone, true) }}</span>, при наличии<br>
        зарегистрированного аккаунта, поступит СМС с проверочным кодом
      </p>
    </template>
  </SMSInputView>
  <FillPasswordForm
    v-if="activeScreen === ActiveResetScreenEnum.FillPassword"
    :is-loading="isLoading"
    @proceed="onNewPasswordConfirm"
  />
</template>

<style scoped lang="scss">
@import '@styles/base/common/variables';

:deep(.btn:disabled) {
  opacity: 1;
}

.phone-message {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $text-black;
    margin-bottom: 40px;

    span {
      font-weight: 500;
    }
}

</style>
