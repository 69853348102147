<script setup lang="ts">
import { someFieldIsInvalidUtil } from '@/common/utils/someFieldIsInvalid.util';
import AuthAlert from '@/views/auth/components/AuthAlert.vue';
import TextField from '@/ui-kit/components/TextField.vue';
import { IconPathEnum } from '@/ui-kit/enums/iconPath.enum';
import { useField, useForm } from 'vee-validate';
import { Validators } from '@/ui-kit/utils/validators.util';
import type { IUserRegister } from '@/views/auth/pages/registration/models/userRegister.model';
import { ValidatorsMessagesEnum } from '@/ui-kit/enums/validators.enum';
import SvgIcon from '@/ui-kit/components/SvgIcon.vue';
import BackButton from '@/common/components/BackButton.vue';
import { useRouter } from 'vue-router';
import { usePhoneMask } from '@/common/hooks/usePhoneMask';
import YandexCaptcha from '@/common/YandexCaptcha.vue';
import { onBeforeMount, ref } from 'vue';
import type { IRequestForSmsCode } from '@/views/auth/models/auth.model';
import ExceededLimitTimer from '@/views/auth/components/ExceededLimitTimer.vue';

const props = defineProps<{
  isLoading: boolean;
  userPhone?: string;
  unblockTimerMs: number;
  attemptsLeft: number;
  isUnblockTimerStart: boolean;
}>();

const emit = defineEmits<{
  (e: 'reset', value: IRequestForSmsCode): void;
  (e: 'clearTimer'): void;
}>();

const router = useRouter();
const captchaToken = ref<string>();

const {values, errors} = useForm<IUserRegister>({
  validationSchema: {
    phone: [
      Validators.required(),
      Validators.phoneNumberMask(ValidatorsMessagesEnum.PhoneNumber),
    ],
  },
});

const fields = {
  phone: useField<string>('phone'),
};

const { phoneMaskOptions, onFocus, onChange } = usePhoneMask(fields.phone, true);

async function proceed(): Promise<void> {
  if (someFieldIsInvalidUtil(errors.value)) {
    Object.values(fields).forEach((field) => field.setTouched(true));
  } else {
    emit('reset', {
      phone: `+${values.phone}`,
      smartToken: captchaToken.value,
    });
  }
}

function navigateBack(): void {
  router.push('/auth/login')
}

function onCaptchaSuccess(token: string): void {
  captchaToken.value = token;
}

onBeforeMount(() => {
  if(props.userPhone) {
    fields.phone.value.value = props.userPhone
    onFocus(false)
  }
})
</script>

<template>
  <div class="d-flex flex-column align-items-center">
    <div class="d-flex flex-column mt40">
      <BackButton @click="navigateBack">Назад</BackButton>
      <div class="d-flex flex-column align-items-center">
        <AuthAlert class="mt24 wrapper">
          <div class="d-flex flex-column">
            <div class="mm-headline-3 mm-color-black mm-font-500 mb16">Восстановление пароля</div>
            <div class="mm-body-regular-s mm-color-black mb24">
              Введите номер телефона вашего аккаунта и следуйте <br>
              дальнейшим инструкциям
            </div>
            <TextField
              v-model="fields.phone.value.value"
              label="Ваш номер мобильного телефона"
              :validation-field="fields.phone"
              :mask-options="phoneMaskOptions"
              :clearable="false"
              :disabled="isLoading"
              icon-path=""
              @focus-change="onFocus"
              @update:model-value="onChange"
            />

            <YandexCaptcha @success="onCaptchaSuccess" />

            <ExceededLimitTimer
              :unblock-timer-ms="unblockTimerMs"
              :attempts-left="attemptsLeft"
              @clear="emit('clearTimer')"
            />

            <div class="position-relative mt40">
              <button
                class="btn btn-primary button56 w-100"
                :disabled="someFieldIsInvalidUtil(errors) || isLoading || !captchaToken || isUnblockTimerStart"
                @click="proceed"
              >
                <span v-if="!isLoading">Восстановить пароль</span>
              </button>
              <SvgIcon
                v-if="isLoading"
                class="uploading-icon"
                :src="IconPathEnum.IndicatorsProgressSvg"
              />
            </div>
          </div>
        </AuthAlert>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@styles/base/common/variables';

.wrapper {
  border-radius: 12px;
  border: 1px solid $gray-200;
}
</style>
