import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import type { VueKeycloakInstance } from '@dsb-norge/vue-keycloak-js/dist/types';
import '@/assets/styles/main.scss';
import Notificator from '@/ui-kit/services/notificator.service';
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import FilesService from '@/ui-kit/services/api/files.service';
import ModalManager from '@/ui-kit/services/modalManager.service';
import 'vue-final-modal/style.css';
import { createI18n } from 'vue-i18n';
import { createVfm } from 'vue-final-modal';
import axios from './common/auth/axios-interceptor';
import ru from '@/ui-kit/locales/ru';
import Toast from 'vue-toastification';
import { customPluralizationRuleRu } from '@/ui-kit/utils/customPluralizationRules.util';
import NotificatorSettings from './ui-kit/constants/notificator.const';
import * as Sentry from '@sentry/vue';

const pinia = createPinia();

const messages = {
  ru,
};

const app = createApp(App);
const vfm = createVfm();
app.use(vfm);
app.use(
  createI18n({
    locale: 'ru',
    globalInjection: true,
    legacy: false,
    messages,
    pluralRules: {
      ru: customPluralizationRuleRu,
    },
  }),
);
app.use(pinia);
app.use(Toast, NotificatorSettings.DEFAULT_NOTIFICATION_PLUGIN_SETTINGS);
app.use(PerfectScrollbar);

if (!window.location.href.includes('localhost') && import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      Sentry.browserTracingIntegration({ router }),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

app.use(router);
app.provide(Notificator.getServiceName(), new Notificator());
app.provide(FilesService.getServiceName(), new FilesService());
app.provide(ModalManager.getServiceName(), new ModalManager());
app.config.globalProperties.$axios = axios;

app.mount('#app');

declare module '@vue/runtime-core' {
  interface IComponentCustomProperties {
    $keycloak: VueKeycloakInstance;
  }
}
