<script setup lang="ts">
import Filter from '@/common/components/filters/Filter.vue';
import type { ISortFilter } from '@/common/models/filters/sort/sort-filter';
import { IconPathEnum } from '@/ui-kit/enums/iconPath.enum';
import SvgIcon from '@/ui-kit/components/SvgIcon.vue';
import { isEqual } from 'lodash-es';
import { ref } from 'vue';
import type { ISortFilterParams } from '@/common/models/filters/filter-model';
import type { ProjectsOrderByEnum } from '@/views/srm/projects/api/models/enums/projectsOrderBy.enum';

const props = withDefaults(defineProps<ISortFilterParams>(), {
  width: 214,
  confirmButtonText: 'Применить',
});

const isOpen = ref<boolean>(false);

function checked(item: ISortFilter<ProjectsOrderByEnum>): boolean {
  return isEqual(props.modelValue, item);
}

function selectSort(item: ISortFilter<ProjectsOrderByEnum>): void {
  emits('update:modelValue', item);
  isOpen.value = false;
}

function viewValueUpdated(value: string): void {
  if (!value) {
    emits('update:modelValue', null);
    isOpen.value = false;
  }
}

function getLabel(): string {
  for (const group of props.groups.groups) {
    const foundItem = group.values.find((item) => isEqual(item.value, props.modelValue));
    if (foundItem) {
      return group.filedName;
    }
  }
  return '';
}

const emits = defineEmits<{
  (e: 'update:modelValue', value?: ISortFilter<unknown>): void;
}>();
</script>

<template>
  <Filter
    v-model:opened="isOpen"
    :label="getLabel()"
    :parent-ref="parentRef"
    :disabled="disabled"
    :rotate-icon-when-opened="false"
    :icon-rotated="modelValue ? modelValue['descending'] : false"
    :icon="IconPathEnum.NavigationFilterNewSvg"
    @update:view-value="viewValueUpdated($event)"
  >
    <div
      class="d-flex flex-column"
      :style="{ 'min-width': width + 'px' }"
    >
      <div
        v-for="group in groups.groups"
        :key="group.title"
        class="group d-flex flex-column"
      >
        <div class="mm-caption-regular mb12">{{ group.title }}</div>
        <div
          v-for="item in group.values"
          :key="item.viewValue"
          class="d-flex align-items-center justify-content-between mm-cursor-pointer mb8"
          @click="selectSort(item.value)"
        >
          <div class="mm-body-regular-s">{{ item.viewValue }}</div>
          <div class="checkmark d-flex align-items-center justify-content-center">
            <SvgIcon
              v-if="checked(item.value)"
              class="checkmark"
              :src="IconPathEnum.IndicatorsCheckmarkSvg"
            />
          </div>
        </div>
      </div>
    </div>
  </Filter>
</template>

<style scoped lang="scss">
@import '@styles/base/common/variables';

.group {
  padding-bottom: 8px;

  &:not(:last-child) {
    border-bottom: 1px solid $qualifications-container-border-c;
    margin-bottom: 16px;
  }
}

.checkmark {
  width: 10px;
  height: 6px;
}
</style>
