import { format, parse, intervalToDuration, isToday, formatISO } from 'date-fns';
import type { Duration } from 'date-fns';
import utcToZonedTime from 'date-fns-tz/esm/utcToZonedTime';
import ru from 'date-fns/locale/ru/index.js';
import { TimezonesEnum } from '../enums/timezones.enum';
import dateFnsTz from 'date-fns-tz';
import moment from 'moment';
import type { IDateDiff } from '@/common/models/date-diff.model';
import { useProfileStore } from '@/stores/profile.store';

const formatOptions = { locale: ru };

export class DateFormat {
  public static default(
    value: string | Date,
    useTimezone = true,
    roundMilliseconds: boolean = false,
    inverted: boolean = false,
    applyLocalOffset: boolean = true,
    formatString?: string,
  ): string {
    let dateValue = value;

    if (roundMilliseconds && value.toString().includes('999Z')) {
      const date = new Date(value);
      date.setMilliseconds(date.getMilliseconds() + 1);
      dateValue = date.toISOString();
    }

    if (useTimezone) {
      dateValue = DateFormat.formatByTimezone(
        dateValue,
        inverted,
        applyLocalOffset,
      );
    }

    if (value instanceof Date) {
      return format(dateValue as Date, formatString || 'dd.MM.yyyy', formatOptions);
    }

    if (!value || value.length <= 3) {
      return value;
    }

    return this.formatDate(moment(dateValue).toISOString(), formatString || 'dd.MM.yyyy', formatOptions);
  }

  public static iso(value: string | Date, useTimezone = true): string {
    let dateValue = value;

    if (useTimezone) {
      dateValue = DateFormat.formatByTimezone(value);
    }

    if (value instanceof Date) {
      return format(dateValue as Date, 'yyyy-MM-dd', formatOptions);
    }

    if (!value || value.length <= 3) {
      return value;
    }
    return this.formatDate(moment(dateValue).toISOString(), 'yyyy-MM-dd', formatOptions);
  }

  public static withHoursMinutes(value: string, formatString?: string, useTimezone = true): string {
    if (!value || value.length <= 3) {
      return value;
    }

    let dateValue = value;

    if (useTimezone) {
      dateValue = DateFormat.formatByTimezone(value).toISOString();
    }

    return this.formatDate(dateValue, formatString || 'dd.MM.yyyy HH:mm', formatOptions);
  }

  public static withHoursAndMinutesPrecise(value: string, useTimezone = true): string {
    return this.default(value, useTimezone) + ' в ' + this.timeWithoutSeconds(value, useTimezone);
  }

  public static withTime(value: string): string {
    if (!value || value.length <= 3) {
      return value;
    }
    return this.formatDate(value, 'dd.MM.yyyy, HH:mm:ss', formatOptions);
  }

  public static withTimeAndMscWithoutMs(value: string): string {
    if (!value || value.length <= 3) {
      return value;
    }

    return this.formatDate(value, 'dd.MM.yyyy, HH:mm (МСК)', formatOptions);
  }

  public static withTimeReversed(value: string, needSeconds = true) {
    if (!value || value.length <= 3) {
      return value;
    }
    return this.formatDate(value, `HH:mm${needSeconds ? ':ss' : ''}, dd.MM.yyyy`, formatOptions);
  }

  public static withoutYear(value: string | Date): string {
    if (value instanceof Date) {
      return format(value, 'dd.MM', formatOptions);
    }
    if (!value || value.length <= 3) {
      return value;
    }

    return this.formatDate(value, 'dd.MM', formatOptions);
  }

  public static withoutDay(value: string): string {
    if (!value || value.length <= 3) {
      return value;
    }
    return this.formatDate(value, 'MM.yyyy', formatOptions);
  }

  public static year(value: string): string {
    if (!value || value.length <= 3) {
      return value;
    }
    return this.formatDate(value, 'yyyy', formatOptions);
  }

  public static nameMonthYear(value: string) {
    if (!value || value.length <= 3) {
      return value;
    }
    return this.formatDate(value, 'LLLL yyyy', formatOptions);
  }

  public static time(value: string, customFormat = 'HH:mm:ss', useTimezone = true) {
    if (!value || value.length <= 3) {
      return value;
    }

    let dateValue = value;

    if (useTimezone) {
      dateValue = moment(DateFormat.formatByTimezone(value))?.toISOString();
    }

    return this.formatDate(dateValue, customFormat, formatOptions);
  }

  public static timeWithoutSeconds(value: string, useTimezones = true) {
    let dateValue = value;

    if (!value || value.length <= 3) {
      return value;
    }

    if (useTimezones) {
      dateValue = moment(DateFormat.formatByTimezone(value))?.toISOString();
    }

    return this.formatDate(dateValue, 'HH:mm', formatOptions);
  }

  public static nativeTimeWithoutSecondsAndTimezone(value: string): string {
    if (!value) {
      return '';
    }

    const splitTime = value.split('T');
    return splitTime[1].slice(0, 5);
  }

  public static intervalToDuration(ms: number): Duration {
    return intervalToDuration({ start: 0, end: ms });
  }

  public static parse(value: string, formatString: string, timezone?: string) {
    const currentTimezone = timezone || TimezonesEnum.Moscow;
    return parse(utcToZonedTime(`${value}Z`, currentTimezone).toString(), formatString, new Date());
  }

  public static getDateDiff(dateTo: string | Date, from?: string | Date, returnObject?: boolean, enableTimezoneOffset = true): string | IDateDiff {
    if (!dateTo) {
      return '&mdash;';
    }
    const now = from ? moment(from) : moment();
    const futureDate = moment(dateTo);
    const duration =  moment.duration(futureDate.diff(now));

    let days = Math.floor(duration.asDays());
    let hours = duration.hours();
    const minutes = duration.minutes();

    const offset = !enableTimezoneOffset ? null : DateFormat.getTimezoneOffset();

    if (offset == null) {
      if (returnObject) {
        return {
          days,
          hours,
          minutes,
        }
      }

      return `${days} дн. ${hours} ч. ${minutes} м.`;
    }

    if (hours + offset < 0) {
      days--;
      hours = 24 - hours + offset;
    }
    else if (hours + offset > 24) {
      days++;
      hours = hours + offset - 24;
    }
    else {
      hours = hours + offset;
    }

    if (returnObject) {
      return {
        days,
        minutes,
        hours,
      }
    }

    return `${days} дн. ${hours} ч. ${minutes} м.`;
  }

  public static formatDate(
    value: string,
    formatString: string,
    formatOptions: dateFnsTz.OptionsWithTZ,
    timezone?: string,
  ) {
    return dateFnsTz.formatInTimeZone(
      new Date(value),
      timezone,
      formatString,
      formatOptions,
    );
  }

  public static todayOrDefault(value: string): string {
    if (isToday(new Date(value))) {
      return 'Сегодня';
    }

    return this.default(value);
  }

  public static shortDate(value: string | Date, useTimezone = true): string {
    let dateValue = value;

    if (useTimezone) {
      dateValue = DateFormat.formatByTimezone(value);
    }

    if (value instanceof Date) {
      return format(dateValue as Date, 'dd.MM.yy', formatOptions);
    }
    if (!value || value.length <= 3) {
      return value;
    }

    return this.formatDate(moment(dateValue).toISOString(), 'dd.MM.yy', formatOptions);
  }

  public static formatLocaDateTimeToISO(date: number | Date | string): string {
    if (date instanceof Date) {
      return formatISO(date);
    }

    return formatISO(new Date(date));
  }

  private static getTimezoneOffset(): number | null {
    const userStore = useProfileStore();
    return userStore.getTimezone?.offset;
  }

  public static formatByTimezone(
    value: string | Date,
    inverted: boolean = false,
    applyLocalOffset: boolean = true,
    neededOffset?: number,
  ): Date {
    const offset = neededOffset || DateFormat.getTimezoneOffset();

    if (offset == null) {
      return value as Date;
    }

    const finalOffset = inverted ? offset * -1 : offset;
    const date = new Date(value);
    const localOffset = applyLocalOffset ? date.getTimezoneOffset() / 60 : 0;
    date.setUTCHours(date.getUTCHours() + finalOffset + localOffset);
    return date;
  }

  // заменить в строке типа 2024-05-30T00:00:00.000+05:00 оффсет (вместо +05:00 будет таймзона пользователя)
  public static replaceUtcTimezoneOffset(value: string): string {
    if (!value) {
      return '';
    }

    const offset = DateFormat.getTimezoneOffset();

    const splitString = value.includes('+')
      ? value.split('+')
      : value.split('-');

    if (!offset) {
      return `${splitString[0]}+00:00`;
    }

    let finalOffsetString = `${splitString[0]}`;

    finalOffsetString += offset < 0 ? '-' : '+';

    finalOffsetString += Math.abs(offset).toString()?.length < 2 ? `0${Math.abs(offset)}:00` : `${Math.abs(offset)}:00`;

    return finalOffsetString;
  }

  // форматировать дату utc с заданным офсетом к таймзоне пользователя
  public static applyUserTimezoneToTimezonedUtcString(value: string): string {
    if (!value) {
      return null;
    }

    const offset = DateFormat.getTimezoneOffset();

    if (value.endsWith('Z')) {
      return moment(
        DateFormat.formatByTimezone(
          value,
          false,
          false,
        ),
        true,
      ).toISOString(false);
    }

    const splitString = value.includes('+')
      ? value.split('+')
      : value.split('-');

    const valueOffset = +(splitString[1].split(':')[0]);

    const formattedDateFromValueOffset = moment(DateFormat.formatByTimezone(
      splitString[0],
      false,
      false,
      valueOffset,
    ), true).toISOString(true);

    if (!offset) {
      return formattedDateFromValueOffset;
    }

    const result = moment(
      DateFormat.formatByTimezone(
        formattedDateFromValueOffset,
        true,
        false,
      ),
      true,
    ).toISOString(true);

    return (result.includes('+')
        ? result.split('+')
        : result.split('-')
    )[0];
  }
}
