<script setup lang="ts">
import { IconPathEnum } from '@/ui-kit/enums/iconPath.enum';
import TextField from '@/ui-kit/components/TextField.vue';
import SvgIcon from '@/ui-kit/components/SvgIcon.vue';
import FilterWrapper from '@/common/components/filters/FilterWrapper.vue';
import type {
  FilterComponentParams,
  IFilterUpdateDataValue,
} from '@/common/models/filters/filter-model';
import { ref, watch, withDefaults, defineProps } from 'vue';
import debounce from 'lodash.debounce';
import type { IAxiosParams } from '@/common/models/filters/axios-params';

const props = withDefaults(
  defineProps<{
    parentRef: unknown;
    filters: Array<FilterComponentParams>;
    filterParams: IAxiosParams<unknown, unknown>;
    disabled?: boolean;
    isFiltersEmpty?: boolean;
    updateConfirmCallback?: () => Promise<boolean>;
    searchPlaceholder?: string;
  }>(),
  {
    searchPlaceholder: 'Название, ИНН, КПП ...',
  },
);

const emits = defineEmits<{
  (e: 'clearFilters'): void;
  (e: 'update:search', value: string): void;
  (e: 'update:query', value: unknown): void;
  (e: 'update:data', value: IFilterUpdateDataValue): void;
}>();

const innerSearch = ref<string>(props.filterParams?.data?.search || '');

function onUpdateQuery(newQuery: unknown): void {
  emits('update:query', newQuery);
}

function onUpdateData(data: unknown, key: number | string): void {
  emits('update:data', { key, data });
}

watch(
  () => innerSearch.value,
  debounce(() => {
    if (!innerSearch.value?.length) {
      emits('update:search', null);
      return;
    }

    if (innerSearch.value?.length < 3) {
      return;
    }

    if (innerSearch.value === props.filterParams.data?.search) {
      return;
    }

    emits('update:search', innerSearch.value);
  }, 700),
);

watch(
  () => props.filterParams.data?.search,
  (newValue) => {
    innerSearch.value = newValue == null ? '' : newValue;
  },
);
</script>

<template>
  <div class="d-flex justify-content-between align-items-start filters-bar">
    <div class="d-flex flex-wrap flex-gap">
      <div
        v-for="filter in filters"
        :key="filter.id"
      >
        <FilterWrapper
          v-if="filter.isQueryFilter"
          :parent-ref="parentRef"
          :model-value="filterParams.query"
          :filter="filter"
          :disabled="disabled && isFiltersEmpty"
          @update:model-value="onUpdateQuery"
        />
        <FilterWrapper
          v-else
          :parent-ref="parentRef"
          :model-value="filterParams.data[filter.id]"
          :filter="filter"
          :update-confirm-callback="updateConfirmCallback"
          :disabled="disabled && isFiltersEmpty"
          @update:model-value="onUpdateData($event, filter.id)"
        />
      </div>

      <button
        v-if="!isFiltersEmpty"
        class="btn btn-secondary clear-filters"
        @click="$emit('clearFilters')"
      >
        Очистить все
        <SvgIcon :src="IconPathEnum.NavigationClose20PxSvg" />
      </button>
    </div>
    <div class="search-container">
      <TextField
        :model-value="innerSearch"
        container-class="search-height"
        :placeholder="searchPlaceholder"
        :icon-path="IconPathEnum.NavigationSearchSvg"
        size="tiny"
        :disabled="disabled && isFiltersEmpty"
        @enter="(event) => innerSearch = event?.toString()"
        @click-clear-button="innerSearch = ''"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.flex-gap {
  gap: 4px;
}

.search-container {
  width: 247px;
  min-width: 247px;
  margin-left: 60px !important;
}

.search {
  height: 36px;
}

.filter-wrapper {
  :deep(.date-range) {
    margin-bottom: 0;
  }
}

.clear-filters {
  padding: 8px 8px 8px 16px;
  gap: 4px;
}
</style>
