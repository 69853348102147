<script setup lang="ts">
import { computed, onBeforeUnmount, ref, watch } from 'vue';

const props = defineProps<{
  unblockTimerMs: number;
  attemptsLeft: number;
}>()

const emit = defineEmits<{
  (e: 'clear'): void;
}>();

const hourInMilliseconds = 3_600_000;
const oneSecond = 1000;
const minuteInMilliseconds = 60_000;

const isBlockRetryCountdown = ref<boolean>(false);
const blockRetryCountdownInterval = ref<number>();
const timeToRetry = ref<number>();

function getTimeFromTimeInt(timeInt: number): string {
  let minutes: string = Math.floor(timeInt / minuteInMilliseconds).toString();
  let seconds = Math.floor((timeInt - Number(minutes) * minuteInMilliseconds) / oneSecond).toString();

  if (minutes.length === 1) {
    minutes = `0${minutes}`;
  }

  if (seconds.length === 1) {
    seconds = `0${seconds}`;
  }

  return `${minutes}:${seconds}`;
}

const countdownTime = computed<string>(() => {
  if (!timeToRetry.value) {
    return '';
  }

  return getTimeFromTimeInt(timeToRetry.value);
});

function startBlockCountdownInterval(timeout?: number): void {
  isBlockRetryCountdown.value = true;
  timeToRetry.value = timeout || hourInMilliseconds;
  blockRetryCountdownInterval.value = setInterval(() => {
    timeToRetry.value -= oneSecond;

    if (timeToRetry.value === 0) {
      clearInterval(blockRetryCountdownInterval.value);
      isBlockRetryCountdown.value = false;
      emit('clear');
    }
  }, oneSecond);
}

const exceedDescription = computed<string>(() => {
  return props.unblockTimerMs !== null &&
    props.attemptsLeft > 0
    ? 'Вы превысили ограничение по количеству запросов кода'
    : 'Вы превысили ограничение по попыткам ввода.';
})

onBeforeUnmount(() => {
  if (blockRetryCountdownInterval.value) {
    clearInterval(blockRetryCountdownInterval.value);
  }
});

watch(
  () => props.unblockTimerMs,
  (newValue) => {
    if (newValue) {
      clearInterval(blockRetryCountdownInterval.value);
      startBlockCountdownInterval(props.unblockTimerMs)
    }
  })
</script>

<template>
  <p
    v-if="isBlockRetryCountdown"
    class="timer-text"
  >
    {{ exceedDescription }} <br>
    Запросить код повторно можно через {{ countdownTime }}
  </p>
</template>

<style scoped lang="scss">
@import '@styles/base/common/variables';

.timer-text {
  margin-top: 40px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $light-green;
  margin-bottom: 0;
}
</style>
