import { RuLocalesEnum } from '../enums/ruLocales.enum';

export default {
  [RuLocalesEnum.Car]: '0 машин | {n} машина | {n} машины | {n} машин',
  [RuLocalesEnum.Apple]: 'нет бананов | {n} банан | {n} банана | {n} бананов',
  [RuLocalesEnum.Item]: '0 товаров | {n} товар | {n} товара | {n} товаров',
  [RuLocalesEnum.MarketplaceQuantity]:
    'нет предложений | {n} предложение | {n} предложения | {n} предложений',
  [RuLocalesEnum.MarketplaceQuantityNumber]:
    '0 предложений | {n} предложение | {n} предложения | {n} предложений',
  [RuLocalesEnum.ItemsQuantity]: 'нет | {n} штука | {n} штуки | {n} штук',
  [RuLocalesEnum.DayAlternate]: '0 дней | {n} дня | {n} дней | {n} дней',
  [RuLocalesEnum.Models]: 'нет моделей | {n} модель | {n} модели | {n} моделей',
  [RuLocalesEnum.ParentCaseProducts]: 'нет товаров | {n} товара | {n} товаров | {n} товаров',
  [RuLocalesEnum.FinalsCategories]:
    'нет финальных категорий | {n} финальная категория | {n} финальные категории | {n} финальных категорий',
  [RuLocalesEnum.Categories]: 'нет категорий | {n} категория | {n} категории | {n} категорий',
  [RuLocalesEnum.Supplier]: '0 поставщиков | {n} поставщик | {n} поставщика | {n} поставщиков',
  [RuLocalesEnum.Hour]: '0 часов | {n} час | {n} часа | {n} часов',
  [RuLocalesEnum.Minute]: '0 минут | {n} минута | {n} минуты | {n} минут',
  [RuLocalesEnum.SelectedCategories]:
    'Нет выбранных категорий | Выбрана {n} категория | Выбрано {n} категории | Выбрано {n} категорий',
  [RuLocalesEnum.SelectedBrands]:
    'Нет выбранных брендов | Выбран {n} бренд | Выбрано {n} бренда | Выбрано {n} брендов',
  [RuLocalesEnum.Addresses]: 'нет адресов | {n} адрес | {n} адреса | {n} адресов',
  [RuLocalesEnum.SelectAdresses]:
    'Выбрано 0 адресов | Выбран {n} адрес | Выбрано {n} адреса | Выбрано {n} адресов',
  [RuLocalesEnum.ToClients]: '0 клиентам | {n} клиенту | {n} клиентам | {n} клиентам',
  [RuLocalesEnum.ToBasis]: '0 базисам | {n} базису | {n} базисам | {n} базисам',
  [RuLocalesEnum.AddToCart]: 'Добавлена 0 | Добавлена {n} | Добавлено {n}',
  [RuLocalesEnum.Offer]: 'Нет офферов | {n} оффер | {n} оффера | {n} офферов',
  [RuLocalesEnum.NotificationsUnreadCount]:
    '0 новых уведомлений | {n} новое уведомление | {n} новых уведомления  | {n} новых уведомлений',
  [RuLocalesEnum.Day]: 'дней | {n} день | {n} дня  | {n} дней',
  [RuLocalesEnum.ItemPosition]:
    'нет товарных позиций | {n} товарную позицию | {n} товарные позиции | {n} товарных позиций',
  [RuLocalesEnum.Source]: '0 источников | {n} источника | {n} источников | {n} источников',
  [RuLocalesEnum.UpToDays]: 'нет | {n} дня | {n} дней| {n} дней',
  [RuLocalesEnum.City]: '0 городов | {n} город | {n} города | {n} городов',
  [RuLocalesEnum.Region]: '0 регионах | {n} регионе | {n} регионах | {n} регионах',
  [RuLocalesEnum.NewMasculine]: '0 новых | {n} новый | {n} новых',
  [RuLocalesEnum.NewNeuter]: '0 новых | {n} новое | {n} новых',
  [RuLocalesEnum.ActiveMasculine]: '0 активных | {n} активный | {n} активных',
  [RuLocalesEnum.Values]: '0 значений | {n} значение | {n} значения | {n} значений',
  [RuLocalesEnum.CargoSpace]: '0 грузомест | {n} грузоместо | {n} грузоместа | {n} грузомест',
  [RuLocalesEnum.CargoSpaceLong]:
    '0 грузовых мест | {n} грузовое место | {n} грузовых места | {n} грузовых мест',
  [RuLocalesEnum.Orders]: '0 заказов | {n} заказ | {n} заказа | {n} заказов',
  [RuLocalesEnum.Suborders]: '0 подзаказов | {n} подзаказ | {n} подзаказа | {n} подзаказов',
  [RuLocalesEnum.UnitsNoCount]: 'единиц | единицу | единицы | единиц',
  [RuLocalesEnum.ProductPositions]:
    '0 товарных позиций | {n} товарную позицию | {n} товарные позиции | {n} товарных позиций',
  [RuLocalesEnum.FromSupplier]: '0 поставщиков | {n} поставщика | {n} поставщика | {n} поставщиков',
  [RuLocalesEnum.SupplierOffer]:
    '0 поставщиков | {n} поставщика | {n} поставщиков | {n} поставщиков',
  [RuLocalesEnum.Lists]: 'нет списков | {n} список | {n} списка | {n} списков',
  [RuLocalesEnum.Needs]:
    'потребностей включено | потребность включена | потребности включены | потребностей включены',
  [RuLocalesEnum.Suppliers]: '0 контрагентов | {n} контрагент | {n} контрагента | {n} контрагентов',
  [RuLocalesEnum.SuppliersWithoutCount]: 'контрагентов | контрагент | контрагента | контрагентов',
  [RuLocalesEnum.Selected]: 'Выбрано | Выбран | Выбрано | Выбрано',
  [RuLocalesEnum.SuppliersParental]:
    '0 контрагентов | {n} контрагента | {n} контрагентов | {n} контрагентов',
  [RuLocalesEnum.SuppliersDelete]:
    '0 контрагентов удалено | {n} контрагент удален | {n} контрагента удалены | {n} контрагентов удалены',
  [RuLocalesEnum.SuppliersAdd]:
    '0 выбранных контрагентов | {n} выбранный контрагент | {n} выбранных контрагента | {n} выбранных контрагентов',
  [RuLocalesEnum.SelectedProjects]: 'проектов | проект | проекта | проектов',
  [RuLocalesEnum.AddSuppliersToList]: '0 контрагентов успешно добавлено | {n} контрагент успешно добавлен | {n} контрагента успешно добавлены | {n} контрагентов успешно добавлены',
};
